import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import SegmentsSubheaderV20 from './segmentsSubheaderV2.0';
import SegmentsTableV20 from './segmentsTableV2.0';
import SegmentDetailV20 from './segmentDetailV2.0';
import { segmentsFilterMenu } 
from '../../../../../../_data/openstack/neutron/segments/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { segmentsUrl as segmentUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/segments/v2.0';
import { networksUrl as networkUrlResponses} from 
'../../../../../../_api_responses/openstack/neutron/networks/v2.0'

const SERVICE_NAME = openStackServices.networkService

const SegmentsWrapperV20 = (props) => {
    const { navigate, location } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [segmentsData, setSegmentsData] = useState([])
    const [segments, setSegments] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [segmentDeleteConfirmDialogOpen, setSegmentDeleteConfirmDialogOpen] = useState(false);
    const [selectedSegments, setSelectedSegments] = useState([])
    const [segmentsSortParams, setSegmentsSortParams] = useState("")
    
    const [segmentFilterQueryParams, setSegmentFilterQueryParams] = useState("")
    const [selectedSegmentFilter, setSelectedSegmentFilter] = useState(segmentsFilterMenu[0].value)
    const [selectedSegmentFilterValue, setSelectedSegmentFilterValue] = useState("")
    const [segmentsFilter, setSegmentsFilter] = useState([...segmentsFilterMenu])

    const [networks, setNetworks] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const segmentsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.segmentsUrl)[0].url)
    const networksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleSegmentFilteredSearch = () => {
        if (selectedSegmentFilter && selectedSegmentFilterValue) {
            setSegmentFilterQueryParams(`${selectedSegmentFilter}=${selectedSegmentFilterValue}`)
        } else {
            setSegmentFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleSegmentsDataFormatting = useCallback(() => {
        const formatted_data = segmentsData.map((item) => {
            let new_item = {...item}
            const network = networks.filter(n => n.id === item.network_id)
            if (network.length > 0) {
                new_item.network_id = network[0].name
            }
            
            return new_item
        })

        setSegments(formatted_data)
    },[
        segmentsData,
        networks
    ])

    const handleSegmentFilterReset = () => {
        setSelectedSegmentFilter(segmentsFilterMenu[0].value)
        setSelectedSegmentFilterValue("")
        setSegmentFilterQueryParams("")
        handleDataFetch()
    }


    const handleNavigateToNetwork = (network_id) => {
        handleDetailCardClose()
        navigate("/networks/l2networking",{state: {network_id: network_id}})
    }


    const handleDetailCardOpen = useCallback((index) => {
        setSelectedSegment(segmentsData[index].id)
        setSelectedRow(segmentsData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[segmentsData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedSegment(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleSegmentsSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setSegmentsSortParams(sort_param)
        handleDataFetch()
    }

    const handleSegmentDelete = async (p_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${segmentsUrl}/${p_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === segmentUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onSegmentDelete = async () => {
        handleSegmentDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedSegments) {
            const resp = await handleSegmentDelete(selectedSegments[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onSegmentDeleteConfirm = (n_list) => {
        const selected_n_list = segmentsData.filter(n => 
            n_list.includes(n.id))
        setSelectedSegments([...selected_n_list])
        setSegmentDeleteConfirmDialogOpen(true)
    }

    const handleSegmentDeleteConfirmDialogClose = () => {
        setSegmentDeleteConfirmDialogOpen(false)
    }

    const getSegmentsActionsList = () => {
        let segment_actions = []
        let new_action = {}
        new_action["value"] = "segment_delete"
        new_action["action"] = onSegmentDeleteConfirm
        new_action["keyword"] = "segmentDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        segment_actions.push({...new_action})
        
        return segment_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${segmentsUrl}?${segmentFilterQueryParams}${segmentsSortParams}`
                    const method = "GET"
                    const segment_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (segment_response.status_code === segmentUrlResponses.get.success_response.status_code) {
                        setSegmentsData(segment_response.data.segments)
                        if (selectedSegment) {
                            const selected_segment = segment_response.data.segments.filter(item => item.id === selectedSegment)
                            if (selected_segment.length > 0) {
                                setSelectedRow(selected_segment[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        segmentsUrl, 
        segmentFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        segmentsSortParams,
        selectedSegment
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
                const method = "GET"
                const network_response = await openstackRequest({url:url, method:method, token: project_token})
                if (network_response.status_code === networkUrlResponses.get.success_response.status_code) {
                    setNetworks(network_response.data.networks)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        networksUrl,
        defaultAdminProject
    ]);


    useEffect(() => {
        if (segmentsData.length > 0) {
            handleSegmentsDataFormatting()
        }
    },[
        segmentsData,
        handleSegmentsDataFormatting
    ])

    useEffect(() => {
        let networks_filter = []
        if (networks.length > 0) {
            networks_filter = networks.map(network => {
                return {keyword: network.name, value: network.id, default: false}
            })
        }
        
        let segment_filter_menu = segmentsFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "network_id") {
                new_item.items = [...networks_filter]
            }
            return new_item
        })

        setSegmentsFilter(segment_filter_menu)
    },[
        segmentsData,
        networks
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const segment_id = location.state ? location.state.segment_id : null
                const segment_index = segmentsData.findIndex(v => v.id === segment_id);
                if (segment_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(segment_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        segmentsData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <SegmentsSubheaderV20 
                selectedFilter={selectedSegmentFilter} 
                setSelectedFilter={setSelectedSegmentFilter}
                selectedFilterValue={selectedSegmentFilterValue}
                setSelectedFilterValue={setSelectedSegmentFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={segmentsFilter}
                handleFilteredSearch={handleSegmentFilteredSearch}
                handleFilterReset={handleSegmentFilterReset}
                networks={networks}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <SegmentsTableV20 
                segmentsData={segments}
                setSegmentsData={setSegments}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getSegmentsActionsList()}
                sortHandler={handleSegmentsSorting}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <SegmentDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleDelete={onSegmentDeleteConfirm}
                    networks={networks}
                    handleNavigateToNetwork={handleNavigateToNetwork}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={segmentDeleteConfirmDialogOpen}
            onClose={handleSegmentDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.segmentDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.segmentDeleteConfirmText}`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onSegmentDelete, 
                sx: {color: 'primary.main'}}]}
        />
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default SegmentsWrapperV20;
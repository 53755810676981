import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        computeNovaConstants
} from '../../../../../../config/openStackConstants';
import HypervisorsTableV21 from './hypervisorsTableV2.1';
import { 
    getXAuthTokenProjectScope, 
    computeNovaRequest
} from '../../../../../../_network/openstack_request';
import { infrastructureUrl as infrastructureUrlResponses } 
from '../../../../../../_api_responses/openstack/compute/infrastructure/v2.1';
import IconButton from '@mui/material/IconButton'
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomText from '../../../../../_common/CustomText';

const SERVICE_NAME = openStackServices.computeService

const HypervisorsWrapperV21 = (props) => {
    const { navigate } = props
    const [isLoading, setIsLoading ] = useState(true);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [hypervisors, setHypervisors] = useState([])
    const [selectedHypervisor, setSelectedHypervisor] = useState(null);
    const [showInstancesDetailsOpen, setShowInstancesDetailsOpen] = useState(false)

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const hypervisorsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.hypervisorsUrl)[0].url)
    

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleHypervisorsDataFormatting = (data) => {
        const formatted_data = data.map((item,index) => {
            let new_item = {...item}
            const server_num = Object.keys(item).includes("servers") ? item.servers.length : 0
            new_item["servers"] = <IconButton
                disabled={server_num === 0}
                onClick={() => handleShowInstancesDetails(index)}
                sx={{
                    fontSize: 16,
                    color: server_num > 0 ? "primary.main" : undefined,
                    alignItems: "center"
                }}
            >
                {server_num}
            </IconButton>
            
            return new_item
        })
        return formatted_data
    }

    const handleShowInstancesDetails = useCallback((row) => {
        setSelectedHypervisor(hypervisors[row])
        setShowInstancesDetailsOpen(true)
    },[hypervisors])

    const handleShowInstancesDetailsClose = () => {
        setSelectedHypervisor(null)
        setShowInstancesDetailsOpen(false)
    }

    const handleNavigateToServer = (server_id) => {
        handleShowInstancesDetailsClose()
        navigate("/compute/servers",{state: {server_id: server_id}})
    }

    useEffect(() => {
        (async () => {
            handleLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${computeServiceDomain}/${computeServiceVersion}/${hypervisorsUrl}/detail?with_servers=true`
                const method = "GET"
                const hypervisors_response = await computeNovaRequest({url:url, method:method, token: project_token})
                if (hypervisors_response.status_code === infrastructureUrlResponses.get.success_response.status_code) {
                    setHypervisors(hypervisors_response.data.hypervisors)
                }
            }
        })();
        setTimeout(()=>{handleLoading(false)},700)
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        hypervisorsUrl, 
        defaultAdminProject
    ]);

    return (
        <Box>
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading &&
                <HypervisorsTableV21
                    hypervisorsData={handleHypervisorsDataFormatting(hypervisors)}
                    setHypervisorsData={setHypervisors}
                />
            }
            {selectedHypervisor && <CustomDialog
                open={showInstancesDetailsOpen}
                onClose={handleShowInstancesDetailsClose}
                dialogTitle={{
                    title: defaultTexts.hypervisorInstancesDetailsDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
            >
            <Stack spacing={2}>
                    {selectedHypervisor.servers.map((server) => {
                        return (
                            <React.Fragment>
                                <Stack 
                                    direction="row" 
                                    alignItems="center" 
                                    justifyContent="space-between"
                                >
                                    <CustomText 
                                        sx={{color: "text.secondary"}}
                                    >
                                        {defaultTexts.idFormFieldLabel}
                                    </CustomText>
                                    <Box 
                                        sx={{color: "primary.main", "&:hover": {cursor: "pointer"}}} 
                                        onClick={() => handleNavigateToServer(server.uuid)}
                                    >
                                        <CustomText>{server.uuid}</CustomText> 
                                    </Box>
                                </Stack>
                                <Stack 
                                    direction="row" 
                                    alignItems="center" 
                                    justifyContent="space-between"
                                >
                                    <CustomText 
                                        sx={{color: "text.secondary"}}
                                    >
                                        {defaultTexts.nameFormFieldLabel}
                                    </CustomText>
                                    <CustomText>{server.name}</CustomText> 
                                </Stack>
                                <Divider />
                            </React.Fragment>
                        )
                        }) 
                    }
                    </Stack>
            </CustomDialog>}
        </Box>
    )
};

export default HypervisorsWrapperV21;
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ServiceContentHeader from '../../../_common/ServiceContentHeader';
import Box from '@mui/material/Box';

import IdentityDomainsWrapperV3 from './domains/identityDomainsWrapperV3';
import IdentityRegionsWrapperV3 from './regions/identityRegionsWrapperV3';
import IdentityProjectsWrapperV3 from './projects/identityProjectsWrapperV3';
import IdentityUsersWrapperV3 from './users/identityUsersWrapperV3';
import IdentityGroupsWrapperV3 from './groups/identityGroupsWrapperV3';
import IdentityRolesWrapperV3 from './roles/identityRolesWrapperV3';
import IdentityServicesCatalogWrapperV3 from './services_catalog/identityServicesCatalogWrapperV3';

const IdentityKeystoneContentV3 = (props) => {
    const { section } = props
    const { serviceTitle } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);

    const [domainsMenu, setDomainsMenu] = useState([
        {keyword: "submenuDomains", navigation: "/domains", is_active: true, component: IdentityDomainsWrapperV3}
    ])

    const [regionsMenu, setRegionsMenu] = useState([
        {keyword: "submenuRegions", navigation: "/regions", is_active: true, component: IdentityRegionsWrapperV3}
    ])

    const [projectsMenu, setProjectsMenu] = useState([
        {keyword: "submenuProjects", navigation: "/projects", is_active: true, component: IdentityProjectsWrapperV3}
    ])

    const [usersMenu, setUsersMenu] = useState([
        {keyword: "submenuUsers", navigation: "/users", is_active: true, component: IdentityUsersWrapperV3}
    ])

    const [groupsMenu, setGroupsMenu] = useState([
        {keyword: "submenuGroups", navigation: "/groups", is_active: true, component: IdentityGroupsWrapperV3}
    ])

    const [rolesMenu, setRolesMenu] = useState([
        {keyword: "submenuRoles", navigation: "/roles", is_active: true, component: IdentityRolesWrapperV3}
    ])

    const [servicesCatalogMenu, setServicesCatalogMenu] = useState([
        {keyword: "submenuServices", navigation: "/services-catalog", is_active: true, component: IdentityServicesCatalogWrapperV3}
    ])

    const getCurrentTab = useCallback(() => {
        if (section === "/domains") {
            const currentItem = domainsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/regions") {
            const currentItem = regionsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/projects") {
            const currentItem = projectsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/users") {
            const currentItem = usersMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/groups") {
            const currentItem = groupsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/roles") {
            const currentItem = rolesMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/services-catalog") {
            const currentItem = servicesCatalogMenu.filter(item => item.is_active)[0]
            return currentItem
        }
    },[
        domainsMenu,
        regionsMenu,
        projectsMenu,
        usersMenu,
        rolesMenu,
        groupsMenu,
        servicesCatalogMenu,
        section
    ])

    const [currentTab, setCurrentTab] = useState(getCurrentTab())

    const changeMenuActiveTab = (navigation) => {
        if (section === "/domains") {
            const updated_menu = domainsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setDomainsMenu(updated_menu)
        } else if (section === "/regions") {
            const updated_menu = regionsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setRegionsMenu(updated_menu)
        } else if (section === "/projects") {
            const updated_menu = projectsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setProjectsMenu(updated_menu)
        } else if (section === "/users") {
            const updated_menu = usersMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setUsersMenu(updated_menu)
        } else if (section === "/groups") {
            const updated_menu = groupsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setGroupsMenu(updated_menu)
        } else if (section === "/roles") {
            const updated_menu = rolesMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setRolesMenu(updated_menu)
        } else if (section === "/services-catalog") {
            const updated_menu = servicesCatalogMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setServicesCatalogMenu(updated_menu)
        }
        
    }

    const getCurrentServiceMenu = () => {
        if (section === "/domains") {
            return domainsMenu
        } else if (section === "/regions") {
            return regionsMenu
        } else if (section === "/projects") {
            return projectsMenu
        } else if (section === "/users") {
            return usersMenu
        } else if (section === "/groups") {
            return groupsMenu
        } else if (section === "/roles") {
            return rolesMenu
        } else if (section === "/services-catalog") {
            return servicesCatalogMenu
        }
    }

    useEffect(() => {
        setCurrentTab(getCurrentTab())
    },[
        section, getCurrentTab
    ]);

    return (
        <Box>
            <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={getCurrentServiceMenu()}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component />
        </Box>
    )
};

export default IdentityKeystoneContentV3;
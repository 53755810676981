import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        computeNovaConstants
} from '../../../../../../config/openStackConstants';
import AggregatesSubheaderV21 from './aggregatesSubheaderV2.1';
import AggregatesTableV21 from './aggregatesTableV2.1';
import AggregateDetailV21 from './aggregateDetailV2.1';
import { aggregatesFilterMenu } 
from '../../../../../../_data/openstack/compute/infrastructure/v2.1';
import { 
    getXAuthTokenProjectScope, 
    computeNovaRequest
} from '../../../../../../_network/openstack_request';
import { infrastructureUrl as infrastructureUrlResponses } 
from '../../../../../../_api_responses/openstack/compute/infrastructure/v2.1';

const SERVICE_NAME = openStackServices.computeService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const AggregatesWrapperV21 = (props) => {
    const { navigate, location } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [aggregatesData, setAggregatesData] = useState([])
    const [aggregates, setAggregates] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedAggregate, setSelectedAggregate] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [aggregateDeleteConfirmDialogOpen, setAggregateDeleteConfirmDialogOpen] = useState(false);
    const [selectedAggregates, setSelectedAggregates] = useState([])
    const [availabilityZones, setAvailabilityZones] = useState([])
    const [selectedAggregateFilter, setSelectedAggregateFilter] = useState(aggregatesFilterMenu[0].value)
    const [selectedAggregateFilterValue, setSelectedAggregateFilterValue] = useState("")
    const [aggregatesFilter, setAggregatesFilter] = useState([...aggregatesFilterMenu])

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const aggregatesUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.aggregatesUrl)[0].url)
    const availabilityZonesUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.availabilityZonesUrl)[0].url)
    
    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleAggregateFilteredSearch = () => {
        if (selectedAggregateFilter && selectedAggregateFilterValue) {
            const new_data = aggregatesData.filter(agr => agr[selectedAggregateFilter].includes(selectedAggregateFilterValue))
            handleAggregatesDataFormatting(new_data)
        } else {
            handleAggregatesDataFormatting(aggregatesData)
        }
    }

    const handleAggregatesDataFormatting = useCallback((data) => {
        const formatted_data = data.map((item) => {
            let new_item = {...item}
            
            return new_item
        })
        setAggregates(formatted_data)
    },[])

    const handleAggregateFilterReset = () => {
        setSelectedAggregateFilter(aggregatesFilterMenu[0].value)
        setSelectedAggregateFilterValue("")
        handleAggregatesDataFormatting(aggregatesData)
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedAggregate(aggregatesData[index].id)
        setSelectedRow(aggregatesData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[aggregatesData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedAggregate(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleAggregateDelete = async (a_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${computeServiceDomain}/${computeServiceVersion}/${aggregatesUrl}/${a_id}`
            const method = "DELETE"
            
            const nt_response = await computeNovaRequest({
                url: url, 
                method: method,
                token: project_token
            })

            if (nt_response.status_code === infrastructureUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onAggregateDelete = async () => {
        handleAggregateDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedAggregates) {
            const resp = await handleAggregateDelete(selectedAggregates[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onAggregateDeleteConfirm = (n_list) => {
        const selected_n_list = aggregatesData.filter(n => 
            n_list.includes(n.id))
        setSelectedAggregates([...selected_n_list])
        setAggregateDeleteConfirmDialogOpen(true)
    }

    const handleAggregateDeleteConfirmDialogClose = () => {
        setAggregateDeleteConfirmDialogOpen(false)
    }

    const getAggregatesActionsList = () => {
        let aggregate_actions = []
        let new_action = {}
        new_action = {}
        new_action["value"] = "aggregate_delete"
        new_action["action"] = onAggregateDeleteConfirm
        new_action["keyword"] = "hostAggregateDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        aggregate_actions.push({...new_action})
        
        return aggregate_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${computeServiceDomain}/${computeServiceVersion}/${aggregatesUrl}`
                    const method = "GET"
                    const aggregates_response = await computeNovaRequest({url:url, method:method, token: project_token})
                    if (aggregates_response.status_code === infrastructureUrlResponses.get.success_response.status_code) {
                        setAggregatesData(aggregates_response.data.aggregates)
                        if (selectedAggregate) {
                            const selected_aggregate = aggregates_response.data.aggregates.filter(item => item.id === selectedAggregate)
                            if (selected_aggregate.length > 0) {
                                setSelectedRow(selected_aggregate[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        aggregatesUrl, 
        dataFetchingRequired,
        defaultAdminProject,
        selectedAggregate
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${computeServiceDomain}/${computeServiceVersion}/${availabilityZonesUrl}`
                const method = "GET"
                const az_response = await computeNovaRequest({url:url, method:method, token: project_token})
                if (az_response.status_code === infrastructureUrlResponses.get.success_response.status_code) {
                    setAvailabilityZones(az_response.data.availabilityZoneInfo)
                }
            }
        })();
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        availabilityZonesUrl, 
        dataFetchingRequired,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (aggregatesData.length > 0) {
            handleAggregatesDataFormatting(aggregatesData)
        }
    },[
        aggregatesData,
        handleAggregatesDataFormatting
    ])

    useEffect(() => {
        let aggregate_filter_menu = aggregatesFilterMenu.map(nt => {
            let availability_zones = []
            if (availabilityZones.length > 0) {
                availability_zones = availabilityZones.map(az => {
                    return {keyword: az.zoneName, value: az.zoneName, default: false}
                })
            }
            let new_item = {...nt}
            if (nt.value === "availability_zone") {
                new_item.items = [...availability_zones]
            }
            return new_item
        })

        setAggregatesFilter(aggregate_filter_menu)
    },[
        aggregatesData,
        availabilityZones
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const aggregate_id = location.state ? location.state.aggregate_id : null
                const aggregate_index = aggregatesData.findIndex(v => v.id === aggregate_id);
                if (aggregate_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(aggregate_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        aggregatesData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <AggregatesSubheaderV21 
                selectedFilter={selectedAggregateFilter} 
                setSelectedFilter={setSelectedAggregateFilter}
                selectedFilterValue={selectedAggregateFilterValue}
                setSelectedFilterValue={setSelectedAggregateFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={aggregatesFilter}
                handleFilteredSearch={handleAggregateFilteredSearch}
                handleFilterReset={handleAggregateFilterReset}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <AggregatesTableV21
                aggregatesData={aggregates}
                setAggregatesData={setAggregates}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getAggregatesActionsList()}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <AggregateDetailV21
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleDelete={onAggregateDeleteConfirm}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={aggregateDeleteConfirmDialogOpen}
            onClose={handleAggregateDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.aggregateDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.AggregateDeleteConfirmText}: [${selectedAggregates.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onAggregateDelete, 
                sx: {color: 'primary.main'}}]}
        />
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default AggregatesWrapperV21;
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { openStackServices, 
        blockStorageCinderConstants,
        identityKeystonConstants 
} from '../../../../../config/openStackConstants';
import DefaultVolumeTypesSubheaderV3 from './defaultVolumeTypesSubhedarV3';
import DefaultVolumeTypesTableV3 from './defaultVolumeTypesTableV3';
import { defaultVolumeTypesFilterMenu } 
from '../../../../../_data/openstack/cinder/volume_types/v3';
import { volumeCinderRequest, getXAuthTokenProjectScope, openstackRequest } from 
'../../../../../_network/openstack_request';
import { volumesUrl as volumeUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import { usersUrl as usersUrlResponses } from 
'../../../../../_api_responses/openstack/identity/users/v3';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.volumeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const DefaultVolumeTypesWrapperV3 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [volumeTypesData, setVolumeTypesData] = useState([])
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [fetchDefaultsDataRequired, setFetchDefaultsDataRequired] = useState(true);
    const [currentDefaultsAction, setCurrentDefaultsAction] = useState("");

    const [defaultVolumeTypesFilters, setDefaultVolumeTypesFilters] = useState([...defaultVolumeTypesFilterMenu])
    const [selectedDefaultVolumeTypeFilter, setSelectedDefaultVolumeTypeFilter] = useState(defaultVolumeTypesFilters[0].value)
    const [selectedDefaultVolumeTypeFilterValue, setSelectedDefaultVolumeTypeFilterValue] = useState("")
    const [projects, setProjects] = useState([])

    const [defaultVolumeTypesData, setDefaultVolumeTypesData] = useState([])
    const [defaultVolumeTypes, setDefaultVolumeTypes] = useState([])

    const [defaultVolumeTypeDialogOpen, setDefaultVolumeTypeDialogOpen] = useState(false)
    const [selectedDefaultVolumeType, setSelectedDefaultVolumeType] = useState("")
    const [volumeTypesList, setVolumeTypesList] = useState([])
    const [selectedProjects, setSelectedProjects] = useState([])

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const volumeTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumeTypesUrl)[0].url)
    const defaultVolumeTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.defaultVolumeTypesUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDefaultsDataFetch = () => {
        setFetchDefaultsDataRequired(true)
    }

    const handleSetDefaultVolumeTypeDialogOpen = (project_list) => {
        setDefaultVolumeTypeDialogOpen(true)
        setSelectedProjects(project_list)
    }

    const handleSetDefaultVolumeTypeDialogClose = () => {
        setSelectedDefaultVolumeType("")
        setDefaultVolumeTypeDialogOpen(false)
    }

    const handleDefaultVolumeTypeFilteredSearch = () => {
        let formatted_data = projects.map(item => {
            let new_item = {}
            new_item["project_id"] = item.id
            new_item["project_name"] = item.name
            const volume_type = defaultVolumeTypesData.filter(i => i.project_id === item.id)
            new_item["volume_type_id"] = volume_type.length > 0 ? volume_type[0].volume_type_id : ""
            new_item["volume_type_name"] = volume_type.length > 0 ? volumeTypesData.filter(v => v.id === volume_type[0].volume_type_id)[0].name : ""
            return new_item
        })
        if (selectedDefaultVolumeTypeFilter && selectedDefaultVolumeTypeFilterValue) {
            if (selectedDefaultVolumeTypeFilter === "project_id") {
                const new_list = formatted_data.filter(item => item.project_id === selectedDefaultVolumeTypeFilterValue)
                setDefaultVolumeTypes(new_list)
            } else if (selectedDefaultVolumeTypeFilter === "volume_type_id") {
                const new_list = formatted_data.filter(item => item.volume_type_id === selectedDefaultVolumeTypeFilterValue)
                setDefaultVolumeTypes(new_list)
            }
        } else {
            setDefaultVolumeTypes(formatted_data)
        }
    }

    const handleDefaultVolumeTypeFilterReset = () => {
        setSelectedDefaultVolumeTypeFilter(defaultVolumeTypesFilters[0].value)
        setSelectedDefaultVolumeTypeFilterValue("")
        let formatted_data = projects.map(item => {
            let new_item = {}
            new_item["project_id"] = item.id
            new_item["project_name"] = item.name
            const volume_type = defaultVolumeTypesData.filter(i => i.project_id === item.id)
            new_item["volume_type_id"] = volume_type.length > 0 ? volume_type[0].volume_type_id : ""
            new_item["volume_type_name"] = volume_type.length > 0 ? volumeTypesData.filter(v => v.id === volume_type[0].volume_type_id)[0].name : ""
            return new_item
        })
        setDefaultVolumeTypes(formatted_data)
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleDefaultVolumeTypeUnset = async (volume_type_list) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let v in volume_type_list) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultVolumeTypesUrl}/${volume_type_list[v]}`
                const method = "DELETE"

                await volumeCinderRequest({
                    url: url, 
                    method: method,
                    token: project_token
                })
            }
            handleDefaultsDataFetch()
        }
    }

    const onDefaultVolumeTypeSet = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let v in selectedProjects) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultVolumeTypesUrl}/${selectedProjects[v]}`
                const method = "PUT"

                await volumeCinderRequest({
                    url: url, 
                    method: method,
                    data: {default_type: {volume_type: selectedDefaultVolumeType}},
                    token: project_token
                })
            }
            handleSetDefaultVolumeTypeDialogClose()
            handleDefaultsDataFetch()
        }
    }

    const getDefaultVolumeTypesActionsList = () => {
        let default_vt_actions = []
        let new_action = {}
        new_action["value"] = "set_default_volume_type"
        new_action["action"] = handleSetDefaultVolumeTypeDialogOpen
        new_action["keyword"] = "setDefaultVolumeTypeActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        default_vt_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "unset_default_volume_type"
        new_action["action"] = handleDefaultVolumeTypeUnset
        new_action["keyword"] = "unsetDefaultVolumeTypeActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        default_vt_actions.push({...new_action})
        
        return default_vt_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        const project_filters = projects.map(project => {
            return {keyword: project.name, value: project.id, default: false}
        })
        const volume_types_filters = volumeTypesData.map(vt => {
            return {keyword: vt.name, value: vt.id, default: false}
        })

        let default_volume_types_filter = defaultVolumeTypesFilterMenu.map(item => {
            let new_item = {...item}
            if (item.value === "project_id") {
                new_item["items"] = [...project_filters]
                new_item["self_item_titles"] = true
            } else if (item.value === "volume_type_id") {
                new_item["items"] = [...volume_types_filters]
                new_item["self_item_titles"] = true
            }
            return new_item
        })
        
        setDefaultVolumeTypesFilters(default_volume_types_filter)

        let formatted_data = projects.map(item => {
            let new_item = {}
            new_item["project_id"] = item.id
            new_item["project_name"] = item.name
            const volume_type = defaultVolumeTypesData.filter(i => i.project_id === item.id)
            new_item["volume_type_id"] = volume_type.length > 0 ? volume_type[0].volume_type_id : ""
            if (volume_type.length > 0) {
                const vol = volumeTypesData.filter(v => v.id === volume_type[0].volume_type_id)
                new_item["volume_type_name"] = vol.length > 0 ? vol[0].name : ""
            }
            return new_item
        })
        setDefaultVolumeTypes(formatted_data)
    },[
        projects,
        volumeTypesData,
        defaultVolumeTypesData
    ])

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let all_volume_types = []
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}`
                    const method = "GET"
        
                    const volume_types_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (volume_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        all_volume_types = [...all_volume_types, ...volume_types_response.data.volume_types]
                    }
                    url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}?is_public=false`
                    const response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        const private_vt = response.data.volume_types.filter(vt => !all_volume_types.map(i => i.id).includes(vt.id))
                        all_volume_types = [...private_vt, ...all_volume_types]
                    }
                    setVolumeTypesData([...all_volume_types])
                    const volume_type_list = all_volume_types.map(item => {
                        return {keyword: item.name, value: item.id, default: false}
                    })
                    setVolumeTypesList(volume_type_list)
                }
            })();
        }
        setDataFetchingRequired(false)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        volumeTypesUrl, 
        dataFetchingRequired,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (fetchDefaultsDataRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultVolumeTypesUrl}`
                    const method = "GET"
        
                    const volume_types_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (volume_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        setDefaultVolumeTypesData(volume_types_response.data.default_types)
                    }
                }
            })();
        }
        setFetchDefaultsDataRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        defaultVolumeTypesUrl,
        fetchDefaultsDataRequired,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === usersUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <DefaultVolumeTypesSubheaderV3 
                handleFetchData={handleDefaultsDataFetch}
                filterMenu={defaultVolumeTypesFilters}
                selectedFilter={selectedDefaultVolumeTypeFilter} 
                setSelectedFilter={setSelectedDefaultVolumeTypeFilter}
                selectedFilterValue={selectedDefaultVolumeTypeFilterValue}
                setSelectedFilterValue={setSelectedDefaultVolumeTypeFilterValue}
                handleFilteredSearch={handleDefaultVolumeTypeFilteredSearch}
                handleFilterReset={handleDefaultVolumeTypeFilterReset}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading &&
                <DefaultVolumeTypesTableV3 
                    defaultVolumeTypesData={defaultVolumeTypes}
                    setDefaultVolumeTypesData={setDefaultVolumeTypes}
                    actionsTexts={defaultTexts}
                    currentAction={currentDefaultsAction}
                    setCurrentAction={setCurrentDefaultsAction}
                    actionsList={getDefaultVolumeTypesActionsList()}
                />
            }
        <CustomDialog
            open={defaultVolumeTypeDialogOpen}
            onClose={handleSetDefaultVolumeTypeDialogClose}
            dialogTitle={{
                title: defaultTexts.setDefaultVolumeTypeActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onDefaultVolumeTypeSet, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomSelectField
                items={volumeTypesList}
                required={true}
                currentValue={selectedDefaultVolumeType}
                setCurrentValue={setSelectedDefaultVolumeType}
                label={defaultTexts.volumeTypeFormFieldLabel}
                self_item_titles={true}
                empty={true}
                size="large"
                sx={{my: 1, width: '90%'}}
            />
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default DefaultVolumeTypesWrapperV3;
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import { flavorsUrl as flavorsUrlResponses} from
'../../../../../_api_responses/openstack/compute/flavors/v2.1';
import { projectsUrl as projectsUrlResponses} from
'../../../../../_api_responses/openstack/identity/projects/v3';
import { openstackRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { computeNovaConstants, identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import { Stack, Button } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import { Divider, Grid } from '@mui/material';
import CustomCard from '../../../../_common/CustomCard';
import { ImDatabase } from "react-icons/im";
import { BsClockHistory } from "react-icons/bs";
import { BsCpu } from "react-icons/bs";
import { GrMemory } from "react-icons/gr";
import { Box } from '@mui/material';
import { IoCalendarOutline } from "react-icons/io5";
import CustomDialog from '../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { setDefaultStatsSubheaderOpenState } from 
'../../../../../store/reducers/computeNovaSlice';

const SERVICE_NAME = openStackServices.computeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const ComputeServersStatsSubheaderV21 = (props) => {
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const openState = useSelector(state => state.computeNova.defaultStatsSubheaderOpenState)
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [selectedStatsProjects, setSelectedStatsProjects] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([...selectedStatsProjects])
    const [selectProjectsModalOpen, setSelectProjectsModalOpen] = useState(false);
    const [projects, setProjects] = useState([]);
    const [selectStartEndDateModalOpen, setSelectStartEndDateModalOpen] = useState(false);
    const currentDateTime = new Date()
    const selectedEndTime = 
        new Date(
            currentDateTime.getFullYear(), 
            currentDateTime.getMonth(), 
            currentDateTime.getDate(), 
            23,59,59);
    const selectedStartTime = new Date(
            currentDateTime.getFullYear(), 
            currentDateTime.getMonth(), 
            1, 0,0,0);
    const [dateForm, setDateForm] = useState({
        "start_date": selectedStartTime, 
        "end_date": selectedEndTime
    })
    const [totalCPUUsage, setTotalCPUUsage] = useState(0)
    const [totalRAMUsage, setTotalRAMUsage] = useState(0)
    const [totalStorageUsage, setTotalStorageUsage] = useState(0)
    const [totalHoursUsage, setTotalHoursUsage] = useState(0)

    const theme = useTheme();
    const dispatch = useDispatch();

    const resourceUsageTexts = [
        {
            field_key: "total_vcpus_usage", 
            icon: BsCpu, 
            color: theme.palette.mode === "dark" ? theme.palette.dashboardPurpleDark : theme.palette.dashboardPurple, 
            title: defaultTexts.totalCPUUsageStatsTitle, 
            data: totalCPUUsage
        },
        {
            field_key: "total_memory_mb_usage", 
            icon: GrMemory, 
            color: theme.palette.mode === "dark" ? theme.palette.dashboardPinkDark : theme.palette.dashboardPink,
            title: defaultTexts.totalRAMUsageStatsTitle, 
            data: `${Math.round(totalRAMUsage > 1023 ? totalRAMUsage / 1024 : totalRAMUsage)} ${totalRAMUsage > 1023 ? "GB" : "MB"}`
        },
        {
            field_key: "total_local_gb_usage", 
            icon:ImDatabase, 
            color: theme.palette.mode === "dark" ? theme.palette.dashboardPurpleDark : theme.palette.dashboardPurple, 
            title: defaultTexts.totalStorageUsageStatsTitle, 
            data: `${Math.round(totalStorageUsage > 1023 ? totalStorageUsage / 1024 : totalStorageUsage)} ${totalStorageUsage > 1023 ? "TB" : "GB"}`
        },
        {
            field_key: "total_hours", 
            icon: BsClockHistory, 
            color: theme.palette.mode === "dark" ? theme.palette.dashboardPinkDark : theme.palette.dashboardPink,
            title: defaultTexts.serverTotalHoursUsageStatsTitle, 
            data: `${Math.round(totalHoursUsage > 999 ? totalHoursUsage / 1000 : totalHoursUsage)} ${totalHoursUsage > 999 ? "K" : ""}`
        }
    ]

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const usageUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.usageUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)


    const handleSelectProjectModalOpen = () => {
        setSelectProjectsModalOpen(true)
    }

    const handleSelectProjectsModalClose = () => {
        setSelectProjectsModalOpen(false)
    }

    const handleProjectsSelection = () => {
        setSelectedStatsProjects(selectedProjects)
        setSelectProjectsModalOpen(false)
    }

    const handleStartEndDateModalOpen = () => {
        setSelectStartEndDateModalOpen(true)
    }

    const handleStartEndDateModalClose = () => {
        setSelectStartEndDateModalOpen(false)
    }

    const handleStartEndDateChange = (value,field_key) => {
        let new_data = {...dateForm}
        new_data[field_key] = value
        setDateForm(new_data)
    }

    const handleComponentOpen = () => {
        dispatch(
            setDefaultStatsSubheaderOpenState({
                defaultStatsSubheaderOpenState: true
            }))
    }

    const handleComponentCollapse = () => {
        dispatch(
            setDefaultStatsSubheaderOpenState({
                defaultStatsSubheaderOpenState: false
            }))
    }

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"
            const projects_response = await openstackRequest({
                url:url, 
                method:method
            })
            
            if ( projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                const projects_list = projects_response.data.projects.map(project => {
                    return {keyword: project.name, value: project.id, default: false}
                })
                setProjects(projects_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            let query_params = "?"
            if (dateForm.start_date) {
                query_params = query_params + `start=${dateForm.start_date.getFullYear()}-${dateForm.start_date.getMonth() + 1}-${dateForm.start_date.getDate()}T${dateForm.start_date.getHours()}:${dateForm.start_date.getMinutes()}:${dateForm.start_date.getSeconds()}&`
            }
    
            if (dateForm.end_date) {
                query_params = query_params + `end=${dateForm.end_date.getFullYear()}-${dateForm.end_date.getMonth() + 1}-${dateForm.end_date.getDate()}T${dateForm.end_date.getHours()}:${dateForm.end_date.getMinutes()}:${dateForm.end_date.getSeconds()}`
            }
            let url = `${computeServiceDomain}/${computeServiceVersion}/${usageUrl}`
            const method = "GET"
            let usage_list = []
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                if (selectedStatsProjects.length > 0) {
                    for (let p in selectedStatsProjects) {
                        const usage_response = await openstackRequest({
                            url:url+`/${selectedStatsProjects[p]}${query_params}`, 
                            method:method, 
                            token: project_token
                        })
                        if ( usage_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
                            if (usage_response.data.tenant_usage.total_hours) {
                                usage_list.push(usage_response.data.tenant_usage)
                                
                            }
                        }
                    }
                    
                } else {
                    const usage_response = await openstackRequest({
                        url:url+query_params, 
                        method:method, 
                        token: project_token
                    })
                    if ( usage_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
                        usage_list = usage_response.data.tenant_usages
                    }
                }
            } 

            let vcpus = 0
            let memory = 0
            let storage = 0
            let hours = 0

            if (usage_list.length > 0) {
                for (let u in usage_list) {
                    vcpus = Math.round(vcpus + usage_list[u].total_vcpus_usage)
                    memory = Math.round(memory + usage_list[u].total_memory_mb_usage)
                    storage = Math.round(storage + usage_list[u].total_local_gb_usage)
                    hours = Math.round(hours + usage_list[u].total_hours)
                }
            }

            setTotalCPUUsage(vcpus)
            setTotalRAMUsage(memory)
            setTotalStorageUsage(storage)
            setTotalHoursUsage(hours)
        })();
    },[
        computeServiceDomain,
        computeServiceVersion,
        usageUrl,
        selectedStatsProjects,
        defaultAdminProject,
        dateForm
    ])

    return (
        <React.Fragment>
        {!openState && 
            <Box sx={{display: "flex", justifyContent: "flex-end", m: 1}}>
                <Button 
                    onClick={handleComponentOpen}
                >
                    {defaultTexts.showStatsSubheaderButtonText}
                </Button>
            </Box>}
        {openState && 
            <Grid container spacing={2} sx={{mt: 0.1}}>
            {resourceUsageTexts.map((item) => {
                return (
                    <Grid item xs={6} md={4} lg={2} key={item.field_key}>
                        <CustomCard 
                            cardWidth={'100%'} 
                            cardHeight={90} 
                            card_sx={{
                                border: theme.palette.mode === "dark" ? 0 : 1, 
                                boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                                backgroundColor: 
                                    theme.palette.mode === "dark" ? 
                                    undefined :
                                    "vLightGray"
                            }}
                            box_sx={{px: 1}}
                        >
                        <Stack
                            direction="row" 
                            alignItems="start" 
                            justifyContent="center"
                            spacing={0}
                        >
                        <Stack
                            direction="column" 
                            alignItems="center" 
                            justifyContent="flex-start"
                            spacing={0}
                        >
                            <CustomText 
                                size="h6" 
                                sx={{
                                    fontSize: {xs: 20, lg: 24},
                                    color: item.color
                                }}
                            >
                                {item.data}
                            </CustomText>
                            <CustomText 
                                size="p" 
                                sx={{fontSize: {xs: 13, md: 14, lg: 16}}}
                            >
                                {item.title}
                            </CustomText>
                        </Stack>
                        <item.icon 
                            size={28}
                        />
                        </Stack>
                    </CustomCard>
                </Grid>
                )
            })}
            <Grid item xs={6} md={4} lg={2} onClick={handleStartEndDateModalOpen}>
                <CustomCard 
                    cardWidth={'100%'} 
                    cardHeight={90} 
                    card_sx={{
                        border: 0, 
                        boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                        backgroundColor: 
                            theme.palette.mode === "dark" ? 
                            "vDarkBlue" : 
                            "lightBlue",
                        m: 0,
                        p: 0,
                        "&:hover": {
                            backgroundColor: 
                            theme.palette.mode === "dark" ? 
                            "lightBlue" : 
                            "vDarkBlue",
                            cursor: "pointer"
                        }
                    }}
                    box_sx={{
                        m: 0, 
                        display: "flex", 
                        flexDirection: "column",
                        alignItems: "center", 
                        justifyContent: "center"
                    }}
                >
                    <IoCalendarOutline 
                        size={28} color="white"
                    />
                    {dateForm.start_date && <CustomText 
                        size="p" 
                        sx={{
                            fontSize: {xs: 14, lg: 16},
                            color: "white"
                        }}
                    >
                        {dateForm.start_date.getDate()}/{dateForm.start_date.getMonth() + 1}/{dateForm.start_date.getFullYear()} - 
                        {dateForm.end_date.getDate()}/{dateForm.end_date.getMonth() + 1}/{dateForm.end_date.getFullYear()}
                    </CustomText>}

                </CustomCard>
            </Grid>
            <Grid 
                item 
                xs={6} 
                md={4} 
                lg={2} 
                onClick={handleSelectProjectModalOpen}
            >
                <CustomCard 
                    cardWidth={'100%'} 
                    cardHeight={90} 
                    card_sx={{
                        border: 0, 
                        boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                        backgroundColor: 
                            theme.palette.mode === "dark" ? 
                            "vDarkBlue" : 
                            "lightBlue",
                        "&:hover": {
                            backgroundColor: 
                            theme.palette.mode === "dark" ? 
                            "lightBlue" : 
                            "vDarkBlue",
                            cursor: "pointer"
                        }
                    }}
                    box_sx={{
                        m: 0, 
                        pr: 2, 
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center"
                    }}
                >
                    <CustomText 
                        size="h6" 
                        sx={{
                            fontSize: {xs: 16, lg: 18},
                            color: "white"
                        }}
                    >
                        {selectedStatsProjects.length > 0 ? 
                            defaultTexts.selectedProjectsButtonText : 
                            defaultTexts.allProjectsButtonText
                        }
                    </CustomText>
                </CustomCard>
            </Grid>
        </Grid>}
        {openState && 
            <Box sx={{display: "flex", justifyContent: "flex-end", m: 1}}>
                <Button 
                        onClick={handleComponentCollapse}
                    >
                    {defaultTexts.hideStatsSubheaderButtonText}
                </Button>
            </Box>}
        {openState && <CustomDialog
                open={selectProjectsModalOpen}
                onClose={handleSelectProjectsModalClose}
                dialogTitle={{
                    title: defaultTexts.selecteProjectsDialogTitleText, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.selecteProjectsDialogMessageText, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.saveButtonText, 
                    onClick: handleProjectsSelection, 
                    variant: "contained",
                    size: "medium"
                }]}
            >
                <CustomSelectField 
                    currentValue={selectedProjects}
                    setCurrentValue={setSelectedProjects}
                    items={projects}
                    self_item_titles={true}
                    label={defaultTexts.selecteProjectsDialogTitleText}
                    size="large"
                    empty={true}
                    required={true}
                    multiple={true}
                    sx={{mt: 5, width: '90%'}}
                />
                <Box sx={{m: 3}}>
                    <CustomText 
                        size="p" 
                        sx={{
                            color: "secondary.main", 
                            fontSize: 18
                        }}
                    >
                        {defaultTexts.selectedProjectsButtonText}
                    </CustomText>
                </Box>
                
                {projects.filter(project => 
                    selectedStatsProjects.includes(project.value)).map(p => {
                    return (
                        <Box sx={{width: "90%", m: 3}} key={p.value}>
                            <CustomText size="p">{p.keyword.toUpperCase()}</CustomText>
                            <Divider />
                        </Box>
                    )
                })}
                
            </CustomDialog>}
            {openState && <CustomDialog
                open={selectStartEndDateModalOpen}
                onClose={handleStartEndDateModalClose}
                dialogTitle={{
                    title: defaultTexts.selectServerStatsDatesDialogTitleText, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.selectServerStatsDatesDialogMessageText, 
                    sx: {color: 'text.primary'}}}
            >
                {getFormFieldComponent(
                    {
                        field_key: "start_date",
                        field_type: "datetime", 
                        required: false, 
                        label: defaultTexts.startTimeTitleText
                    },
                    dateForm,
                    handleStartEndDateChange,
                    defaultTexts.startTimeTitleText
                )}
                {getFormFieldComponent(
                    {
                        field_key: "end_date",
                        field_type: "datetime", 
                        required: false, 
                        label: defaultTexts.endTimeTitleText
                    },
                    dateForm,
                    handleStartEndDateChange,
                    defaultTexts.endTimeTitleText
                )}
            </CustomDialog>}
        </React.Fragment>
    )
};

export default ComputeServersStatsSubheaderV21;
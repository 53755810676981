import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import SecurityGroupsSubheaderV20 from './securityGroupsSubheaderV2.0';
import SecurityGroupsTableV20 from './securityGroupsTableV2.0';
import SecurityGroupDetailV20 from './securityGroupDetailV2.0';
import { securityGroupsFilterMenu } 
from '../../../../../../_data/openstack/neutron/security/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { securityUrl as securityUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/security/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';
import CustomCheckboxField from '../../../../../_common/_form_fields/CustomCheckboxField';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const SecurityGroupsWrapperV20 = (props) => {
    const { navigate, location } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [securityGroupsData, setSecurityGroupsData] = useState([])
    const [securityGroups, setSecurityGroups] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedSecurityGroup, setSelectedSecurityGroup] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [securityGroupDeleteConfirmDialogOpen, setSecurityGroupDeleteConfirmDialogOpen] = useState(false);
    const [selectedSecurityGroups, setSelectedSecurityGroups] = useState([])
    const [securityGroupsSortParams, setSecurityGroupsSortParams] = useState("")
    
    const [securityGroupFilterQueryParams, setSecurityGroupFilterQueryParams] = useState("")
    const [selectedSecurityGroupFilter, setSelectedSecurityGroupFilter] = useState(securityGroupsFilterMenu[0].value)
    const [selectedSecurityGroupFilterValue, setSelectedSecurityGroupFilterValue] = useState("")
    const [securityGroupsFilter, setSecurityGroupsFilter] = useState([...securityGroupsFilterMenu])
    const [selectedStatefulState, setSelectedStatefulState] = useState(false)
    const [statefulStateChangeDialogOpen, setStatefulStateChangeDialogOpen] = useState(false)

    const [projects, setProjects] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const securityGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.securityGroupsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleSecurityGroupFilteredSearch = () => {
        if (selectedSecurityGroupFilter && selectedSecurityGroupFilterValue) {
            setSecurityGroupFilterQueryParams(`${selectedSecurityGroupFilter}=${selectedSecurityGroupFilterValue}`)
        } else {
            setSecurityGroupFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleSelectedStatefulStateChange = (event) => {
        setSelectedStatefulState(event.target.checked)
    }

    const handleStatefulStateChangeDialogClose = () => {
        setStatefulStateChangeDialogOpen(false)
    }

    const handleStatefulStateChangeDialogOpen = (sg_list) => {
        const selected_sg_list = securityGroupsData.filter(sg => 
            sg_list.includes(sg.id))
        setSelectedSecurityGroups([...selected_sg_list])
        setStatefulStateChangeDialogOpen(true)
    }

    const handleSecurityGroupsDataFormatting = useCallback(() => {
        const formatted_data = securityGroupsData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            new_item.security_group_rules = item.security_group_rules.length
            return new_item
        })
        setSecurityGroups(formatted_data)
    },[
        securityGroupsData,
        projects
    ])

    const handleSecurityGroupFilterReset = () => {
        setSelectedSecurityGroupFilter(securityGroupsFilterMenu[0].value)
        setSelectedSecurityGroupFilterValue("")
        setSecurityGroupFilterQueryParams("")
        handleDataFetch()
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedSecurityGroup(securityGroupsData[index].id)
        setSelectedRow(securityGroupsData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[securityGroupsData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedSecurityGroup(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleSecurityGroupsSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setSecurityGroupsSortParams(sort_param)
        handleDataFetch()
    }

    const handleChangeStatefulState = async (sg_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupsUrl}/${sg_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {security_group: {stateful: selectedStatefulState}},
                token: project_token
            })

            if (nt_response.status_code === securityUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    }

    const onChangeStatefulState = async () => {
        handleStatefulStateChangeDialogClose()
        let err = []
        for (let n in selectedSecurityGroups) {
            const resp = await handleChangeStatefulState(selectedSecurityGroups[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleSecurityGroupDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupsUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === securityUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onSecurityGroupDelete = async () => {
        handleSecurityGroupDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedSecurityGroups) {
            const resp = await handleSecurityGroupDelete(selectedSecurityGroups[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onSecurityGroupDeleteConfirm = (n_list) => {
        const selected_n_list = securityGroupsData.filter(n => 
            n_list.includes(n.id))
        setSelectedSecurityGroups([...selected_n_list])
        setSecurityGroupDeleteConfirmDialogOpen(true)
    }

    const handleSecurityGroupDeleteConfirmDialogClose = () => {
        setSecurityGroupDeleteConfirmDialogOpen(false)
    }

    const getSecurityGroupsActionsList = () => {
        let securityGroup_actions = []
        let new_action = {}
        new_action["value"] = "change_stateful_state"
        new_action["action"] = handleStatefulStateChangeDialogOpen
        new_action["keyword"] = "changeStatefulStateActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        securityGroup_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "securityGroup_delete"
        new_action["action"] = onSecurityGroupDeleteConfirm
        new_action["keyword"] = "securityGroupDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        securityGroup_actions.push({...new_action})
        
        return securityGroup_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupsUrl}?${securityGroupFilterQueryParams}${securityGroupsSortParams}`
                    const method = "GET"
                    const securityGroup_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (securityGroup_response.status_code === securityUrlResponses.get.success_response.status_code) {
                        setSecurityGroupsData(securityGroup_response.data.security_groups)
                        if (selectedSecurityGroup) {
                            const selected_securityGroup = securityGroup_response.data.security_groups.filter(item => item.id === selectedSecurityGroup)
                            if (selected_securityGroup.length > 0) {
                                setSelectedRow(selected_securityGroup[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        securityGroupsUrl, 
        securityGroupFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        securityGroupsSortParams,
        selectedSecurityGroup
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (securityGroupsData.length > 0) {
            handleSecurityGroupsDataFormatting()
        }
    },[
        securityGroupsData,
        handleSecurityGroupsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        
        let securityGroup_filter_menu = securityGroupsFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            return new_item
        })

        setSecurityGroupsFilter(securityGroup_filter_menu)
    },[
        securityGroupsData,
        projects
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const securityGroup_id = location.state ? location.state.securityGroup_id : null
                const securityGroup_index = securityGroupsData.findIndex(v => v.id === securityGroup_id);
                if (securityGroup_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(securityGroup_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        securityGroupsData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <SecurityGroupsSubheaderV20 
                selectedFilter={selectedSecurityGroupFilter} 
                setSelectedFilter={setSelectedSecurityGroupFilter}
                selectedFilterValue={selectedSecurityGroupFilterValue}
                setSelectedFilterValue={setSelectedSecurityGroupFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={securityGroupsFilter}
                handleFilteredSearch={handleSecurityGroupFilteredSearch}
                handleFilterReset={handleSecurityGroupFilterReset}
                projects={projects}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <SecurityGroupsTableV20 
                securityGroupsData={securityGroups}
                setSecurityGroupsData={setSecurityGroups}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getSecurityGroupsActionsList()}
                sortHandler={handleSecurityGroupsSorting}
                projects={projects}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <SecurityGroupDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleDelete={onSecurityGroupDeleteConfirm}
                    handleStatefulStateChange={handleStatefulStateChangeDialogOpen}
                    projects={projects}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={securityGroupDeleteConfirmDialogOpen}
            onClose={handleSecurityGroupDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.securityGroupDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.securityGroupDeleteConfirmText}: [${selectedSecurityGroups.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onSecurityGroupDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={statefulStateChangeDialogOpen}
            onClose={handleStatefulStateChangeDialogClose}
            dialogTitle={{
                title: defaultTexts.changeStatefulStateTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onChangeStatefulState, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomCheckboxField
                currentValue={selectedStatefulState}
                setCurrentValue={handleSelectedStatefulStateChange}
                label={defaultTexts.statefuleFormFieldLabel}
                default_value={false}
            />
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default SecurityGroupsWrapperV20;
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../../_common/WrapperBox';
import AddButton from '../../../../../_common/AddButton';
import FormGroup from '@mui/material/FormGroup';
import Constants from '../../../../../../config/constants';
import FilterButton from '../../../../../_common/FilterButton';
import CustomDialog from '../../../../../_common/CustomDialog';
import { 
    openStackServices, 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import { securityGroupDataForm } from 
'../../../../../../_data/openstack/neutron/security/v2.0';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import { 
    getXAuthTokenProjectScope,
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { securityUrl as securityUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/security/v2.0';
import Grid from '@mui/material/Grid';


const SERVICE_NAME = openStackServices.networkService

const SecurityGroupsSubheaderV20 = (props) => {
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu, projects } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successSecurityGroupAdd, setSuccessSecurityGroupAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const securityGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.securityGroupsUrl)[0].url)

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessSecurityGroupAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddSecurityGroupFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in securityGroupDataForm) {
            if (
                securityGroupDataForm[n].field_type === "string" || 
                securityGroupDataForm[n].field_type === "select"
                ) {
                new_form_data[securityGroupDataForm[n].field_key] = securityGroupDataForm[n].default_value ? 
                securityGroupDataForm[n].default_value : 
                ""
            } else if (securityGroupDataForm[n].field_type === "bool") {
                new_form_data[securityGroupDataForm[n].field_key] = securityGroupDataForm[n].default_value ? 
                securityGroupDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (securityGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (securityGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }


    const getDataForm = () => {
        let form = [...securityGroupDataForm]
        
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_key === "project_id") {
                        const projects_filter = projects.map(p => {
                            return {keyword: p.name, value: p.id, default: false}
                        })
                        form_field_options["items"] = [...projects_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in securityGroupDataForm) {
            if (securityGroupDataForm[n].required && !formData[securityGroupDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[securityGroupDataForm[n].field_key] = {}
                updatedDataFormOptions[securityGroupDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[securityGroupDataForm[n].field_key]["errorText"] = defaultTexts[securityGroupDataForm[n].error_label]
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onAddSecurityGroup = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            let data = {...formData}
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupsUrl}`
                const method = "POST"
                const securityGroup_response = await openstackRequest({
                    url: url, 
                    method: method, 
                    data: {security_group: data},
                    token: project_token
                })
                if (securityGroup_response.status_code === securityUrlResponses.post.success_response.status_code) {
                    handleDataFetch()
                    handleAddSecurityGroupFormReset()
                    setFormDataOptions({})
                } else {
                    const error_response = securityUrlResponses.post_async.error_response.filter(
                        error_item => error_item.status_code === securityGroup_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: securityGroup_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = securityUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: securityGroup_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }
        return validateFormData
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successSecurityGroupAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in securityGroupDataForm) {
                if (
                    securityGroupDataForm[n].field_type === "string" || 
                    securityGroupDataForm[n].field_type === "select"
                    ) {
                    new_form_data[securityGroupDataForm[n].field_key] = securityGroupDataForm[n].default_value ? 
                    securityGroupDataForm[n].default_value : 
                    ""
                } else if (securityGroupDataForm[n].field_type === "bool") {
                    new_form_data[securityGroupDataForm[n].field_key] = securityGroupDataForm[n].default_value ? 
                    securityGroupDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid 
                container 
                justifyContent='space-between' 
                alignItems="center">
                <Grid item>
                    <AddButton 
                        getDataForm={getDataForm}               
                        onSubmit={onAddSecurityGroup}
                        formReset={handleAddSecurityGroupFormReset}
                        customTexts={{
                            title: defaultTexts.addSecurityGroupDialogTitle
                        }}
                    />
                </Grid>
                <Grid item>
                    <FilterButton 
                        choice_mode={Constants.simple_filter_type}
                        currentFilter={selectedFilter}
                        setCurrentFilter={setSelectedFilter}
                        filter_menu_titles={defaultTexts}
                        filter_menu={filterMenu}
                        currentFilterValue={selectedFilterValue}
                        setCurrentFilterValue={setSelectedFilterValue}
                        onFilterSubmit={handleFilteredSearch}
                        onFilterReset={handleFilterReset}
                    />
                </Grid>
            </Grid>
            {successSecurityGroupAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successSecurityGroupAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successSecurityGroupAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default SecurityGroupsSubheaderV20
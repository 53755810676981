export const volumeTypeDataSchema = [
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "qos_specs_id",
        is_id_field: false,
        field_type: "string", 
        label: "qosSpecsFormFieldLabel"
    },
    {
        field_key: "is_public",
        is_id_field: false,
        field_type: "bool", 
        label: "formValuePublic",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const volumeTypesFilterMenu = [
    {
        keyword: "isPublicFormFieldLabel",
        value: "is_public",
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    }
]

export const volumeTypesSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    }
]

export const volumeTypeDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    },
    {
        field_key: "os-volume-type-access:is_public",
        field_type: "bool", 
        required: false,
        label: "isPublicFormFieldLabel",
        default_value: true
    }
]


export const qosSpecsDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "consumer",
        field_type: "select", 
        required: true, 
        label: "consumerFormFieldLabel", 
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true,
        with_help_text: true,
        help_text: "qosSpecsConsumerFormFieldHelpText",
        items: [
            {
                keyword: "back-end",
                value: "back-end",
                default: true
            },
            {
                keyword: "front-end",
                value: "front-end",
                default: false
            },
            {
                keyword: "both",
                value: "both",
                default: true
            },
        ]
    },
]

export const qosSpecsDataSchema = [
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "consumer",
        is_id_field: false,
        field_type: "string", 
        label: "consumerFormFieldLabel"
    },
    {
        field_key: "specs",
        is_id_field: false,
        field_type: "json_in_json", 
        label: "specsFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const qosSpecsSchema = [
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "consumer",
        is_id_field: false,
        field_type: "string", 
        label: "consumerFormFieldLabel"
    }
]

export const qosSpecsSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    }
]

export const volumeTypeEncryptionDataSchema = [
    {
        field_key: "encryption_id",
        is_id_field: false,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "provider",
        is_id_field: true,
        field_type: "string", 
        label: "providerFormFieldLabel"
    },
    {
        field_key: "control_location",
        is_id_field: false,
        field_type: "string", 
        label: "controlLocationFormFieldLabel"
    },
    {
        field_key: "cipher",
        is_id_field: false,
        field_type: "string", 
        label: "cipherFormFieldLabel"
    },
    {
        field_key: "key_size",
        is_id_field: false,
        field_type: "string", 
        label: "keySizeFormFieldLabel"
    }
]

export const encryptionDataForm = [
    {
        field_key: "provider",
        field_type: "select", 
        required: true, 
        label: "providerFormFieldLabel", 
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: false,
        default_empty: true,
        items: [
            {
                keyword: "luksFormFieldChoiceText",
                value: "luks",
                default: true
            },
            {
                keyword: "plainFormFieldChoiceText",
                value: "plain",
                default: false
            }
        ]
    },
    {
        field_key: "control_location",
        field_type: "select", 
        required: true, 
        label: "controlLocationFormFieldLabel", 
        error_label: "requiredFormFieldError",
        default_value: "front-end",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: false,
        items: [
            {
                keyword: "back-end",
                value: "back-end",
                default: true
            },
            {
                keyword: "front-end",
                value: "front-end",
                default: false
            }
        ]
    },
    {
        field_key: "key_size",
        field_type: "integer",
        required: false,
        label: "keySizeFormFieldLabel",
    },
    {
        field_key: "cipher",
        field_type: "string",
        required: false,
        label: "cipherFormFieldLabel",
    }
]


export const volumeTypeEncryptionDataUpdateForm = [
    {
        field_key: "provider",
        field_type: "select", 
        required: false, 
        label: "providerFormFieldLabel", 
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: false,
        default_empty: true,
        items: [
            {
                keyword: "luksFormFieldChoiceText",
                value: "luks",
                default: true
            },
            {
                keyword: "plainFormFieldChoiceText",
                value: "plain",
                default: false
            }
        ]
    },
    {
        field_key: "control_location",
        field_type: "select", 
        required: false, 
        label: "controlLocationFormFieldLabel", 
        error_label: "requiredFormFieldError",
        default_value: "front-end",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: false,
        items: [
            {
                keyword: "back-end",
                value: "back-end",
                default: true
            },
            {
                keyword: "front-end",
                value: "front-end",
                default: false
            }
        ]
    },
    {
        field_key: "key_size",
        field_type: "integer",
        required: false,
        label: "keySizeFormFieldLabel",
    },
    {
        field_key: "cipher",
        field_type: "string",
        required: false,
        label: "cipherFormFieldLabel",
    }
]

export const defaultVolumeTypesFilterMenu = [
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        items: [],
        self_item_titles: true
    },
    {
        keyword: "volumeTypeFormFieldLabel",
        value: "volume_type_id",
        type: "select", 
        multiple: false,
        items: [],
        self_item_titles: true
    }
]

export const defaultVolumeTypeSchema = [
    {
        field_key: "project_name",
        is_id_field: false,
        field_type: "string", 
        label: "projectNameFormFieldLabel"
    },
    {
        field_key: "volume_type_name",
        is_id_field: false,
        field_type: "string", 
        label: "volumeTypeFormFieldLabel"
    },
    {
        field_key: "project_id",
        is_id_field: true,
        field_type: "string", 
        label: "projectIDFormFieldLabel"
    }
]
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ServiceContentHeader from '../../../_common/ServiceContentHeader';
import Box from '@mui/material/Box';
import VolumeTypesWrapperV3 from './volume_types/volumeTypesWrapperV3';
import VolumesWrapperV3 from './volumes/volumesWrapperV3';
import SnapshotsWrapperV3 from './snapshots/snapshotsWrapperV3';
import BackupsWrapperV3 from './backups/backupsWrapperV3';
import GroupsWrapperV3 from './groups/groupsWrapperV3';
import GroupSnapshotsWrapperV3 from './group_snapshots/groupSnapshotsWrapperV3';
import GroupTypesWrapperV3 from './group_types/groupTypesWrapperV3';
import LimitsWrapperV3 from './limits/limitsWrapperV3';
import VolumeTransfersWrapperV3 from './volumes/volumeTransfersWrapperV3';
import DefaultVolumeTypesWrapperV3 from './volume_types/defaultVolumeTypesWrapperV3';
import QoSSpecsWrapperV3 from './volume_types/qosSpecsWrapperV3';

const VolumesCinderContentV3 = (props) => {
    const { navigate, location, section } = props
    const { serviceTitle } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const [volumesMenu, setVolumesMenu] = useState([
        {keyword: "submenuVolumes", navigation: "/volumes", is_active: true, component: VolumesWrapperV3},
        {keyword: "submenuVolumeTransfers", navigation: "/volume-transfers", is_active: false, component: VolumeTransfersWrapperV3},
    ])

    const [volumeTypesMenu, setVolumeTypesMenu] = useState([
        {keyword: "submenuVolumeTypes", navigation: "/volume-types", is_active: true, component: VolumeTypesWrapperV3},
        {keyword: "submenuQoSSpecs", navigation: "/qos-specs", is_active: false, component: QoSSpecsWrapperV3},
        {keyword: "submenuDefaultVolumeTypes", navigation: "/default-volume-types", is_active: false, component: DefaultVolumeTypesWrapperV3}
    ])

    const [snapshotsMenu, setSnapshotsMenu] = useState([
        {keyword: "submenuSnapshots", navigation: "/snapshots", is_active: true, component: SnapshotsWrapperV3}
    ])

    const [backupsMenu, setBackupsMenu] = useState([
        {keyword: "submenuBackups", navigation: "/backups", is_active: true, component: BackupsWrapperV3},
    ])

    const [volumeGroupsMenu, setVolumeGroupsMenu] = useState([
        {keyword: "submenuVolumeGroups", navigation: "/volume-groups", is_active: true, component: GroupsWrapperV3},
    ])

    const [groupSnapshotsMenu, setGroupSnapshotsMenu] = useState([
        {keyword: "submenuGroupSnapshots", navigation: "/volume-group-snapshots", is_active: true, component: GroupSnapshotsWrapperV3}
    ])

    const [groupTypesMenu, setGroupTypesMenu] = useState([
        {keyword: "submenuGroupTypes", navigation: "/volume-group-types", is_active: true, component: GroupTypesWrapperV3},
    ])

    const [limitsMenu, setLimitsMenu] = useState([
        {keyword: "submenuLimits", navigation: "/volume-limits", is_active: true, component: LimitsWrapperV3}
    ])

    const getCurrentTab = useCallback(() => {
        if (section === "/volumes") {
            const currentItem = volumesMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/volume-types") {
            if (location.state && location.state.volume_type_id) {
                const currentItem = volumeTypesMenu.filter(item => item.navigation === "/volume-types")[0]
                return currentItem
            } else if (location.state && location.state.qos_id) {
                const currentItem = volumeTypesMenu.filter(item => item.navigation === "/qos-specs")[0]
                return currentItem
            } else {
                const currentItem = volumeTypesMenu.filter(item => item.is_active)[0]
                return currentItem
            }
        } else if (section === "/snapshots") {
            const currentItem = snapshotsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/backups") {
            const currentItem = backupsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/volume-groups") {
            const currentItem = volumeGroupsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/group-snapshots") {
            const currentItem = groupSnapshotsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/group-types") {
            const currentItem = groupTypesMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/limits") {
            const currentItem = limitsMenu.filter(item => item.is_active)[0]
            return currentItem
        } 
    },[
        volumesMenu,
        volumeTypesMenu,
        snapshotsMenu,
        backupsMenu,
        volumeGroupsMenu,
        groupSnapshotsMenu,
        groupTypesMenu,
        limitsMenu,
        location,
        section
    ])

    const [currentTab, setCurrentTab] = useState(getCurrentTab())
    
    const changeMenuActiveTab = (navigation) => {
        if (section === "/volumes") {
            const updated_menu = volumesMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setVolumesMenu(updated_menu)
        } else if (section === "/volume-types") {
            const updated_menu = volumeTypesMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setVolumeTypesMenu(updated_menu)
        } else if (section === "/snapshots") {
            const updated_menu = snapshotsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setSnapshotsMenu(updated_menu)
        } else if (section === "/backups") {
            const updated_menu = backupsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setBackupsMenu(updated_menu)
        } else if (section === "/volume-groups") {
            const updated_menu = volumeGroupsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setVolumeGroupsMenu(updated_menu)
        } else if (section === "/group-snapshots") {
            const updated_menu = groupSnapshotsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setGroupSnapshotsMenu(updated_menu)
        } else if (section === "/group-types") {
            const updated_menu = groupTypesMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setGroupTypesMenu(updated_menu)
        } else if (section === "/limits") {
            const updated_menu = limitsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setLimitsMenu(updated_menu)
        } 
    }

    const getCurrentServiceMenu = () => {
        if (section === "/volumes") {
            return volumesMenu
        } else if (section === "/volume-types") {
            return volumeTypesMenu
        } else if (section === "/snapshots") {
            return snapshotsMenu
        } else if (section === "/backups") {
            return backupsMenu
        } else if (section === "/volume-groups") {
            return volumeGroupsMenu
        } else if (section === "/group-snapshots") {
            return groupSnapshotsMenu
        } else if (section === "/group-types") {
            return groupTypesMenu
        } else if (section === "/limits") {
            return limitsMenu
        }
    }

    useEffect(() => {
        setCurrentTab(getCurrentTab())
    },[
        section, getCurrentTab
    ]);

    return (
        <Box>
            <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={getCurrentServiceMenu()}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component 
                navigate={navigate} 
                location={location}
                changeMenuActiveTab={changeMenuActiveTab}
            />
        </Box>
    )
};

export default VolumesCinderContentV3;
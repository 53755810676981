import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import FirewallRulesSubheaderV20 from './firewallRulesSubheaderV2.0';
import FirewallRulesTableV20 from './firewallRulesTableV2.0';
import FirewallRuleDetailV20 from './firewallRuleDetailV2.0';
import { firewallRulesFilterMenu } 
from '../../../../../../_data/openstack/neutron/fwaas/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';
import CustomCheckboxField from '../../../../../_common/_form_fields/CustomCheckboxField';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const FirewallRulesWrapperV20 = (props) => {
    const { navigate, location } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [firewallRulesData, setFirewallRulesData] = useState([])
    const [firewallRules, setFirewallRules] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedFirewallRule, setSelectedFirewallRule] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [firewallRuleDeleteConfirmDialogOpen, setFirewallRuleDeleteConfirmDialogOpen] = useState(false);
    const [selectedFirewallRules, setSelectedFirewallRules] = useState([])
    const [firewallRulesSortParams, setFirewallRulesSortParams] = useState("")
    const [firewallPolicies, setFirewallPolicies] = useState([])
    const [firewallGroups, setFirewallGroups] = useState([])
    const [adminStateChangeDialogOpen, setAdminStateChangeDialogOpen] = useState(false);
    const [sharedStateChangeDialogOpen, setSharedStateChangeDialogOpen] = useState(false);
    const [selectedAdminState, setSelectedAdminState] = useState(true);
    const [selectedSharedState, setSelectedSharedState] = useState(true);
    
    const [firewallRuleFilterQueryParams, setFirewallRuleFilterQueryParams] = useState("")
    const [selectedFirewallRuleFilter, setSelectedFirewallRuleFilter] = useState(firewallRulesFilterMenu[0].value)
    const [selectedFirewallRuleFilterValue, setSelectedFirewallRuleFilterValue] = useState("")
    const [firewallRulesFilter, setFirewallRulesFilter] = useState([...firewallRulesFilterMenu])


    const [projects, setProjects] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const firewallRulesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.firewallRulesUrl)[0].url)
    const firewallPoliciesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.firewallPoliciesUrl)[0].url)
    const firewallGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.firewallGroupsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleSelectedSharedStateChange = (event) => {
        setSelectedSharedState(event.target.checked)
    }
    const handleSelectedAdminStateChange = (event) => {
        setSelectedAdminState(event.target.checked)
    }

    const handleSharedStateChangeDialogClose = () => {
        setSharedStateChangeDialogOpen(false)
    }

    const handleAdminStateChangeDialogClose = () => {
        setAdminStateChangeDialogOpen(false)
    }

    const handleSharedStateChangeDialogOpen = (fg_list) => {
        const selected_list = firewallRulesData.filter(f => 
            fg_list.includes(f.id))
        setSelectedFirewallRules([...selected_list])
        setSharedStateChangeDialogOpen(true)
    }

    const handleAdminStateChangeDialogOpen = (fg_list) => {
        const selected_list = firewallRulesData.filter(f => 
            fg_list.includes(f.id))
        setSelectedFirewallRules([...selected_list])
        setAdminStateChangeDialogOpen(true)
    }

    const handleChangeAdminState = async (fw_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}/${fw_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {firewall_rule: {enabled: selectedAdminState}},
                token: project_token
            })

            if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    }

    const onChangeAdminState = async () => {
        handleAdminStateChangeDialogClose()
        let err = []
        for (let n in selectedFirewallRules) {
            const resp = await handleChangeAdminState(selectedFirewallRules[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleChangeSharedState = async (fw_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}/${fw_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {firewall_rule: {shared: selectedSharedState}},
                token: project_token
            })

            if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    }

    const onChangeSharedState = async () => {
        handleSharedStateChangeDialogClose()
        let err = []
        for (let n in selectedFirewallRules) {
            const resp = await handleChangeSharedState(selectedFirewallRules[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleFirewallRuleFilteredSearch = () => {
        if (selectedFirewallRuleFilter && selectedFirewallRuleFilterValue) {
            setFirewallRuleFilterQueryParams(`${selectedFirewallRuleFilter}=${selectedFirewallRuleFilterValue}`)
        } else {
            setFirewallRuleFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleFirewallRulesDataFormatting = useCallback(() => {
        const formatted_data = firewallRulesData.map((row) => {
            let new_item = {...row}
            const project = projects.filter(p => p.id === row.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            let source = ""
            let destination = ""
            if (row.source_ip_address) {
                source = `${row.source_ip_address}`
            } else {
                source = "Any"
            }
            if (row.destination_ip_address) {
                destination = `${row.destination_ip_address}`
            } else {
                destination = "Any"
            }
            let protocol = ""
            if (row.protocol) {
                protocol = `${row.protocol.toUpperCase()}`
            } else {
                protocol = "Any"
            }
            let source_ports = ""
            if (row.source_port) {
                source_ports = `${row.source_port}`
            } else {
                source_ports = "Any"
            }
            let destination_ports = ""
            if (row.destination_port) {
                destination_ports = `${row.destination_port}`
            } else {
                destination_ports = "Any"
            }
            new_item.protocol = protocol
            new_item["source"] = source
            new_item["destination"] = destination
            new_item["source_ports"] = source_ports
            new_item["destination_ports"] = destination_ports

            const firewall_policy = firewallPolicies.filter(fp => fp.id === row.firewall_policy_id)[0]
            new_item.firewall_policy_id = firewall_policy ? firewall_policy.name : row.firewall_policy_id
            new_item.ip_version = `IPv${row.ip_version}`
            new_item.action = row.action.toUpperCase()
            return new_item
        })
        setFirewallRules(formatted_data)
    },[
        firewallRulesData,
        projects,
        firewallPolicies
    ])

    const handleFirewallRuleFilterReset = () => {
        setSelectedFirewallRuleFilter(firewallRulesFilterMenu[0].value)
        setSelectedFirewallRuleFilterValue("")
        setFirewallRuleFilterQueryParams("")
        handleDataFetch()
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedFirewallRule(firewallRulesData[index].id)
        setSelectedRow(firewallRulesData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[firewallRulesData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedFirewallRule(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFirewallRulesSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setFirewallRulesSortParams(sort_param)
        handleDataFetch()
    }

    const handleFirewallRuleDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === firewallUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onFirewallRuleDelete = async () => {
        handleFirewallRuleDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedFirewallRules) {
            const resp = await handleFirewallRuleDelete(selectedFirewallRules[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onFirewallRuleDeleteConfirm = (n_list) => {
        const selected_n_list = firewallRulesData.filter(n => 
            n_list.includes(n.id))
        setSelectedFirewallRules([...selected_n_list])
        setFirewallRuleDeleteConfirmDialogOpen(true)
    }

    const handleFirewallRuleDeleteConfirmDialogClose = () => {
        setFirewallRuleDeleteConfirmDialogOpen(false)
    }

    const getFirewallRulesActionsList = () => {
        let firewallRule_actions = []
        let new_action = {}
        new_action["value"] = "admin_state_update"
        new_action["action"] = handleAdminStateChangeDialogOpen
        new_action["keyword"] = "enableDisableRuleActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        firewallRule_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "share_state_update"
        new_action["action"] = handleSharedStateChangeDialogOpen
        new_action["keyword"] = "shareStateChangeActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        firewallRule_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "firewallRule_delete"
        new_action["action"] = onFirewallRuleDeleteConfirm
        new_action["keyword"] = "firewallRuleDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        firewallRule_actions.push({...new_action})
        
        return firewallRule_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}?${firewallRuleFilterQueryParams}${firewallRulesSortParams}`
                    const method = "GET"
                    const firewallRule_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (firewallRule_response.status_code === firewallUrlResponses.get.success_response.status_code) {
                        setFirewallRulesData(firewallRule_response.data.firewall_rules)
                        if (selectedFirewallRule) {
                            const selected_firewallRule = firewallRule_response.data.firewall_rules.filter(item => item.id === selectedFirewallRule)
                            if (selected_firewallRule.length > 0) {
                                setSelectedRow(selected_firewallRule[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        firewallRulesUrl, 
        firewallRuleFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        firewallRulesSortParams,
        selectedFirewallRule
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}`
                const method = "GET"
                
                const fp_response = await openstackRequest({
                    url:url, 
                    method:method,
                    token: project_token
                })
    
                if (fp_response.status_code === firewallUrlResponses.get.success_response.status_code) {
                    setFirewallPolicies(fp_response.data.firewall_policies)
                }
            }
        })();
    },[
        neutronServiceDomain,
        neutronServiceVersion,
        firewallPoliciesUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}`
                const method = "GET"
                
                const fp_response = await openstackRequest({
                    url:url, 
                    method:method,
                    token: project_token
                })
    
                if (fp_response.status_code === firewallUrlResponses.get.success_response.status_code) {
                    setFirewallGroups(fp_response.data.firewall_groups)
                }
            }
        })();
    },[
        neutronServiceDomain,
        neutronServiceVersion,
        firewallGroupsUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (firewallRulesData.length > 0) {
            handleFirewallRulesDataFormatting()
        }
    },[
        firewallRulesData,
        handleFirewallRulesDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        let fw_groups_filter = []
        if (firewallGroups.length > 0) {
            fw_groups_filter = firewallGroups.map(fg => {
                const project = projects.filter(pj => pj.id === fg.project_id)[0]
                return {keyword: `${project.name}: ${fg.name}`, value: fg.id, default: false}
            })
        }

        
        let firewallRule_filter_menu = firewallRulesFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            } else if (nt.value === "source_firewall_group_id" || 
                nt.value === "destination_firewall_group_id") {
                new_item.items = [...fw_groups_filter]
            }
            return new_item
        })

        setFirewallRulesFilter(firewallRule_filter_menu)
    },[
        firewallRulesData,
        projects,
        firewallGroups
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const firewallRule_id = location.state ? location.state.firewallRule_id : null
                const firewallRule_index = firewallRulesData.findIndex(v => v.id === firewallRule_id);
                if (firewallRule_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(firewallRule_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        firewallRulesData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <FirewallRulesSubheaderV20 
                selectedFilter={selectedFirewallRuleFilter} 
                setSelectedFilter={setSelectedFirewallRuleFilter}
                selectedFilterValue={selectedFirewallRuleFilterValue}
                setSelectedFilterValue={setSelectedFirewallRuleFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={firewallRulesFilter}
                handleFilteredSearch={handleFirewallRuleFilteredSearch}
                handleFilterReset={handleFirewallRuleFilterReset}
                projects={projects}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <FirewallRulesTableV20 
                firewallRulesData={firewallRules}
                seFirewallRulesData={setFirewallRules}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getFirewallRulesActionsList()}
                sortHandler={handleFirewallRulesSorting}
                projects={projects}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <FirewallRuleDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleDelete={onFirewallRuleDeleteConfirm}
                    projects={projects}
                    firewallPolicies={firewallPolicies}
                    firewallRules={firewallRulesData}
                    adminStateChange={handleAdminStateChangeDialogOpen}
                    sharedStateChange={handleSharedStateChangeDialogOpen}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={firewallRuleDeleteConfirmDialogOpen}
            onClose={handleFirewallRuleDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.firewallRuleDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.firewallRuleDeleteConfirmText}`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onFirewallRuleDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={adminStateChangeDialogOpen}
            onClose={handleAdminStateChangeDialogClose}
            dialogTitle={{
                title: defaultTexts.enableDisableRuleTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onChangeAdminState, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomCheckboxField
                currentValue={selectedAdminState}
                setCurrentValue={handleSelectedAdminStateChange}
                label={defaultTexts.enabledFormFieldLabel}
                default_value={false}
            />
        </CustomDialog>
        <CustomDialog
            open={sharedStateChangeDialogOpen}
            onClose={handleSharedStateChangeDialogClose}
            dialogTitle={{
                title: defaultTexts.changeSharedStateTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onChangeSharedState, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomCheckboxField
                currentValue={selectedSharedState}
                setCurrentValue={handleSelectedSharedStateChange}
                label={defaultTexts.sharedFormFieldLabel}
                default_value={false}
            />
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default FirewallRulesWrapperV20;
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import FirewallGroupsSubheaderV20 from './firewallGroupsSubheaderV2.0';
import FirewallGroupsTableV20 from './firewallGroupsTableV2.0';
import FirewallGroupDetailV20 from './firewallGroupDetailV2.0';
import { firewallGroupsFilterMenu } 
from '../../../../../../_data/openstack/neutron/fwaas/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';
import CustomCheckboxField from '../../../../../_common/_form_fields/CustomCheckboxField';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const FirewallGroupsWrapperV20 = (props) => {
    const { navigate, location } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [firewallGroupsData, setFirewallGroupsData] = useState([])
    const [firewallGroups, setFirewallGroups] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedFirewallGroup, setSelectedFirewallGroup] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [firewallGroupDeleteConfirmDialogOpen, setFirewallGroupDeleteConfirmDialogOpen] = useState(false);
    const [selectedFirewallGroups, setSelectedFirewallGroups] = useState([])
    const [firewallGroupsSortParams, setFirewallGroupsSortParams] = useState("")
    const [firewallPolicies, setFirewallPolicies] = useState([])
    const [adminStateChangeDialogOpen, setAdminStateChangeDialogOpen] = useState(false);
    const [sharedStateChangeDialogOpen, setSharedStateChangeDialogOpen] = useState(false);
    const [selectedAdminState, setSelectedAdminState] = useState(true);
    const [selectedSharedState, setSelectedSharedState] = useState(true);
    
    const [firewallGroupFilterQueryParams, setFirewallGroupFilterQueryParams] = useState("")
    const [selectedFirewallGroupFilter, setSelectedFirewallGroupFilter] = useState(firewallGroupsFilterMenu[0].value)
    const [selectedFirewallGroupFilterValue, setSelectedFirewallGroupFilterValue] = useState("")
    const [firewallGroupsFilter, setFirewallGroupsFilter] = useState([...firewallGroupsFilterMenu])


    const [projects, setProjects] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const firewallGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.firewallGroupsUrl)[0].url)
    const firewallPoliciesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.firewallPoliciesUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleSelectedSharedStateChange = (event) => {
        setSelectedSharedState(event.target.checked)
    }
    const handleSelectedAdminStateChange = (event) => {
        setSelectedAdminState(event.target.checked)
    }

    const handleSharedStateChangeDialogClose = () => {
        setSharedStateChangeDialogOpen(false)
    }

    const handleAdminStateChangeDialogClose = () => {
        setAdminStateChangeDialogOpen(false)
    }

    const handleSharedStateChangeDialogOpen = (fg_list) => {
        const selected_list = firewallGroupsData.filter(f => 
            fg_list.includes(f.id))
        setSelectedFirewallGroups([...selected_list])
        setSharedStateChangeDialogOpen(true)
    }

    const handleAdminStateChangeDialogOpen = (fg_list) => {
        const selected_list = firewallGroupsData.filter(f => 
            fg_list.includes(f.id))
        setSelectedFirewallGroups([...selected_list])
        setAdminStateChangeDialogOpen(true)
    }

    const handleChangeAdminState = async (fw_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}/${fw_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {firewall_group: {admin_state_up: selectedAdminState}},
                token: project_token
            })

            if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    }

    const onChangeAdminState = async () => {
        handleAdminStateChangeDialogClose()
        let err = []
        for (let n in selectedFirewallGroups) {
            const resp = await handleChangeAdminState(selectedFirewallGroups[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleChangeSharedState = async (fw_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}/${fw_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {firewall_group: {shared: selectedSharedState}},
                token: project_token
            })

            if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    }

    const onChangeSharedState = async () => {
        handleSharedStateChangeDialogClose()
        let err = []
        for (let n in selectedFirewallGroups) {
            const resp = await handleChangeSharedState(selectedFirewallGroups[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleFirewallGroupFilteredSearch = () => {
        if (selectedFirewallGroupFilter && selectedFirewallGroupFilterValue) {
            setFirewallGroupFilterQueryParams(`${selectedFirewallGroupFilter}=${selectedFirewallGroupFilterValue}`)
        } else {
            setFirewallGroupFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleFirewallGroupsDataFormatting = useCallback(() => {
        const formatted_data = firewallGroupsData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            new_item.ports = item.ports.length
            const in_policy = firewallPolicies.filter(fp => fp.id === item.ingress_firewall_policy_id)
            const out_policy = firewallPolicies.filter(fp => fp.id === item.egress_firewall_policy_id)
            if (in_policy.length > 0) {
                new_item.ingress_firewall_policy_id = in_policy[0].name ? in_policy[0].name : item.ingress_firewall_policy_id
            }
            if (out_policy.length > 0) {
                new_item.egress_firewall_policy_id = out_policy[0].name ? out_policy[0].name : item.egress_firewall_policy_id
            }
            return new_item
        })
        setFirewallGroups(formatted_data)
    },[
        firewallGroupsData,
        projects,
        firewallPolicies
    ])

    const handleFirewallGroupFilterReset = () => {
        setSelectedFirewallGroupFilter(firewallGroupsFilterMenu[0].value)
        setSelectedFirewallGroupFilterValue("")
        setFirewallGroupFilterQueryParams("")
        handleDataFetch()
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedFirewallGroup(firewallGroupsData[index].id)
        setSelectedRow(firewallGroupsData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[firewallGroupsData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedFirewallGroup(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFirewallGroupsSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setFirewallGroupsSortParams(sort_param)
        handleDataFetch()
    }

    const handleFirewallGroupDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === firewallUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onFirewallGroupDelete = async () => {
        handleFirewallGroupDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedFirewallGroups) {
            const resp = await handleFirewallGroupDelete(selectedFirewallGroups[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onFirewallGroupDeleteConfirm = (n_list) => {
        const selected_n_list = firewallGroupsData.filter(n => 
            n_list.includes(n.id))
        setSelectedFirewallGroups([...selected_n_list])
        setFirewallGroupDeleteConfirmDialogOpen(true)
    }

    const handleFirewallGroupDeleteConfirmDialogClose = () => {
        setFirewallGroupDeleteConfirmDialogOpen(false)
    }

    const getFirewallGroupsActionsList = () => {
        let firewallGroup_actions = []
        let new_action = {}
        new_action["value"] = "admin_state_update"
        new_action["action"] = handleAdminStateChangeDialogOpen
        new_action["keyword"] = "adminStateChangeActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        firewallGroup_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "share_state_update"
        new_action["action"] = handleSharedStateChangeDialogOpen
        new_action["keyword"] = "shareStateChangeActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        firewallGroup_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "firewallGroup_delete"
        new_action["action"] = onFirewallGroupDeleteConfirm
        new_action["keyword"] = "firewallGroupDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        firewallGroup_actions.push({...new_action})
        
        return firewallGroup_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}?${firewallGroupFilterQueryParams}${firewallGroupsSortParams}`
                    const method = "GET"
                    const firewallGroup_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (firewallGroup_response.status_code === firewallUrlResponses.get.success_response.status_code) {
                        setFirewallGroupsData(firewallGroup_response.data.firewall_groups)
                        if (selectedFirewallGroup) {
                            const selected_firewallGroup = firewallGroup_response.data.firewall_groups.filter(item => item.id === selectedFirewallGroup)
                            if (selected_firewallGroup.length > 0) {
                                setSelectedRow(selected_firewallGroup[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        firewallGroupsUrl, 
        firewallGroupFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        firewallGroupsSortParams,
        selectedFirewallGroup
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}`
                const method = "GET"
                
                const fp_response = await openstackRequest({
                    url:url, 
                    method:method,
                    token: project_token
                })
    
                if (fp_response.status_code === firewallUrlResponses.get.success_response.status_code) {
                    setFirewallPolicies(fp_response.data.firewall_policies)
                }
            }
        })();
    },[
        neutronServiceDomain,
        neutronServiceVersion,
        firewallPoliciesUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (firewallGroupsData.length > 0) {
            handleFirewallGroupsDataFormatting()
        }
    },[
        firewallGroupsData,
        handleFirewallGroupsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        
        let firewallGroup_filter_menu = firewallGroupsFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            return new_item
        })

        setFirewallGroupsFilter(firewallGroup_filter_menu)
    },[
        firewallGroupsData,
        projects
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const firewallGroup_id = location.state ? location.state.firewallGroup_id : null
                const firewallGroup_index = firewallGroupsData.findIndex(v => v.id === firewallGroup_id);
                if (firewallGroup_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(firewallGroup_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        firewallGroupsData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <FirewallGroupsSubheaderV20 
                selectedFilter={selectedFirewallGroupFilter} 
                setSelectedFilter={setSelectedFirewallGroupFilter}
                selectedFilterValue={selectedFirewallGroupFilterValue}
                setSelectedFilterValue={setSelectedFirewallGroupFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={firewallGroupsFilter}
                handleFilteredSearch={handleFirewallGroupFilteredSearch}
                handleFilterReset={handleFirewallGroupFilterReset}
                projects={projects}
                firewallPolicies={firewallPolicies}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <FirewallGroupsTableV20 
                firewallGroupsData={firewallGroups}
                seFirewallGroupsData={setFirewallGroups}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getFirewallGroupsActionsList()}
                sortHandler={handleFirewallGroupsSorting}
                projects={projects}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <FirewallGroupDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleDelete={onFirewallGroupDeleteConfirm}
                    projects={projects}
                    firewallPolicies={firewallPolicies}
                    firewallGroups={firewallGroupsData}
                    adminStateChange={handleAdminStateChangeDialogOpen}
                    sharedStateChange={handleSharedStateChangeDialogOpen}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={firewallGroupDeleteConfirmDialogOpen}
            onClose={handleFirewallGroupDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.firewallGroupDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.firewallGroupDeleteConfirmText}: [${selectedFirewallGroups.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onFirewallGroupDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={adminStateChangeDialogOpen}
            onClose={handleAdminStateChangeDialogClose}
            dialogTitle={{
                title: defaultTexts.changeAdminStateTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onChangeAdminState, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomCheckboxField
                currentValue={selectedAdminState}
                setCurrentValue={handleSelectedAdminStateChange}
                label={defaultTexts.adminStateFormFieldLabel}
                default_value={false}
            />
        </CustomDialog>
        <CustomDialog
            open={sharedStateChangeDialogOpen}
            onClose={handleSharedStateChangeDialogClose}
            dialogTitle={{
                title: defaultTexts.changeSharedStateTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onChangeSharedState, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomCheckboxField
                currentValue={selectedSharedState}
                setCurrentValue={handleSelectedSharedStateChange}
                label={defaultTexts.sharedFormFieldLabel}
                default_value={false}
            />
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default FirewallGroupsWrapperV20;
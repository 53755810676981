import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import { openStackServices, 
        blockStorageCinderConstants, 
        identityKeystonConstants
} from '../../../../../config/openStackConstants';
import BackupsSubheaderV3 from './backupsSubheaderV3';
import BackupsTableV3 from './backupsTableV3';
import { backupsFilterMenu, backupStatusList } 
from '../../../../../_data/openstack/cinder/backups/v3';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../_network/openstack_request';
import { backupsUrl as backupUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/backups/v3';
import BackupDetailV3 from './backupDetailV3';
import { projectsUrl as projectsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/projects/v3';
import { volumesUrl as volumeUrlResponses } from 
'../../../../../_api_responses/openstack/cinder/volumes/v3';

const SERVICE_NAME = openStackServices.volumeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const BackupsWrapperV3 = (props) => {
    const { navigate, location } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [backupsData, setBackupsData] = useState([])
    const [backups, setBackups] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedBackup, setSelectedBackup] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [backupDeleteConfirmDialogOpen, setBackupDeleteConfirmDialogOpen] = useState(false);
    const [backupStatusUpdateDialogOpen, setBackupStatusUpdateDialogOpen] = useState(false);
    const [selectedBackups, setSelectedBackups] = useState([])
    const [backupsSortParams, setBackupsSortParams] = useState("")
    
    const [backupFilterQueryParams, setBackupFilterQueryParams] = useState("?all_tenants=true")
    const [selectedBackupFilter, setSelectedBackupFilter] = useState(backupsFilterMenu[0].value)
    const [selectedBackupFilterValue, setSelectedBackupFilterValue] = useState("")
    const [backupsFilter, setBackupsFilter] = useState([...backupsFilterMenu])
    const [selectedBackupStatus, setSelectedBackupStatus] = useState("");
    const [selectedBackupStatusError, setSelectedBackupStatusError] = useState(false);

    const [projects, setProjects] = useState([])
    const [volumes, setVolumes] = useState([])
    const [snapshots, setSnapshots] = useState([])

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const backupsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.backupsUrl)[0].url)
    const volumesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
    const snapshotsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.snapshotsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleBackupFilteredSearch = () => {
        if (selectedBackupFilter && selectedBackupFilterValue) {
            setBackupFilterQueryParams(`?${selectedBackupFilter}=${selectedBackupFilterValue}&&all_tenants=true`)
        } else {
            setBackupFilterQueryParams("?all_tenants=true")
        }
        handleDataFetch()
    }

    const handleBackupsDataFormatting = useCallback(() => {
        const formatted_data = backupsData.map((item) => {
            let new_item = {...item}
            new_item.size = `${item.size}GB`
            new_item.bootable = Boolean(item.bootable === "true")
            const project = projects.filter(p => p.id === item[blockStorageCinderConstants.backupProjectIdField])
            if (project.length > 0) {
                new_item[blockStorageCinderConstants.backupProjectIdField] = project[0].name
            }
            const volume = volumes.filter(v => v.id === item.volume_id)
            if (volume.length > 0) {
                new_item.volume_id = volume[0].name ? volume[0].name : volume[0].id
            }
            return new_item
        })
        setBackups(formatted_data)
    },[
        backupsData,
        volumes,
        projects
    ])

    const handleBackupFilterReset = () => {
        setSelectedBackupFilter(backupsFilterMenu[0].value)
        setSelectedBackupFilterValue("")
        setBackupFilterQueryParams("?all_tenants=true")
        handleDataFetch()
    }

    const handleNavigateToVolume = (volume_id) => {
        handleDetailCardClose()
        navigate("/block-storage/volumes",{state: {volume_id: volume_id}})
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedBackup(backupsData[index].id)
        setSelectedRow(backupsData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[backupsData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedBackup(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleBackupsSorting = (field,direction) => {
        const sort_param = `&&sort=${field}:${direction}`
        setBackupsSortParams(sort_param)
        handleDataFetch()
    }

    const handleBackupDelete = async (s_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/${s_id}/action`
            const method = "POST"
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                data: {"os-force_delete": {}},
                token: project_token
            })
            if (vt_response.status_code === backupUrlResponses.post_async.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    };

    const handleBackupStatusUpdate = async (s_id) => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/${s_id}/action`
                const method = "POST"
                
                const b_response = await volumeCinderRequest({
                    url:url, 
                    method:method,
                    token: project_token,
                    data: {"os-reset_status": {
                        status: selectedBackupStatus
                    }},
                    has_response: false
                })

                if (b_response.status_code === backupUrlResponses.post_async.success_response.status_code) {
                    return null
                } else {
                    return b_response.error
                }
        }
    }

    const onBackupDelete = async () => {
        handleBackupDeleteConfirmDialogClose()
        let err = []
        for (let s in selectedBackups) {
            const resp = await handleBackupDelete(selectedBackups[s].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onBackupDeleteConfirm = (s_list) => {
        const selected_s_list = backupsData.filter(s => 
            s_list.includes(s.id))
        setSelectedBackups([...selected_s_list])
        setBackupDeleteConfirmDialogOpen(true)
    }

    const handleBackupDeleteConfirmDialogClose = () => {
        setBackupDeleteConfirmDialogOpen(false)
    }

    const onBackupStatusUpdate = (s_list) => {
        const selected_s_list = backupsData.filter(s => 
            s_list.includes(s.id))
        setSelectedBackups([...selected_s_list])
        setBackupStatusUpdateDialogOpen(true)
    }

    const handleBackupStatusUpdateDialogClose = () => {
        setSelectedBackupStatus("")
        setSelectedBackupStatusError(false)
        setBackupStatusUpdateDialogOpen(false)
    }

    const onBackupStatusUpdateSubmit = async () => {
        if (selectedBackupStatus.length === 0) {
            setSelectedBackupStatusError(true)
            return false
        } else {
            handleBackupStatusUpdateDialogClose()
            let err = []
            for (let s in selectedBackups) {
                const resp = await handleBackupStatusUpdate(selectedBackups[s].id)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
            setSelectedBackupStatus("")
            handleDataFetch()
            if (err.length > 0) {
                let error_object = {}
                error_object["error_title"] = "errorDeleteRecordTitle"
                error_object["error_message"] = "errorDeleteRecordMessage"
                error_object["error_details"] = err.toString()
                setError(error_object)
                setErrorDialogOpen(true)
            }
        }
    }

    const getBackupsActionsList = () => {
        let backup_actions = []
        let new_action = {}
        new_action["value"] = "backup_delete"
        new_action["action"] = onBackupDeleteConfirm
        new_action["keyword"] = "backupDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        backup_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "backup_status_update"
        new_action["action"] = onBackupStatusUpdate
        new_action["keyword"] = "backupStatusUpdateActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        backup_actions.push({...new_action})
        
        return backup_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/detail${backupFilterQueryParams}${backupsSortParams}`
                    const method = "GET"
                    const backups_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (backups_response.status_code === backupUrlResponses.get.success_response.status_code) {
                        setBackupsData(backups_response.data.backups)
                        if (selectedBackup) {
                            const selected_backup = backups_response.data.backups.filter(item => item.id === selectedBackup)
                            if (selected_backup.length > 0) {
                                setSelectedRow(selected_backup[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        backupsUrl, 
        backupFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        backupsSortParams,
        selectedBackup
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail?all_tenants=true`
                const method = "GET"

                const volume_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (volume_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    setVolumes(volume_response.data.volumes)
                }
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        volumesUrl
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/detail?all_tenants=true`
                const method = "GET"

                const snapshot_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (snapshot_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    setSnapshots(snapshot_response.data.snapshots)
                }
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        snapshotsUrl
    ]);


    useEffect(() => {
        if (backupsData.length > 0) {
            handleBackupsDataFormatting()
        }
    },[
        backupsData,
        handleBackupsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }
        let volume_list = []
        if (volumes.length > 0) {
            volume_list = volumes.map(vt => {
                return {keyword: `${vt.name} (${vt.id})`, value: vt.id, default: false}
            })
        }
        let snapshot_list = []
        if (snapshots.length > 0 ) {
            snapshot_list = snapshots.map(sn => {
                return {keyword: sn.name, value: sn.id, default: false}
            })
        }

        let backup_filter_menu = backupsFilterMenu.map(fl => {
            let new_item = {...fl}
            if (fl.value === "project_id") {
                new_item.items = [...projects_filter]
            } else if (fl.value === "volume_id") {
                new_item.items = [...volume_list]
            } else if (fl.value === "snapshot_id") {
                new_item.items = [...snapshot_list]
            }
            return new_item
        })
        setBackupsFilter(backup_filter_menu)
    },[
        backupsData,
        projects,
        volumes,
        snapshots
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const backup_id = location.state ? location.state.backup_id : null
                const backup_index = backupsData.findIndex(v => v.id === backup_id);
                if (backup_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(backup_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        backupsData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <BackupsSubheaderV3 
                selectedFilter={selectedBackupFilter} 
                setSelectedFilter={setSelectedBackupFilter}
                selectedFilterValue={selectedBackupFilterValue}
                setSelectedFilterValue={setSelectedBackupFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={backupsFilter}
                handleFilteredSearch={handleBackupFilteredSearch}
                handleFilterReset={handleBackupFilterReset}
                volumes={volumes}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading &&
                <BackupsTableV3 
                    data={backups}
                    setData={setBackups}
                    handleRowSelection={handleDetailCardOpen}
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    actionsTexts={defaultTexts}
                    actionsList={getBackupsActionsList()}
                    sortHandler={handleBackupsSorting}
                />
            }
            {selectedRow !== null && 
                <CustomSideDrawer 
                    open={detailCardOpen}
                    widthWeight={WIDTH_WEIGHT}
                    handleDrawerOpen={handleDetailCardOpen}
                    handleDrawerClose={handleDetailCardClose}
                > 
                    <BackupDetailV3
                        selectedRow={selectedRow}
                        selectedBackup={backups.filter(s => s.id === selectedRow.id)[0]}
                        widthWeight={WIDTH_WEIGHT}
                        handleFetchData={handleDataFetch}
                        handleNavigateToVolume={handleNavigateToVolume}
                        handleDelete={onBackupDeleteConfirm}
                        handleStatusUpdate={onBackupStatusUpdate}
                    />  
                </CustomSideDrawer>
            }
            <CustomDialog
                open={backupDeleteConfirmDialogOpen}
                onClose={handleBackupDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.backupDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.backupDeleteConfirmText}: [${selectedBackups.map(v => v.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onBackupDelete, 
                    sx: {color: 'primary.main'}}]}
            />
            <CustomDialog
                open={backupStatusUpdateDialogOpen}
                onClose={handleBackupStatusUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts.backupStatusUpdateTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onBackupStatusUpdateSubmit, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomSelectField
                    items={backupStatusList}
                    required={true}
                    error={selectedBackupStatusError}
                    errorText={defaultTexts.requiredFormFieldError}
                    currentValue={selectedBackupStatus}
                    setCurrentValue={setSelectedBackupStatus}
                    label={defaultTexts.backupStatusFormFieldLabel}
                    self_item_titles={false}
                    item_titles={defaultTexts}
                    empty={true}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default BackupsWrapperV3;
import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import FormHelperText from '@mui/material/FormHelperText';
import CustomText from '../CustomText';
import { ClickAwayListener } from '@mui/material';
import parse from 'html-react-parser';

const ITEM_HEIGHT = 46;
const ITEM_PADDING_TOP = 8;
const FIELD_WIDTH = 250

const getStyles = (item, selectedItems, theme) => {
    const selected_items = typeof selectedItems === 'string' ? 
    selectedItems.split(',') : 
    selectedItems
    return {
      fontWeight:
      selected_items && selected_items.indexOf(item) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
}

const CustomSelectField = (props) => {
    const { currentValue, setCurrentValue } = props;
    const { items } = props;
    const { item_titles, self_item_titles } = props;
    const { label, size, empty, fieldWidth } = props;
    const { multiple } = props;
    const { field_key } = props
    const { withHelp, helpText } = props
    const { sx, menuProps } = props;
    const { disabled, error, errorText, required } = props;
    const { index, zIndex, helpTextWidth, helpTextHeight } = props
  
    const theme = useTheme();

    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [helpOpen, setHelpOpen] = useState(false);
    const [placement, setPlacement] = useState();

    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: fieldWidth ? fieldWidth : FIELD_WIDTH,
          ...menuProps
        },
      },
    };

    const handleClose = () => {
      setOpen(false);
    };
  
    const handleOpen = () => {
      if (!disabled) {
        setOpen(true);
      } else {
        setOpen(false);
      }
      
    };

    const handleHelpTextClose = () => {
      setHelpOpen(false)
  }

    const handleClick = (newPlacement) => (event) => {
      setAnchorEl(event.currentTarget);
      setHelpOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

    const onChange = (event) => {
      const { target: { value },} = event;
      
      if (multiple) {
        setCurrentValue(
          typeof value === 'string' ? 
          value.split(',') : 
          value,
          field_key,
          index
        );
      } else {
        setCurrentValue(value,field_key,index);
      }
    };

    useEffect(() => {
      if (multiple) {
        if (currentValue && currentValue.length === 0 && !empty) {
          const default_items = items.filter(item => item.default)
          setCurrentValue(default_items.map(item => item.value))
        }
      } else {
        if (currentValue && currentValue.length === 0 && !empty) {
          const default_item = items.filter(item => item.default)
          setCurrentValue(default_item[0].value)
        }
      }
    },[currentValue,items,multiple,setCurrentValue,empty]);  

    if (withHelp) {
      return (
        <Grid 
          container 
          spacing={2} 
          direction="row" 
          alignItems="center"
          justifyContent="start"
        >
          <Grid item xs={10} sm={11}>
            <FormControl 
              error={error}
              required={required}
              sx={{ 
                width: '100%',
                ...sx
              }}
              size={size ? size : "medium"}
            >
              <InputLabel>{label}</InputLabel>
              <Select
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  multiple={multiple ? multiple : false}
                  value={currentValue}
                  onChange={onChange}
                  input={<OutlinedInput label={label} />}
                  MenuProps={MenuProps}
              >
                  {items.map((item) => (
                      <MenuItem
                          key={item.value}
                          value={item.value}
                          style={getStyles(item.value, currentValue, theme)}
                      >
                          {self_item_titles ? item.keyword : item_titles[item.keyword] }
                      </MenuItem>
                  ))}
              </Select>
              {error && 
                <FormHelperText>
                    {errorText}
                </FormHelperText>
              }
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <ClickAwayListener onClickAway={handleHelpTextClose}>
              <IconButton onClick={handleClick('top-start')}>
                <HelpIcon />
              </IconButton>
            </ClickAwayListener>
            <Popper
                sx={{ zIndex: zIndex ? zIndex : 1200 }}
                open={helpOpen}
                anchorEl={anchorEl}
                placement={placement}
                transition
            >
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper 
                        sx={{
                            width: helpTextWidth ? helpTextWidth : 200, 
                            height: helpTextHeight ? helpTextHeight : undefined,
                            p: 2, 
                            backgroundColor: "paper",
                            overflowY: "scroll"
                        }}
                    >
                        <CustomText 
                            size="p" 
                            sx={{color: "text.primary"}}
                        >
                          {parse(helpText)}
                        </CustomText>
                    </Paper>
                </Fade>
                )}
            </Popper>
          </Grid>
        </Grid>
    )
  }

  return (
    <FormControl 
      error={error}
      required={required}
      sx={{ 
        width: fieldWidth ? fieldWidth : FIELD_WIDTH,
        ...sx
      }}
      size={size ? size : "medium"}
    >
      <InputLabel>{label}</InputLabel>
      <Select
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          multiple={multiple ? multiple : false}
          value={currentValue}
          onChange={onChange}
          input={<OutlinedInput label={label} />}
          MenuProps={MenuProps}
      >
          {items.map((item) => (
              <MenuItem
                  key={item.value}
                  value={item.value}
                  style={getStyles(item.value, currentValue, theme)}
              >
                {self_item_titles ? item.keyword : item_titles[item.keyword] }
              </MenuItem>
          ))}
      </Select>
      {error && 
        <FormHelperText>
            {errorText}
        </FormHelperText>
      }
    </FormControl>
  )
};

export default CustomSelectField;
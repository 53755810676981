import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import { openStackServices, 
        blockStorageCinderConstants, 
        identityKeystonConstants
} from '../../../../../config/openStackConstants';
import SnapshotsSubheaderV3 from './snapshotsSubheaderV3';
import SnapshotsTableV3 from './snapshotsTableV3';
import { snapshotsFilterMenu, snapshotStatusList } 
from '../../../../../_data/openstack/cinder/snapshots/v3';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../_network/openstack_request';
import { snapshotsUrl as snapshotUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/snapshots/v3';
import SnapshotDetailV3 from './snapshotDetailV3';
import { projectsUrl as projectsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/projects/v3';
import { volumesUrl as volumeUrlResponses } from 
'../../../../../_api_responses/openstack/cinder/volumes/v3';

const SERVICE_NAME = openStackServices.volumeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const SnapshotsWrapperV3 = (props) => {
    const { navigate, location } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [snapshotsData, setSnapshotsData] = useState([])
    const [snapshots, setSnapshots] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedSnapshot, setSelectedSnapshot] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [snapshotDeleteConfirmDialogOpen, setSnapshotDeleteConfirmDialogOpen] = useState(false);
    const [snapshotStatusUpdateDialogOpen, setSnapshotStatusUpdateDialogOpen] = useState(false);
    const [selectedSnapshots, setSelectedSnapshots] = useState([])
    const [snapshotsSortParams, setSnapshotsSortParams] = useState("")
    
    const [snapshotFilterQueryParams, setSnapshotFilterQueryParams] = useState("?all_tenants=true")
    const [selectedSnapshotFilter, setSelectedSnapshotFilter] = useState(snapshotsFilterMenu[0].value)
    const [selectedSnapshotFilterValue, setSelectedSnapshotFilterValue] = useState("")
    const [snapshotsFilter, setSnapshotsFilter] = useState([...snapshotsFilterMenu])
    const [selectedSnapshotStatus, setSelectedSnapshotStatus] = useState("");
    const [selectedSnapshotStatusError, setSelectedSnapshotStatusError] = useState(false);

    const [projects, setProjects] = useState([])
    const [volumes, setVolumes] = useState([])
    const [groupSnapshots, setGroupSnapshots] = useState([])

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const snapshotsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.snapshotsUrl)[0].url)
    const volumesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    const groupSnapshotsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.groupSnapshotsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleSnapshotFilteredSearch = () => {
        if (selectedSnapshotFilter && selectedSnapshotFilterValue) {
            setSnapshotFilterQueryParams(`?${selectedSnapshotFilter}=${selectedSnapshotFilterValue}&&all_tenants=true`)
        } else {
            setSnapshotFilterQueryParams("?all_tenants=true")
        }
        handleDataFetch()
    }

    const handleSnapshotsDataFormatting = useCallback(() => {
        const formatted_data = snapshotsData.map((item) => {
            let new_item = {...item}
            new_item.size = `${item.size}GB`
            new_item.bootable = Boolean(item.bootable === "true")
            const project = projects.filter(p => p.id === item[blockStorageCinderConstants.snapshotProjectIdField])
            if (project.length > 0) {
                new_item[blockStorageCinderConstants.snapshotProjectIdField] = project[0].name
            }
            const volume = volumes.filter(v => v.id === item.volume_id)
            if (volume.length > 0) {
                new_item.volume_id = volume[0].name ? volume[0].name : volume[0].id
            }
            const group_snapshots = groupSnapshots.filter(gs => gs.id === item.group_snapshot_id)
            if (group_snapshots.length > 0) {
                new_item.group_snapshot_id = group_snapshots[0].name ? group_snapshots[0].name : group_snapshots[0].id
            }
            return new_item
        })
        setSnapshots(formatted_data)
    },[
        snapshotsData,
        volumes,
        projects,
        groupSnapshots
    ])

    const handleSnapshotFilterReset = () => {
        setSelectedSnapshotFilter(snapshotsFilterMenu[0].value)
        setSelectedSnapshotFilterValue("")
        setSnapshotFilterQueryParams("?all_tenants=true")
        handleDataFetch()
    }

    const handleNavigateToVolume = (volume_id) => {
        handleDetailCardClose()
        navigate("/block-storage/volumes",{state: {volume_id: volume_id}})
    }

    const handleNavigateToBackup = (backup_id) => {
        handleDetailCardClose()
        navigate("/block-storage/backups",{state: {backup_id: backup_id}})
    }

    const handleNavigateToGroupSnapshot = (group_snapshot_id) => {
        handleDetailCardClose()
        navigate("/block-storage/group-snapshots",{state: {group_snapshot_id: group_snapshot_id}})
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedSnapshot(snapshotsData[index].id)
        setSelectedRow(snapshotsData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[snapshotsData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedSnapshot(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleSnapshotsSorting = (field,direction) => {
        const sort_param = `&&sort=${field}:${direction}`
        setSnapshotsSortParams(sort_param)
        handleDataFetch()
    }

    const handleSnapshotDelete = async (s_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/${s_id}`
            const method = "DELETE"
            
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (vt_response.status_code === snapshotUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    };

    const handleSnapshotStatusUpdate = async (s_id) => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/${s_id}/action`
                const method = "POST"
                
                const vt_response = await volumeCinderRequest({
                    url:url, 
                    method:method,
                    token: project_token,
                    data: {"os-reset_status": {
                        status: selectedSnapshotStatus
                    }},
                    has_response: false
                })

                if (vt_response.status_code === snapshotUrlResponses.post_async.success_response.status_code) {
                    return null
                } else {
                    return vt_response.error
                }
        }
    }

    const onSnapshotDelete = async () => {
        handleSnapshotDeleteConfirmDialogClose()
        let err = []
        for (let s in selectedSnapshots) {
            const resp = await handleSnapshotDelete(selectedSnapshots[s].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onSnapshotDeleteConfirm = (s_list) => {
        const selected_s_list = snapshotsData.filter(s => 
            s_list.includes(s.id))
        setSelectedSnapshots([...selected_s_list])
        setSnapshotDeleteConfirmDialogOpen(true)
    }

    const handleSnapshotDeleteConfirmDialogClose = () => {
        setSnapshotDeleteConfirmDialogOpen(false)
    }

    const onSnapshotStatusUpdate = (s_list) => {
        const selected_s_list = snapshotsData.filter(s => 
            s_list.includes(s.id))
        setSelectedSnapshots([...selected_s_list])
        setSnapshotStatusUpdateDialogOpen(true)
    }

    const handleSnapshotStatusUpdateDialogClose = () => {
        setSelectedSnapshotStatus("")
        setSelectedSnapshotStatusError(false)
        setSnapshotStatusUpdateDialogOpen(false)
    }

    const onSnapshotStatusUpdateSubmit = async () => {
        if (selectedSnapshotStatus.length === 0) {
            setSelectedSnapshotStatusError(true)
            return false
        } else {
            handleSnapshotStatusUpdateDialogClose()
            let err = []
            for (let s in selectedSnapshots) {
                const resp = await handleSnapshotStatusUpdate(selectedSnapshots[s].id)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
            setSelectedSnapshotStatus("")
            handleDataFetch()
            if (err.length > 0) {
                let error_object = {}
                error_object["error_title"] = "errorDeleteRecordTitle"
                error_object["error_message"] = "errorDeleteRecordMessage"
                error_object["error_details"] = err.toString()
                setError(error_object)
                setErrorDialogOpen(true)
            }
        }
    }

    const getSnapshotsActionsList = () => {
        let snapshot_actions = []
        let new_action = {}
        new_action["value"] = "snapshot_delete"
        new_action["action"] = onSnapshotDeleteConfirm
        new_action["keyword"] = "snapshotDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        snapshot_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "snapshot_status_update"
        new_action["action"] = onSnapshotStatusUpdate
        new_action["keyword"] = "snapshotStatusUpdateActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        snapshot_actions.push({...new_action})
        
        return snapshot_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/detail${snapshotFilterQueryParams}${snapshotsSortParams}`
                    const method = "GET"
                    const snapshots_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (snapshots_response.status_code === snapshotUrlResponses.get.success_response.status_code) {
                        setSnapshotsData(snapshots_response.data.snapshots)
                        if (selectedSnapshot) {
                            const selected_snapshot = snapshots_response.data.snapshots.filter(item => item.id === selectedSnapshot)
                            if (selected_snapshot.length > 0) {
                                setSelectedRow(selected_snapshot[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        snapshotsUrl, 
        snapshotFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        snapshotsSortParams,
        selectedSnapshot
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
            (async () => {
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupSnapshotsUrl}/detail?all_tenants=true`
                    const method = "GET"
                    const snapshots_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (snapshots_response.status_code === snapshotUrlResponses.get.success_response.status_code) {
                        setGroupSnapshots(snapshots_response.data.group_snapshots)
                    }
                }
            })();
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        groupSnapshotsUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail?all_tenants=true`
                const method = "GET"

                const volume_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (volume_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    setVolumes(volume_response.data.volumes)
                }
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        volumesUrl
    ]);


    useEffect(() => {
        if (snapshotsData.length > 0) {
            handleSnapshotsDataFormatting()
        }
    },[
        snapshotsData,
        handleSnapshotsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }
        let volume_list = []
        if (volumes.length > 0) {
            volume_list = volumes.map(vt => {
                return {keyword: `${vt.name} (${vt.id})`, value: vt.id, default: false}
            })
        }

        let snapshot_filter_menu = snapshotsFilterMenu.map(fl => {
            let new_item = {...fl}
            if (fl.value === "project_id") {
                new_item.items = [...projects_filter]
            } else if (fl.value === "volume_id") {
                new_item.items = [...volume_list]
            }
            return new_item
        })

        setSnapshotsFilter(snapshot_filter_menu)
    },[
        snapshotsData,
        projects,
        volumes
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const snapshot_id = location.state ? location.state.snapshot_id : null
                const snapshot_index = snapshotsData.findIndex(v => v.id === snapshot_id);
                if (snapshot_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(snapshot_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        snapshotsData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <SnapshotsSubheaderV3 
                selectedFilter={selectedSnapshotFilter} 
                setSelectedFilter={setSelectedSnapshotFilter}
                selectedFilterValue={selectedSnapshotFilterValue}
                setSelectedFilterValue={setSelectedSnapshotFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={snapshotsFilter}
                handleFilteredSearch={handleSnapshotFilteredSearch}
                handleFilterReset={handleSnapshotFilterReset}
                volumes={volumes}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading &&
                <SnapshotsTableV3 
                    data={snapshots}
                    setData={setSnapshots}
                    handleRowSelection={handleDetailCardOpen}
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    actionsTexts={defaultTexts}
                    actionsList={getSnapshotsActionsList()}
                    sortHandler={handleSnapshotsSorting}
                />
            }
            {selectedRow !== null && 
                <CustomSideDrawer 
                    open={detailCardOpen}
                    widthWeight={WIDTH_WEIGHT}
                    handleDrawerOpen={handleDetailCardOpen}
                    handleDrawerClose={handleDetailCardClose}
                > 
                    <SnapshotDetailV3
                        selectedRow={selectedRow}
                        selectedSnapshot={snapshots.filter(s => s.id === selectedRow.id)[0]}
                        widthWeight={WIDTH_WEIGHT}
                        handleFetchData={handleDataFetch}
                        handleNavigateToVolume={handleNavigateToVolume}
                        handleNavigateToBackup={handleNavigateToBackup}
                        handleNavigateToGroupSnapshot={handleNavigateToGroupSnapshot}
                        handleDelete={onSnapshotDeleteConfirm}
                        handleStatusUpdate={onSnapshotStatusUpdate}
                    />  
                </CustomSideDrawer>
            }
            <CustomDialog
                open={snapshotDeleteConfirmDialogOpen}
                onClose={handleSnapshotDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.snapshotDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.snapshotDeleteConfirmText}: [${selectedSnapshots.map(v => v.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onSnapshotDelete, 
                    sx: {color: 'primary.main'}}]}
            />
            <CustomDialog
                open={snapshotStatusUpdateDialogOpen}
                onClose={handleSnapshotStatusUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts.snapshotStatusUpdateTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onSnapshotStatusUpdateSubmit, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomSelectField
                    items={snapshotStatusList}
                    required={true}
                    error={selectedSnapshotStatusError}
                    errorText={defaultTexts.requiredFormFieldError}
                    currentValue={selectedSnapshotStatus}
                    setCurrentValue={setSelectedSnapshotStatus}
                    label={defaultTexts.snapshotStatusFormFieldLabel}
                    self_item_titles={false}
                    item_titles={defaultTexts}
                    empty={true}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default SnapshotsWrapperV3;
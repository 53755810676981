import React from 'react';
import { useSelector } from 'react-redux';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';
import { blockStorageCinderConstants } 
from '../../../../../config/openStackConstants';
import Box from '@mui/material/Box';

const detailTabCardConfig = {
    border: 0.3,
    borderRadius: 2,
    borderStyle: "dashed",
    borderColor: "text.secondary",
    minHeight: 450
}

const VolumeSpecsV3 = (props) => {
    const { 
        volumeData, 
        selectedRow, 
        handleNavigateToVolumeGroup,
        handleNavigateToVolumeType,
        handleNavigateToSnapshot,
        handleNavigateToVolume,
        handleNavigateToImage,
        volumes,
        snapshots
     } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);

    return (
        <React.Fragment>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack direction="column" spacing={1} sx={{width: "90%"}}>
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.idFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData ? volumeData.id : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.nameFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData.name ? volumeData.name : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.projectFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData[blockStorageCinderConstants.volumeProjectIdField] ? 
                                        volumeData[blockStorageCinderConstants.volumeProjectIdField] : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.userFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData.user_id ? volumeData.user_id : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.availabilityZoneFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData.availability_zone ? volumeData.availability_zone : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.hostFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData["os-vol-host-attr:host"] ? volumeData["os-vol-host-attr:host"] : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.statusFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData.status.toUpperCase()}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.groupFormFieldLabel}
                                </CustomText>
                                {volumeData.group_id ? <Box 
                                    sx={{cursor: "pointer", color: "primary.main"}}
                                    onClick={() => 
                                        handleNavigateToVolumeGroup(
                                            selectedRow.group_id
                                        )
                                    }
                                >
                                    <CustomText size="p">
                                        {volumeData.group_id}
                                    </CustomText>
                                </Box> : 
                                <CustomText size="p">-</CustomText>
                                }
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.clusterFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData.cluster_name ? volumeData.cluster_name : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                        </Stack>
                    </WrapperBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack 
                            direction="column" 
                            spacing={1} 
                            sx={{width: "90%"}}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.sizeFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData.size}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.volumeSourceFormFieldLabel}
                                </CustomText>
                                {volumeData.snapshot_id && 
                                    <Box
                                        onClick={() => handleNavigateToSnapshot(volumeData.snapshot_id)}
                                        sx={{cursor: "pointer", color: "primary.main"}}
                                    >
                                        <CustomText size="p">
                                            {defaultTexts.snapshotFormFieldLabel}: {
                                                snapshots.length > 0 ? 
                                                snapshots.filter(sn => sn.id === volumeData.snapshot_id)[0].name : volumeData.snapshot_id
                                            }
                                        </CustomText>
                                    </Box>}
                                {volumeData.source_volid && 
                                    <Box
                                        onClick={() => handleNavigateToVolume(volumeData.source_volid)}
                                        sx={{cursor: "pointer", color: "primary.main"}}
                                    >
                                        <CustomText size="p">
                                            {defaultTexts.volumeFormFieldLabel}: {volumes.filter(v => v.id === volumeData.source_volid)[0].name}
                                        </CustomText>
                                    </Box>}
                                {Object.keys(volumeData).includes("volume_image_metadata") && 
                                    <Box
                                        onClick={() => handleNavigateToImage(volumeData.volume_image_metadata.image_id)}
                                        sx={{cursor: "pointer", color: "primary.main"}}
                                    >
                                        <CustomText size="p">
                                            {defaultTexts.imageFormFieldLabel} {volumeData.volume_image_metadata.image_name}
                                        </CustomText>
                                    </Box>}
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.bootableFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData.bootable  ? defaultTexts.formValueYes : defaultTexts.formValueNo}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.volumeTypeFormFieldLabel}
                                </CustomText>
                                {volumeData.volume_type ? <Box 
                                    sx={{cursor: "pointer", color: "primary.main"}}
                                    onClick={() => 
                                        handleNavigateToVolumeType(
                                            selectedRow.volume_type_id
                                        )
                                    }
                                >
                                    <CustomText size="p">
                                        {volumeData.volume_type}
                                    </CustomText>
                                </Box> : 
                                <CustomText size="p">-</CustomText>}
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.encryptedFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData.encrypted ? 
                                        defaultTexts.formValueYes : defaultTexts.formValueNo}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.createTimeHeaderLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {new Date(volumeData.created_at).toLocaleString()}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.updatedAtFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {new Date(volumeData.updated_at).toLocaleString()}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.multiattachFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData.multiattach ? defaultTexts.formValueYes : defaultTexts.formValueNo}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.descriptionFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {volumeData.description ? volumeData.description : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                        </Stack>
                    </WrapperBox>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default VolumeSpecsV3;
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../_common/WrapperBox';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Button, Divider } from '@mui/material';
import { projectsUrl as projectsUrlResponses} from
'../../../../../_api_responses/openstack/identity/projects/v3';
import { flavorsUrl as flavorsUrlResponses } from 
'../../../../../_api_responses/openstack/compute/flavors/v2.1';
import { openstackRequest, computeNovaRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { imagesUrl as imagesUrlResponses } from 
'../../../../../_api_responses/openstack/glance/images/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { 
    computeNovaConstants, 
    identityKeystonConstants,
    imagesGlanceConstants
} from 
'../../../../../config/openStackConstants';
import { Stack } from '@mui/material';
import ComputeServersStatsSubheaderV21 from './computeServersStatsSubheaderV2_1';
import ComputeServersTableV21 from './computeServersTableV2_1';
import CustomText from '../../../../_common/CustomText';
import CustomDialog from '../../../../_common/CustomDialog';
import Box from '@mui/material/Box';
import { 
    power_states,
    serversFilterGroups, 
    serversFilters
} from '../../../../../_data/openstack/compute/servers/v2.1';
import ComputeServerDetailV21 from './computeServerDetailsV2_1';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ComputeServerFilterV21 from './computeServerFilterV2_1';
import ComputeServerLunchDialogV21 from './computeServerLunchDialogV2_1';

const SERVICE_NAME = openStackServices.computeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService
const IMAGE_SERVICE_NAME = openStackServices.imageService
const flavors_default_url_query = "?is_public=none"

const ComputeServersWrapperV21 = (props) => {
    const { navigate, location } = props
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [serversData, setServersData] = useState([])
    const [servers, setServers] = useState([])
    const [flavorsData, setFlavorsData] = useState([])
    const [selectedFlavor, setSelectedFlavor] = useState();
    const [flavorDialogModalOpen, setFlavorDialogModalOpen] = useState(false)
    const [projectsList, setProjectsList] = useState([])
    const [usersList, setUsersList] = useState([])
    const { width, height } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedServer, setSelectedServer] = useState(null);
    const [fetchDataRequired, setFetchDataRequired] = useState(true);
    const [serversStatusToCheck,setServersStatusToCheck] = useState({})
    const [intervalId, setIntervalId] = useState(null)
    const [startedCheck, setStartedCheck] = useState(false)
    const [currentAction, setCurrentAction] = useState("");
    const [serverDeleteConfirmDialogOpen, setServerDeleteConfirmDialogOpen] = useState(false);
    const [selectedServers, setSelectedServers] = useState([])
    const [currentFilters, setCurrentFilters] = useState({})
    const [queryParams, setQueryParams] = useState("")
    const [sortParams, setSortParams] = useState("")
    const [serverGroups, setServerGroups] = useState([])
    const [serverCreateDialogOpen, setServerCreateDialogOpen] = useState(false)
    const [images, setImages] = useState([]);
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const serversUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serversUrl)[0].url)
    const serverGroupsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serverGroupsUrl)[0].url)
    const flavorsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.flavorsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    const usersUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.usersUrl)[0].url)
    const imageServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IMAGE_SERVICE_NAME)[0].config_params.service_domain)
    const imageServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IMAGE_SERVICE_NAME)[0].config_params.api_version)
    const imagesUrl = useSelector(
        state => state.imageGlance.imageGlanceApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === imagesGlanceConstants.imagesUrl)[0].url)

    const handleServerCreateDialogOpen = () => {
        setServerCreateDialogOpen(true)
    }

    const handleServerCreateDialogClose = () => {
        setServerCreateDialogOpen(false)
    }


    const handleServerDelete = async (server) => {
        const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${server.id}`
        const method = "DELETE"
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const server_response = await openstackRequest({
                url:url, 
                method:method, 
                token: project_token
            })
            if (server_response.status_code === 204) {
                return true
            } else {
                return false
            }
        }
    }

    const onServerDelete = async () => {
        handleServerDeleteConfirmDialogClose()
        setIsLoading(true)
        for (let s in selectedServers) {
            await handleServerDelete(selectedServers[s])
        }
        setIsLoading(false)
        handleDataFetch()
    }


    const onServerDeleteConfirm = (server_list) => {
        const selected_server_list = serversData.filter(srv => 
            server_list.includes(srv.id))
        setSelectedServers([...selected_server_list])
        setServerDeleteConfirmDialogOpen(true)
    }

    const handleServerDeleteConfirmDialogClose = () => {
        setServerDeleteConfirmDialogOpen(false)
    }

    const getServersActionsList = () => {
        let server_actions = []
        let new_action = {}
        new_action["value"] = "server_delete"
        new_action["action"] = onServerDeleteConfirm
        new_action["keyword"] = "serverDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        server_actions.push({...new_action})
        
        return server_actions
    }

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleServerStatusChange = (status,id) => {
        if (!Object.keys(serversStatusToCheck).includes(id)) {
            let new_list = {...serversStatusToCheck}
            new_list[id] = status
            setServersStatusToCheck(new_list)
        }
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedRow(servers[index])
        setSelectedServer(serversData.filter(item => 
            item.id === servers[index].id)[0])
        
        setTimeout(() => setDetailCardOpen(true), 100)    
    },[servers, serversData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false), 100)  
        setSelectedRow(null)
        setSelectedServer(null)
        navigate(location.path,{})
    };

    const handleNavigateToFlavor = (flavor_id) => {
        handleDetailCardClose()
        navigate("/compute/flavors",{state: {flavor_id: flavor_id}})
    }

    const handleNavigateToVolume = (volume_id) => {
        handleDetailCardClose()
        navigate("/block-storage/volumes",{state: {volume_id: volume_id}})
    }

    const handleNavigateToNetwork = (network_id) => {
        handleDetailCardClose()
        navigate("/networks/l2networking",{state: {network_id: network_id}})
    }

    const handleNavigateToSubnet = (subnet_id) => {
        handleDetailCardClose()
        navigate("/networks/l3networking",{state: {subnet_id: subnet_id}})
    }

    const handleNavigateToPort = (port_id) => {
        handleDetailCardClose()
        navigate("/networks/l2networking",{state: {port_id: port_id}})
    }

    const handleNavigateToSecurityGroup = (sec_group_id) => {
        handleDetailCardClose()
        navigate("/networks/security",{state: {securityGroup_id: sec_group_id}})
    }

    const handleCurrentFiltersChange = (event,field_key) => {
        let new_filter_data = {...currentFilters}
        for (let g in serversFilterGroups) {
            const field_item = serversFilters[serversFilterGroups[g].name].filter(
                item => item.field_key === field_key)
            if (field_item.length > 0) {
                if (serversFilters[serversFilterGroups[g].name].filter(
                    item => item.field_key === field_key)[0].field_type === "bool") {
                    new_filter_data[field_key] = event.target.checked
                } else if (serversFilters[serversFilterGroups[g].name].filter(
                    item => item.field_key === field_key)[0].field_type === "select" ||
                    serversFilters[serversFilterGroups[g].name].filter(
                        item => item.field_key === field_key)[0].field_type === "datetime"
                ) {
                    new_filter_data[field_key] = event
                } else {
                    new_filter_data[field_key] = event.target.value
                }
            }
        }
        setCurrentFilters(new_filter_data)
    }

    const handleServerFilterReset = () => {
        setCurrentFilters({})
        setQueryParams("")
        handleDataFetch()
    }

    const handleServerSearch = () => {
        let query_params = ""
        for (let key in currentFilters) {
            if (currentFilters[key]) {
                if (key === "changes-since" || key === "created_at") {
                    const val = currentFilters[key].toISOString()
                    query_params = `${query_params}&${key}=${val}`
                } else {
                    query_params = `${query_params}&${key}=${currentFilters[key]}`
                }
            }
        }
        setQueryParams(query_params)
        handleDataFetch()
    }

    const handleFlavorDataFormatting = useCallback((data) => {
        const formatted_data = data.map(item => {
            let new_item = {...item}
            if (item.ram > (1024 * 1024) - 1) {
                new_item.ram = `${Math.round(item.ram / 1024 * 1024)} TB`
            } else if (item.ram > 1023) {
                new_item.ram = `${Math.round(item.ram / 1024)} GB`
            } else {
                new_item.ram = `${item.ram} MB`
            }

            if (item.swap) {
                if (item.swap > 1023) {
                    new_item.swap = `${Math.round(item.swap / 1024 * 1024)} GB`
                } else {
                    new_item.swap = `${item.swap} MB`
                }
            }

            if (item["os-flv-ext-data:ephemeral"]) {
                new_item["os-flv-ext-data:ephemeral"] = `${item["os-flv-ext-data:ephemeral"]} GB`
            }

            if (item.disk) {
                new_item.disk = `${item.disk} GB`
            }
            
            return new_item
        })

        setFlavorsData(formatted_data)
    },[])

    const flavorDialogOpen = useCallback((row) => {
        const flavor_id = servers[row].flavor_details ? 
            servers[row].flavor_details.id : 
            servers[row].flavor
        setSelectedFlavor(flavorsData.filter(fl => fl.id === flavor_id)[0])
        setFlavorDialogModalOpen(true)
    },[flavorsData,servers])

    const flavorDialogClose = () => {
        setSelectedFlavor(undefined)
        setFlavorDialogModalOpen(false)
    }

    const FlavorPopoverComponent = (props) => {
        const { flavor } = props;
        return (
        <Button>
            {flavor.name}
        </Button>)}

    const handleServerDataFormatting = useCallback((data) => {
        const formatted_data = data.map(item => {
            let new_item = {...item}
            if (flavorsData.length > 0) {
                const flavor = flavorsData.filter(
                    fl => fl.id === item.flavor.id)
                if (flavor.length > 0) {
                    new_item.flavor = <FlavorPopoverComponent 
                        flavor={flavor[0]} 
                        />
                    new_item.flavor_details = flavor[0]
                } else {
                    new_item.flavor = item.flavor.id
                }
            } else {
                new_item.flavor = item.flavor.id
            }
            if (projectsList.length > 0) {
                new_item.tenant_id = projectsList.filter(
                    pj => pj.value === item.tenant_id)[0].keyword
            }
            if (usersList.length > 0) {
                new_item.user_id = usersList.filter(
                    us => us.value === item.user_id)[0].keyword
            }
            new_item.addresses = []
            if (Object.keys(item.addresses).length > 0) {
                for (let a in item.addresses) {
                    for (let i in item.addresses[a]) {
                        new_item.addresses.push(item.addresses[a][i].addr)
                    }
                    
                }
            }
            new_item["OS-EXT-STS:power_state"] = defaultTexts[power_states[item["OS-EXT-STS:power_state"]]]
            
            const age_mins = (new Date() - new Date(item.created)) / 1000 / 60
            if (age_mins < 60) {
                new_item.age = `${parseInt(age_mins)} minutes`
            } else if (age_mins < 1440) {
                new_item.age = `${parseInt(age_mins / 60)} hours, ${parseInt(age_mins % 60)} minutes`
            } else {
                new_item.age = `${parseInt(age_mins / 1440)} days`
            }

            if (Object.keys(serversStatusToCheck).includes(item.id)) {
                new_item.progress = <WrapperBox
                sx={{
                    borderColor: "primary"
                }}
            >
                <CircularProgress />
            </WrapperBox>
            } else {
                new_item.progress = "-"
            }

            if (item.image) {
                const image = images.filter(im => im.id === item.image.id)
                if (image.length > 0) {
                    new_item.image = image[0].name
                } else {
                    new_item.image = item.image.id
                }
            } else {
                new_item.image = "-"
            }
            
            return new_item
        })
        setServers(formatted_data)
    },[
        flavorsData,
        projectsList,
        usersList,
        defaultTexts,
        images,
        serversStatusToCheck
    ])

    const handleServerSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setSortParams(sort_param)
        handleDataFetch()
    }

    useEffect(() => {
        (async () => {
            const url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}/detail${flavors_default_url_query}`
            const method = "GET"

            const flavors_response = await openstackRequest({url:url, method:method})
            if (flavors_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
                handleFlavorDataFormatting([...flavors_response.data.flavors])
            }
        })();
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        flavorsUrl, 
        handleFlavorDataFormatting
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"
            const projects_response = await openstackRequest({url:url, method:method})
            
            if ( projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                const projects_list = projects_response.data.projects.map(project => {
                    return {keyword: project.name, value: project.id, default: false}
                })
                setProjectsList(projects_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}`
            const method = "GET"
            const users_response = await openstackRequest({url:url, method:method})
            
            if ( users_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                const users_list = users_response.data.users.map(user => {
                    return {keyword: user.name, value: user.id, default: false}
                })
                setUsersList(users_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        usersUrl
    ]);

    useEffect(() => {
        (async () => {
            const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const images_response = await openstackRequest({url:url, method:method, token: project_token})
                if (images_response.status_code === imagesUrlResponses.get.success_response.status_code) {
                    setImages(images_response.data.images)
                }
            }
        })();
    },[
        imageServiceDomain, 
        imageServiceVersion, 
        imagesUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        if (fetchDataRequired) {
                (async () => {
                    handleLoading(true)
                    const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/detail?all_tenants=true${queryParams}${sortParams}`
                    const method = "GET"
                    const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                    if (project_token) {
                        const servers_response = await openstackRequest({
                            url:url, 
                            method:method, 
                            token: project_token
                        })
                        if (Object.keys(serversStatusToCheck).length > 0) {
                        const changed_servers = servers_response.data.servers.filter(s => Object.keys(serversStatusToCheck).includes(s.id))
                        let new_list = {...serversStatusToCheck}
                        if (changed_servers.length > 0) {
                            for (let sr in changed_servers) {
                                if (new_list[changed_servers[sr].id] !== changed_servers[sr].status) {
                                    delete new_list[changed_servers[sr].id]
                                }
                            }
                        }
                        setServersStatusToCheck(new_list)
                    }
                        setServersData(servers_response.data.servers)
                    }
                })();
           setFetchDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},1200)
    },[
        computeServiceDomain,
        computeServiceVersion,
        serversUrl,
        defaultAdminProject,
        flavorsData,
        projectsList,
        usersList,
        fetchDataRequired,
        selectedRow,
        serversStatusToCheck,
        intervalId,
        queryParams,
        sortParams
    ]);

    useEffect(() => {
        (async () => {
            const url = `${computeServiceDomain}/${computeServiceVersion}/${serverGroupsUrl}?all_projects=true`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const server_groups_response = await computeNovaRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                setServerGroups(server_groups_response.data.server_groups)
            }
        })();
    },[
        computeServiceDomain,
        computeServiceVersion,
        serverGroupsUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        handleServerDataFormatting(serversData)
    },[
        handleServerDataFormatting,
        flavorsData,
        projectsList,
        usersList,
        serversData
    ]);

    useEffect(() => {
        if (selectedServer && servers.length > 0) {
            const s = servers.filter(item => item.id === selectedServer.id)
            if (s.length > 0) {
                setSelectedRow(s[0])
            }
        }
    },[
        servers,
        selectedServer
    ]);

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        let interval_id = null
        if (Object.keys(serversStatusToCheck).length > 0 && !startedCheck) {
            handleServerDataFormatting(serversData)
            setStartedCheck(true)
                interval_id = setInterval(() => {
                    handleDataFetch(); 
                },10000)
                setIntervalId(interval_id)
        }
        if (Object.keys(serversStatusToCheck).length === 0) {
            if (intervalId) {
                clearInterval(intervalId)
                setStartedCheck(false)
                setIntervalId(null)
            }
            handleServerDataFormatting(serversData)
        }
    },[
        serversStatusToCheck,
        serversData,
        handleServerDataFormatting,
        startedCheck,
        intervalId
    ])

    useEffect(() => {
        if (Object.keys(currentFilters).length === 0) {
            let new_filter_data = {}
            for (let g in serversFilterGroups) {
                for (let n in serversFilters[serversFilterGroups[g].name]) {
                    if (
                        serversFilters[serversFilterGroups[g].name][n].field_type === "string" || 
                        serversFilters[serversFilterGroups[g].name][n].field_type === "select" ||
                        serversFilters[serversFilterGroups[g].name][n].field_type === "list"
                        ) {
                            new_filter_data[serversFilters[serversFilterGroups[g].name][n].field_key] = ""
                    } else if (serversFilters[serversFilterGroups[g].name][n].field_type === "bool") {
                        new_filter_data[serversFilters[serversFilterGroups[g].name][n].field_key] = serversFilters[g.name][n].default_value
                    }
                }
            }
            setCurrentFilters(new_filter_data)
        }
    },[
        currentFilters
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        if (!fetchDataRequired && location.state) {
            const server_id = location.state ? location.state.server_id : null
            const server_index = servers.findIndex(s => s.id === server_id);
            if (server_index !== -1) {
                setTimeout(() => handleDetailCardOpen(server_index), 100)
            }
        }
    },[
        fetchDataRequired,
        location,
        servers,
        handleDetailCardOpen
    ])

    return (
        <Box>
            <ComputeServersStatsSubheaderV21 />
            <Box 
                sx={{
                    flexDirection: "row", 
                    justifyContent: 'space-between'
                }}>
                    <Stack 
                        direction="row" 
                        justifyContent="space-between" 
                        alignItems="center"
                        sx={{position: "relative", width: "100%"}}
                    >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        spacing={2}
                    >
                        <Fab
                            onClick={handleServerCreateDialogOpen}
                            color='primary'
                            size='small'
                            sx={{m: 1}}
                        >
                            <AddIcon />
                        </Fab>
                    </Stack>
                    <Box 
                        sx={{
                            position: "absolute", 
                            top: 0, 
                            right: 0,
                            zIndex: 1100
                        }}
                    >
                        <ComputeServerFilterV21 
                            filterGroups={serversFilterGroups}
                            filters={serversFilters}
                            projects={projectsList}
                            users={usersList}
                            flavors={flavorsData}
                            currentValues={currentFilters}
                            setCurrentValue={handleCurrentFiltersChange}
                            handleSearch={handleServerSearch}
                            handleFilterReset={handleServerFilterReset}
                            max_height={height * 0.7}
                        />
                    </Box>
                    </Stack>
            </Box>
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading &&
                <ComputeServersTableV21 
                    serversData={servers}
                    setServersData={setServers}
                    handleRowSelection={handleDetailCardOpen}
                    handleCellClick={{flavor: flavorDialogOpen}}
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    actionsTexts={defaultTexts}
                    actionsList={getServersActionsList()}
                    filterGroups={serversFilterGroups}
                    filters={serversFilters}
                    projects={projectsList}
                    users={usersList}
                    flavors={flavorsData}
                    currentValues={currentFilters}
                    setCurrentValue={handleCurrentFiltersChange}
                    handleServerFilterReset={handleServerFilterReset}
                    handleServerSearch={handleServerSearch}
                    sortHandler={handleServerSorting}
                />
            }
            {selectedRow !== null && <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <ComputeServerDetailV21
                    selectedRow={selectedRow}
                    selectedServer={selectedServer}
                    widthWeight={WIDTH_WEIGHT}
                    handleDrawerClose={handleDetailCardClose}
                    handleDataFetch={handleDataFetch}
                    handleServerStatusChange={handleServerStatusChange}
                    serverGroups={serverGroups}
                    handleNavigateToFlavor={handleNavigateToFlavor}
                    handleNavigateToVolume={handleNavigateToVolume}
                    handleNavigateToNetwork={handleNavigateToNetwork}
                    handleNavigateToSubnet={handleNavigateToSubnet}
                    handleNavigateToPort={handleNavigateToPort}
                    handleNavigateToSecurityGroup={handleNavigateToSecurityGroup}
                />         
            </CustomSideDrawer>}
            {selectedFlavor && <CustomDialog
                open={flavorDialogModalOpen}
                onClose={flavorDialogClose}
                dialogTitle={{
                    title: `${defaultTexts.flavorDetailsDialogTitle} ${selectedFlavor.name}`, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
            >
            <Stack direction="column" spacing={2}>
                <Stack 
                    direction="row" 
                    spacing={2} 
                    justifyContent="space-between" 
                    alignItems="center"
                >
                    <CustomText size="p">
                        {defaultTexts.idFormFieldLabel}:
                    </CustomText>
                    <CustomText size="p">
                        {selectedFlavor.id}
                    </CustomText>
                </Stack>
                <Divider />
                <Stack 
                    direction="row" 
                    spacing={2} 
                    justifyContent="space-between" 
                    alignItems="center"
                >
                    <CustomText size="p">
                        {defaultTexts.vcpusFormFieldLabel}:
                    </CustomText>
                    <CustomText size="p">
                        {selectedFlavor.vcpus}
                    </CustomText>
                </Stack>
                <Divider />
                <Stack 
                    direction="row" 
                    spacing={2} 
                    justifyContent="space-between" 
                    alignItems="center"
                >
                    <CustomText size="p">
                        {defaultTexts.ramTableHeaderLabel}:
                    </CustomText>
                    <CustomText size="p">
                        {selectedFlavor.ram}
                    </CustomText>
                </Stack>
                <Divider />
                <Stack 
                    direction="row" 
                    spacing={2} 
                    justifyContent="space-between" 
                    alignItems="center"
                >
                    <CustomText size="p">
                        {defaultTexts.diskTableHeaderLabel}:
                    </CustomText>
                    <CustomText size="p">
                        {selectedFlavor.disk}
                    </CustomText>
                </Stack>
                <Divider />
            </Stack>
            </CustomDialog>}
            <CustomDialog
                open={serverDeleteConfirmDialogOpen}
                onClose={handleServerDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.serverDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.serverDeleteConfirmText}: [${selectedServers.map(s => s.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onServerDelete, 
                    sx: {color: 'primary.main'}}]}
            />
            <ComputeServerLunchDialogV21 
                open={serverCreateDialogOpen} 
                handleClose={handleServerCreateDialogClose}
                handleDataFetch={handleDataFetch}
                handleServerStatusChange={handleServerStatusChange}
            />
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default ComputeServersWrapperV21;
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from '../../_common/CustomDrawer';
import IncorrectServiceVersionNote from '../../_common/IncorrectServiceVersionNote';
import NotPurchasedService from '../../_common/NotPurchasedService';
import { identityKeystonConstants } from '../../../config/openStackConstants';
import IdentityKeystoneContentV3 from './v3/IdentityKeystoneContentV3';
import { openStackServices } from '../../../config/openStackConstants';

const SERVICE_NAME = openStackServices.identityService
const SERVICE_TITLE = 'Identity (Keystone)'

const IdentityKeystoneWrapper = (props) => {
    const { section } = props
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }
    const purchasedServices = useSelector(state => 
        state.openstack.purchasedServices);

    const purchasedService = purchasedServices.filter(
        item => item.service === SERVICE_NAME)
    if (purchasedService.length === 0) {
        return (
            <CustomDrawer>
                <NotPurchasedService service={SERVICE_TITLE} />
            </CustomDrawer>
        )
    }
    if (!identityKeystonConstants.supported_api_versions.includes(
        purchasedService[0].config_params.api_version)) {
        return (
            <CustomDrawer>
                <IncorrectServiceVersionNote 
                    service_title={SERVICE_TITLE} 
                    service_version={purchasedService[0].config_params.api_version}
                />
            </CustomDrawer>
        )
    }

    if (purchasedService[0].config_params.api_version === "v3") {
        return (
            <CustomDrawer>
                <IdentityKeystoneContentV3 
                    serviceTitle={SERVICE_TITLE} 
                    serviceName={SERVICE_NAME}
                    section={section}
                />
            </CustomDrawer>
        )
    }
};

export default IdentityKeystoneWrapper;
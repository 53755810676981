import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../_common/WrapperBox';
import AddButton from '../../../../_common/AddButton';
import FormGroup from '@mui/material/FormGroup';
import Constants from '../../../../../config/constants';
import FilterButton from '../../../../_common/FilterButton';
import CustomDialog from '../../../../_common/CustomDialog';
import { 
    openStackServices, 
    blockStorageCinderConstants, 
    computeNovaConstants,
    imagesGlanceConstants
} from '../../../../../config/openStackConstants';
import { volumeDataForm } from 
'../../../../../_data/openstack/cinder/volumes/v3';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope, 
    computeNovaRequest, 
    openstackRequest 
} from '../../../../../_network/openstack_request';
import { volumesUrl as volumeUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import Grid from '@mui/material/Grid';

const SERVICE_NAME = openStackServices.volumeService
const COMPUTE_SERVICE_NAME = openStackServices.computeService
const IMAGE_SERVICE_NAME = openStackServices.imageService

const VolumesSubheaderV3 = (props) => {
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const { volumes, volumeTypes, volumeGroups } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successVolumeAdd, setSuccessVolumeAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});
    const [availabilityZones, setAvailabilityZones] = useState([])
    const [snapshots, setSnapshots] = useState([])
    const [backups, setBackups] = useState([])
    const [images, setImages] = useState([]);

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const volumesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
    const snapshotsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.snapshotsUrl)[0].url)
    const backupsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.backupsUrl)[0].url)
    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
    const availabilityZonesUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.availabilityZonesUrl)[0].url)
    const imageServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IMAGE_SERVICE_NAME)[0].config_params.service_domain)
    const imageServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IMAGE_SERVICE_NAME)[0].config_params.api_version)
    const imagesUrl = useSelector(
        state => state.imageGlance.imageGlanceApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === imagesGlanceConstants.imagesUrl)[0].url)


    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessVolumeAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddVolumeFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in volumeDataForm) {
            if (volumeDataForm[n].field_key === "source") {
                new_form_data[volumeDataForm[n].field_key] = volumeDataForm[n].items.filter(item => item.default)[0].value
            }else if (
                volumeDataForm[n].field_type === "string" || 
                volumeDataForm[n].field_type === "select"
                ) {
                new_form_data[volumeDataForm[n].field_key] = ""
            } else if (volumeDataForm[n].field_type === "bool") {
                new_form_data[volumeDataForm[n].field_key] = volumeDataForm[n].default_value ? 
                volumeDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (volumeDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (volumeDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }


    const getDataForm = () => {
        let form = [...volumeDataForm]
        if (formData.source === "blank") {
            form = form.filter(item => item.field_key !== "source_volid" &&
            item.field_key !== "imageRef" &&
            item.field_key !== "snapshot_id" &&
            item.field_key !== "backup_id"
            )
        } else if (formData.source === "volume") {
            form = form.filter(item => item.field_key !== "imageRef" &&
            item.field_key !== "snapshot_id" &&
            item.field_key !== "backup_id"
            )
        } else if (formData.source === "image") {
            form = form.filter(item => item.field_key !== "source_volid" &&
            item.field_key !== "snapshot_id" &&
            item.field_key !== "backup_id"
            )
        } else if (formData.source === "snapshot") {
            form = form.filter(item => item.field_key !== "source_volid" &&
            item.field_key !== "imageRef" &&
            item.field_key !== "backup_id"
            )
        } else if (formData.source === "backup") {
            form = form.filter(item => item.field_key !== "source_volid" &&
            item.field_key !== "imageRef" &&
            item.field_key !== "snapshot_id"
            )
        }
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_key === "availability_zone") {
                        form_field_options["items"] = [...availabilityZones]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    } else if (field.field_key === "source") {
                        form_field_options["items"] = [...field.items]
                        form_field_options["self_item_titles"] = false
                        form_field_options["empty"] = false
                        form_field_options["item_titles"] = defaultTexts
                    } else if (field.field_key === "volume_type") {
                        form_field_options["items"] = [...volumeTypes]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    } else if (field.field_key === "group_id") {
                        form_field_options["items"] = [...volumeGroups]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    } else if (field.field_key === "source_volid") {
                        const volume_list = volumes.filter(
                            item => item.status === "in-use" || 
                            item.status === "available").map(item => {
                            return {
                                keyword: `${item.name} (${item.id})`, 
                                value: item.id, 
                                default: false}
                        })
                        form_field_options["items"] = [...volume_list]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    } else if (field.field_key === "snapshot_id") {
                        form_field_options["items"] = [...snapshots]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    } else if (field.field_key === "backup_id") {
                        form_field_options["items"] = [...backups]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    } else if (field.field_key === "imageRef") {
                        form_field_options["items"] = [...images]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        const special_fields = ["imageRef", "snapshot_id", "backup_id", "source_volid"]
        const special_keys = ["image", "snapshot", "backup", "volume"]
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in volumeDataForm) {
            if (!special_fields.includes(volumeDataForm[n].field_key) && volumeDataForm[n].required && !formData[volumeDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[volumeDataForm[n].field_key] = {}
                updatedDataFormOptions[volumeDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[volumeDataForm[n].field_key]["errorText"] = defaultTexts[volumeDataForm[n].error_label]
            }
        }
        for (let k in special_keys) {
            if (formData.source === special_keys[k] && !formData[special_fields[k]]) {
                validation_faild = false
                updatedDataFormOptions[special_fields[k]] = {}
                updatedDataFormOptions[special_fields[k]]["error"] = true
                updatedDataFormOptions[special_fields[k]]["errorText"] = defaultTexts.requiredFormFieldError
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const addVolumeDataFormatting = () => {
        let new_data = {}
        if (formData.source === "volume") {
            new_data["source_volid"] = formData.source_volid
        } else if (formData.source === "image") {
            new_data["imageRef"] = formData.imageRef
        } else if (formData.source === "snapshot") {
            new_data["snapshot_id"] = formData.snapshot_id
        } else if (formData.source === "backup") {
            new_data["backup_id"] = formData.backup_id
        }

        const other_fields = Object.keys(formData).filter(key => ![
            "source", 
            "source_volid",
            "imageRef",
            "snapshot_id",
            "backup_id"
        ].includes(key))

        for (let k in other_fields) {
            if (formData[other_fields[k]].length > 0) {
                new_data[other_fields[k]] = formData[other_fields[k]]
            }
        }
        return new_data
    }

    const onAddVolume = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            const formatted_data = addVolumeDataFormatting()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}`
                const method = "POST"
                const volume_response = await volumeCinderRequest({
                    url: url, 
                    method: method, 
                    data: {volume: formatted_data},
                    token: project_token
                })
                if (volume_response.status_code === volumeUrlResponses.post_async.success_response.status_code) {
                    handleDataFetch()
                    handleAddVolumeFormReset()
                    setFormDataOptions({})
                } else {
                    const error_response = volumeUrlResponses.post_async.error_response.filter(
                        error_item => error_item.status_code === volume_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: volume_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = volumeUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: volume_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }
        return validateFormData
    }

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${computeServiceDomain}/${computeServiceVersion}/${availabilityZonesUrl}`
                const method = "GET"
                const zone_response = await computeNovaRequest({
                    url: url, 
                    method: method,
                    token: project_token
                })
                if (zone_response.status_code === 200) {
                    const availability_zones = zone_response.data.availabilityZoneInfo.map(item => {
                        return {keyword: item.zoneName, value: item.zoneName, default: false}
                    })
                    setAvailabilityZones(availability_zones)
                }
            }
        })();
    },[
        computeServiceDomain,
        computeServiceVersion,
        availabilityZonesUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
            (async () => {
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/detail?all_tenants=true`
                    const method = "GET"
                    const snapshot_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (snapshot_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        const snapshot_list = snapshot_response.data.snapshots.map(item => {
                            return {keyword: item.name, value: item.id, default: false}
                        })
                        setSnapshots(snapshot_list)
                    }
                }
            })();
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        snapshotsUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/detail?all_tenants=true`
                const method = "GET"
                const backup_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (backup_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    const backup_list = backup_response.data.backups.map(item => {
                        return {keyword: item.name, value: item.id, default: false}
                    })
                    setBackups(backup_list)
                }
            }
        })();
},[
    cinderServiceDomain, 
    cinderServiceVersion, 
    backupsUrl, 
    defaultAdminProject
]);

useEffect(() => {
    (async () => {
        const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}`
        const method = "GET"
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const images_response = await openstackRequest({url:url, method:method, token: project_token})
            if (images_response.status_code === 200) {
                const image_list = images_response.data.images.map(item => {
                    return {keyword: item.name, value: item.id, default: false}
                })
                setImages(image_list)
            }
        }
    })();
},[
    imageServiceDomain, 
    imageServiceVersion, 
    imagesUrl, 
    defaultAdminProject
]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successVolumeAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in volumeDataForm) {
                if (volumeDataForm[n].field_key === "source") {
                    new_form_data[volumeDataForm[n].field_key] = volumeDataForm[n].items.filter(item => item.default)[0].value
                }else if (
                    volumeDataForm[n].field_type === "string" || 
                    volumeDataForm[n].field_type === "select"
                    ) {
                    new_form_data[volumeDataForm[n].field_key] = ""
                } else if (volumeDataForm[n].field_type === "bool") {
                    new_form_data[volumeDataForm[n].field_key] = volumeDataForm[n].default_value ? 
                    volumeDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid container justifyContent='space-between' alignItems="center">
                <Grid item>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddVolume}
                formReset={handleAddVolumeFormReset}
                customTexts={{
                    title: defaultTexts.addVolumeDialogTitle
                }}
            />
            </Grid>
            <Grid item>
                <FilterButton 
                    choice_mode={Constants.simple_filter_type}
                    currentFilter={selectedFilter}
                    setCurrentFilter={setSelectedFilter}
                    filter_menu_titles={defaultTexts}
                    filter_menu={filterMenu}
                    currentFilterValue={selectedFilterValue}
                    setCurrentFilterValue={setSelectedFilterValue}
                    onFilterSubmit={handleFilteredSearch}
                    onFilterReset={handleFilterReset}
                />
            </Grid>
            </Grid>
            {successVolumeAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successVolumeAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successVolumeAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            
        </WrapperBox>
    )
};

export default VolumesSubheaderV3
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        computeNovaConstants
} from '../../../../../../config/openStackConstants';
import AvailabilityZonesTableV21 from './availabilityZonesTableV2.1';
import { 
    getXAuthTokenProjectScope, 
    computeNovaRequest
} from '../../../../../../_network/openstack_request';
import { infrastructureUrl as infrastructureUrlResponses } 
from '../../../../../../_api_responses/openstack/compute/infrastructure/v2.1';

const SERVICE_NAME = openStackServices.computeService

const AvailabilityZonesWrapperV21 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [availabilityZones, setAvailabilityZones] = useState([])

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const availabilityZonesUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.availabilityZonesUrl)[0].url)
    

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleAZsDataFormatting = (data) => {
        const formatted_data = data.map((item) => {
            let new_item = {...item}
            new_item.zoneState = item.zoneState.available
            new_item.hosts = Object.keys(item.hosts)

            return new_item
        })
        return formatted_data
    }

    useEffect(() => {
        (async () => {
            handleLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${computeServiceDomain}/${computeServiceVersion}/${availabilityZonesUrl}/detail`
                const method = "GET"
                const az_response = await computeNovaRequest({url:url, method:method, token: project_token})
                if (az_response.status_code === infrastructureUrlResponses.get.success_response.status_code) {
                    setAvailabilityZones(az_response.data.availabilityZoneInfo)
                }
            }
        })();
        setTimeout(()=>{handleLoading(false)},700)
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        availabilityZonesUrl, 
        defaultAdminProject
    ]);

    return (
        <Box>
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading &&
                <AvailabilityZonesTableV21
                    azData={handleAZsDataFormatting(availabilityZones)}
                    setAzData={setAvailabilityZones}
                />
            }
        </Box>
    )
};

export default AvailabilityZonesWrapperV21;
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ServiceContentHeader from '../../../_common/ServiceContentHeader';
import Box from '@mui/material/Box';
import NetworksWrapperV20 from './l2Networking/networks/networksWrapperV2.0';
import SegmentsWrapperV20 from './l2Networking/segments/segmentsWrapperV2.0';
import PortsWrapperV20 from './l2Networking/ports/portsWrapperV2.0';
import TrunksWrapperV20 from './l2Networking/trunks/trunksWrapperV2.0';
import NeutronLimitsWrapperV20 from './limits/neutronLimitsWrapperV2.0'
import SubnetsWrapperV20 from './l3Networking/subnets/subnetsWrapperV2.0';
import FloatingIPsWrapperV20 from './l3Networking/floating_ips/floatingIPsWrapperV2.0';
import VirtualIPsWrapperV20 from './l3Networking/virtual_ips/virtualIPsWrapperV2.0';
import RoutersWrapperV20 from './l3Networking/routers/routersWrapperV2.0';
import SecurityGroupsWrapperV20 from './security/security_groups/securityGroupsWrapperV2.0';
import AddressGroupsWrapperV20 from './security/address_groups/addressGroupsWrapperV2.0';
import DefaultRulesWrapperV20 from './security/default_rules/defaultRulesWrapperV2.0';
import FirewallGroupsWrapperV20 from './fwaas/fw_groups/firewallGroupsWrapperV2.0';
import FirewallPoliciesWrapperV20 from './fwaas/fw_policies/firewallPoliciesWrapperV2.0';
import FirewallRulesWrapperV20 from './fwaas/fw_rules/firewallRulesWrapperV2.0';
import IKEPoliciesWrapperV20 from './vpnaas/vpn_ike_policies/ikePoliciesWrapperV2.0';
import IPsecPoliciesWrapperV20 from './vpnaas/vpn_ipsec_policies/ipsecPoliciesWrapperV2.0';
import VPNServicesWrapperV20 from './vpnaas/vpn_services/vpnServicesWrapperV2.0';
import EndpointGroupsWrapperV20 from './vpnaas/vpn_endpoint_groups/vpnEndpointGroupsWrapperV2.0';
import SiteConnectionsWrapperV20 from './vpnaas/vpn_site_connections/siteConnectionsWrapperV2.0';
import { openStackServices, 
    networkNeutronConstants
} from '../../../../config/openStackConstants';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../_network/openstack_request';
import { networksUrl as networkUrlResponses } 
from '../../../../_api_responses/openstack/neutron/networks/v2.0';

const SERVICE_NAME = openStackServices.networkService

const NetworksNeutronContentV2_0 = (props) => {
    const { navigate, location, section } = props
    const { serviceTitle } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [segmentsSupported, setSegmentsSupported] = useState(false)
    const [trunksSupported, setTrunksSupported] = useState(false)
    const [virtualIPsSupported, setVirtualIPsSupported] = useState(false)

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const segmentsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.segmentsUrl)[0].url)
    const trunksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.trunksUrl)[0].url)
    const virtualIPsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.virtualIPsUrl)[0].url)

    const [l2NetworkingMenu, setL2NetworkingMenu] = useState([
        {keyword: "submenuNetworks", navigation: "/networks", is_active: true, component: NetworksWrapperV20},
        {keyword: "submenuPorts", navigation: "/ports", is_active: false, component: PortsWrapperV20},
        {keyword: "submenuSegments", navigation: "/segments", is_active: false, component: SegmentsWrapperV20},
        {keyword: "submenuTrunks", navigation: "/trunks", is_active: false, component: TrunksWrapperV20},
    ])

    const [l3NetworkingMenu, setL3NetworkingMenu] = useState([
        {keyword: "submenuSubnets", navigation: "/subnets", is_active: true, component: SubnetsWrapperV20},
        {keyword: "submenuFloatingIPs", navigation: "/floating-ips", is_active: false, component: FloatingIPsWrapperV20},
        {keyword: "submenuVirtualIPs", navigation: "/virtual-ips", is_active: false, component: VirtualIPsWrapperV20},
        {keyword: "submenuRouters", navigation: "/routers", is_active: false, component: RoutersWrapperV20},
    ])

    const [securityMenu, setSecurityMenu] = useState([
        {keyword: "submenuSecurityGroups", navigation: "/security-groups", is_active: true, component: SecurityGroupsWrapperV20},
        {keyword: "submenuAddressGroups", navigation: "/address-groups", is_active: false, component: AddressGroupsWrapperV20},
        {keyword: "submenuDefaultRules", navigation: "/default-rules", is_active: false, component: DefaultRulesWrapperV20},
    ])

    const [firewallsMenu, setFirewallsMenu] = useState([
        {keyword: "submenuFirewallGroups", navigation: "/firewall-groups", is_active: true, component: FirewallGroupsWrapperV20},
        {keyword: "submenuFirewallPolicies", navigation: "/firewall-policies", is_active: false, component: FirewallPoliciesWrapperV20},
        {keyword: "submenuFirewallRules", navigation: "/firewall-rules", is_active: false, component: FirewallRulesWrapperV20},
    ])

    const [vpnsMenu, setVPNsMenu] = useState([
        {keyword: "submenuIKEPolicies", navigation: "/ike-policies", is_active: true, component: IKEPoliciesWrapperV20},
        {keyword: "submenuIPSecPolicies", navigation: "/ipsec-policies", is_active: false, component: IPsecPoliciesWrapperV20},
        {keyword: "submenuVPNServices", navigation: "/vpn-services", is_active: false, component: VPNServicesWrapperV20},
        {keyword: "submenuEndpointGroups", navigation: "/endpoint-groups", is_active: false, component: EndpointGroupsWrapperV20},
        {keyword: "submenuSiteConnections", navigation: "/site-connections", is_active: false, component: SiteConnectionsWrapperV20},
    ])

    const [limitsMenu, setLimitsMenu] = useState([
        {keyword: "submenuNetworkLimits", navigation: "/limits", is_active: true, component: NeutronLimitsWrapperV20},
    ])

    const getCurrentTab = useCallback(() => {
        if (section === "/l2networking") {
            if (location.state && location.state.network_id) {
                const currentItem = l2NetworkingMenu.filter(item => item.navigation === "/networks")[0]
                return currentItem
            } else if (location.state && location.state.port_id) {
                const currentItem = l2NetworkingMenu.filter(item => item.navigation === "/ports")[0]
                return currentItem
            } else {
                const currentItem = l2NetworkingMenu.filter(item => item.is_active)[0]
                return currentItem
            }
        } else if (section === "/l3networking") {
            if (location.state && location.state.subnet_id) {
                const currentItem = l3NetworkingMenu.filter(item => item.navigation === "/subnets")[0]
                return currentItem
            } else {
                const currentItem = l3NetworkingMenu.filter(item => item.is_active)[0]
                return currentItem
            }
        } else if (section === "/security") {
            if (location.state && location.state.securityGroup_id) {
                const currentItem = securityMenu.filter(item => item.navigation === "/security-groups")[0]
                return currentItem
            } else {
                const currentItem = securityMenu.filter(item => item.is_active)[0]
                return currentItem
            }
        } else if (section === "/fwaas") {
            const currentItem = firewallsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/vpnaas") {
            const currentItem = vpnsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/limits") {
            const currentItem = limitsMenu.filter(item => item.is_active)[0]
            return currentItem
        }
    },[
        l2NetworkingMenu,
        l3NetworkingMenu,
        securityMenu,
        firewallsMenu,
        vpnsMenu,
        limitsMenu,
        section,
        location
    ])

    const [currentTab, setCurrentTab] = useState(getCurrentTab())

    const changeMenuActiveTab = (navigation) => {
        if (section === "/l2networking") {
            const updated_menu = l2NetworkingMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setL2NetworkingMenu(updated_menu)
        } else if (section === "/l3networking") {
            const updated_menu = l3NetworkingMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setL3NetworkingMenu(updated_menu)
        } else if (section === "/security") {
            const updated_menu = securityMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setSecurityMenu(updated_menu)
        } else if (section === "/fwaas") {
            const updated_menu = firewallsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setFirewallsMenu(updated_menu)
        } else if (section === "/vpnaas") {
            const updated_menu = vpnsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setVPNsMenu(updated_menu)
        } else if (section === "/limits") {
            const updated_menu = limitsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setLimitsMenu(updated_menu)
        }
    }

    const getCurrentServiceMenu = () => {
        if (section === "/l2networking") {
            let menu_list = [...l2NetworkingMenu]
            if (!segmentsSupported) {
                menu_list = menu_list.filter(item => item.navigation !== "/segments")
            }
            if (!trunksSupported) {
                menu_list = menu_list.filter(item => item.navigation !== "/trunks")
            }
            return menu_list
        } else if (section === "/l3networking") {
            let menu_list = [...l3NetworkingMenu]
            if (!virtualIPsSupported) {
                menu_list = menu_list.filter(item => item.navigation !== "/virtual-ips")
            }
            return menu_list
        } else if (section === "/security") {
            return securityMenu
        } else if (section === "/fwaas") {
            return firewallsMenu
        } else if (section === "/vpnaas") {
            return vpnsMenu
        } else if (section === "/limits") {
            return limitsMenu
        }
    }

    useEffect(() => {
        setCurrentTab(getCurrentTab())
    },[
        section, getCurrentTab
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${segmentsUrl}`
                const method = "GET"
                const segment_response = await openstackRequest({url:url, method:method, token: project_token})

                if (segment_response.status_code === networkUrlResponses.get.success_response.status_code) {
                    setSegmentsSupported(true)
                } else if (segment_response.status_code === 404) {
                    setSegmentsSupported(false)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        segmentsUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}`
                const method = "GET"
                const trunk_response = await openstackRequest({url:url, method:method, token: project_token})
                if (trunk_response.status_code === networkUrlResponses.get.success_response.status_code) {
                    setTrunksSupported(true)
                } else if (trunk_response.status_code === 404) {
                    setTrunksSupported(false)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        trunksUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${virtualIPsUrl}`
                const method = "GET"
                const vip_response = await openstackRequest({url:url, method:method, token: project_token})

                if (vip_response.status_code === networkUrlResponses.get.success_response.status_code) {
                    setVirtualIPsSupported(true)
                } else if (vip_response.status_code === 404) {
                    setVirtualIPsSupported(false)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        virtualIPsUrl, 
        defaultAdminProject
    ]);

    return (
        <Box>
            <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={getCurrentServiceMenu()}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component 
                navigate={navigate} 
                location={location}
                changeMenuActiveTab={changeMenuActiveTab}
            />
        </Box>
    )
};

export default NetworksNeutronContentV2_0;
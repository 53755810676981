import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import CustomDialog from '../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { openStackServices, 
        blockStorageCinderConstants
} from '../../../../../config/openStackConstants';
import QoSSpecsSubheaderV3 from './qosSpecsSubheaderV3';
import QoSSpecsTableV3 from './qosSpecsTableV3';
import { volumeCinderRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { volumesUrl as volumeUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import QoSSpecsDetailV3 from './qosSpecsDetailV3';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.volumeService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const QoSSpecsWrapperV3 = (props) => {
    const {navigate, location, changeMenuActiveTab} = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [volumeTypesData, setVolumeTypesData] = useState([])
    const [qosSpecsData, setQoSSpecsData] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailQoSCardOpen, setDetailQoSCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [fetchQoSDataRequired, setFetchQoSDataRequired] = useState(true);
    const [selectedQoSRow, setSelectedQoSRow] = useState(null);
    const [currentQoSAction, setCurrentQoSAction] = useState("");
    const [qosSpecsDeleteConfirmDialogOpen, setQoSSpecsDeleteConfirmDialogOpen] = useState(false);
    const [selectedQoSSpecs, setSelectedQoSSpecs] = useState([])
    const [qosSpecsSortParams, setQoSSpecsSortParams] = useState("")
    const [qosSpecsDisassociateAllConfirmDialogOpen, setQoSSpecsDisassociateAllConfirmDialogOpen] = useState(false)

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const volumeTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumeTypesUrl)[0].url)
    const qosSpecsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.qosSpecsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleQoSDataFetch = () => {
        setFetchQoSDataRequired(true)
    }

    const handleNavigateToVolumeTypes = (vt_id) => {
        handleQoSDetailCardClose()
        navigate("/block-storage/volume-types",{state: {volume_type_id: vt_id}})
    }

    const handleQoSDetailCardOpen = useCallback((index) => {
        setSelectedQoSRow(qosSpecsData[index])
        setTimeout(() => setDetailQoSCardOpen(true),100)
    },[qosSpecsData])

    const handleQoSDetailCardClose = () => {
        setDetailQoSCardOpen(false)
        setSelectedQoSRow(null)
        navigate(location.path,{})
        changeMenuActiveTab("/qos-specs")
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleQoSSpecsSorting = (field,direction) => {
        const sort_param = `&&sort=${field}:${direction}`
        setQoSSpecsSortParams(sort_param)
        handleQoSDataFetch()
    }

    const handleQoSSpecDelete = async (qos_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}/${qos_id}`
            const method = "DELETE"
            
            const vt_response = await volumeCinderRequest({
                url: url, 
                method: method,
                token: project_token
            })

            if (vt_response.status_code === volumeUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    }

    const handleQoSSpecDisassociateAll = async (qos_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}/${qos_id}/disassociate_all`
            const method = "GET"
            
            try {
                await volumeCinderRequest({
                    url: url, 
                    method: method,
                    token: project_token,
                    has_response: false
                })
                return true
            } catch {
                return false
            }
        }
    }

    const onQoSSpecDelete = async () => {
        handleQoSSpecsDeleteConfirmDialogClose()
        setIsLoading(true)
        let err  = []
        for (let q in selectedQoSSpecs) {
            const resp = await handleQoSSpecDelete(selectedQoSSpecs[q].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleQoSDetailCardClose()
        handleQoSDataFetch()
        setIsLoading(false)
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onQoSSpecDisassociateAll = async () => {
        handleQoSSpecsDisassociateAllConfirmDialogClose()
        setIsLoading(true)
        for (let q in selectedQoSSpecs) {
            await handleQoSSpecDisassociateAll(selectedQoSSpecs[q].id)
        }
        handleQoSDetailCardClose()
        handleDataFetch()
        handleQoSDataFetch()
        setIsLoading(false)
    }

    const onQoSSpecsDeleteConfirm = (qos_list) => {
        const selected_qos_list = qosSpecsData.filter(qs => 
            qos_list.includes(qs.id))
        setSelectedQoSSpecs([...selected_qos_list])
        setQoSSpecsDeleteConfirmDialogOpen(true)
    }

    const onQoSSpecsDisassociateAll = (qos_list) => {
        const selected_qos_list = qosSpecsData.filter(qs => 
            qos_list.includes(qs.id))
        setSelectedQoSSpecs([...selected_qos_list])
        setQoSSpecsDisassociateAllConfirmDialogOpen(true)
    }

    const handleQoSSpecsDeleteConfirmDialogClose = () => {
        setQoSSpecsDeleteConfirmDialogOpen(false)
    }

    const handleQoSSpecsDisassociateAllConfirmDialogClose = () => {
        setQoSSpecsDisassociateAllConfirmDialogOpen(false)
    }

    const getQoSSpecsActionsList = () => {
        let qos_specs_actions = []
        let new_action = {}
        new_action["value"] = "qos_specs_delete"
        new_action["action"] = onQoSSpecsDeleteConfirm
        new_action["keyword"] = "qosSpecsDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        qos_specs_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "qos_specs_disassociate_all"
        new_action["action"] = onQoSSpecsDisassociateAll
        new_action["keyword"] = "qosSpecsDisassociateAllActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        qos_specs_actions.push({...new_action})
        
        return qos_specs_actions
    }


    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let all_volume_types = []
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}`
                    const method = "GET"
        
                    const volume_types_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (volume_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        all_volume_types = [...all_volume_types, ...volume_types_response.data.volume_types]
                    }
                    url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}?is_public=false`
                    const response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        const private_vt = response.data.volume_types.filter(vt => !all_volume_types.map(i => i.id).includes(vt.id))
                        all_volume_types = [...private_vt, ...all_volume_types]
                    }
                    setVolumeTypesData([...all_volume_types])
                    if (selectedRow) {
                        let new_data = all_volume_types.filter(vt => vt.id === selectedRow.id)
                        if (new_data.length > 0) {
                            setSelectedRow(new_data[0])
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        volumeTypesUrl, 
        dataFetchingRequired,
        selectedRow,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (fetchQoSDataRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}${qosSpecsSortParams}`
                    const method = "GET"
        
                    const qos_specs_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (qos_specs_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        setQoSSpecsData(qos_specs_response.data.qos_specs)
                    }
                    if (selectedQoSRow) {
                        let new_data = qos_specs_response.data.qos_specs.filter(qs => qs.id === selectedQoSRow.id)
                        if (new_data.length > 0) {
                            setSelectedQoSRow(new_data[0])
                        }
                    }
                }
                
            })();
            setFetchQoSDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        qosSpecsUrl, 
        fetchQoSDataRequired,
        selectedQoSRow,
        defaultAdminProject,
        qosSpecsSortParams
    ]);

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const qos_id = location.state ? location.state.qos_id : null
                const qos_index = qosSpecsData.findIndex(v => v.id === qos_id);
                if (qos_index !== -1) {
                    setTimeout(() => handleQoSDetailCardOpen(qos_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        qosSpecsData,
        handleQoSDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <QoSSpecsSubheaderV3 
                handleFetchData={handleQoSDataFetch}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading &&
                <QoSSpecsTableV3 
                    qosSpecsData={qosSpecsData}
                    setQoSSpecsData={setQoSSpecsData}
                    handleRowSelection={handleQoSDetailCardOpen}
                    currentAction={currentQoSAction}
                    setCurrentAction={setCurrentQoSAction}
                    actionsTexts={defaultTexts}
                    actionsList={getQoSSpecsActionsList()}
                    sortHandler={handleQoSSpecsSorting}
                />
            }
            {selectedQoSRow !== null && 
                <CustomSideDrawer 
                    open={detailQoSCardOpen}
                    widthWeight={WIDTH_WEIGHT}
                    handleDrawerOpen={handleQoSDetailCardOpen}
                    handleDrawerClose={handleQoSDetailCardClose}
                > 
                    <QoSSpecsDetailV3 
                        selectedRow={selectedQoSRow}
                        widthWeight={WIDTH_WEIGHT}
                        handleNavigateToVolumeTypes={handleNavigateToVolumeTypes}
                        handleQoSFetchData={handleQoSDataFetch}
                        handleFetchData={handleDataFetch}
                        handleQoSSpecsDelete={onQoSSpecsDeleteConfirm}
                        handleDisassociateAll={handleQoSSpecDisassociateAll}
                        volumeTypes={volumeTypesData}
                    />
                </CustomSideDrawer>
            }
            <CustomDialog
                open={qosSpecsDeleteConfirmDialogOpen}
                onClose={handleQoSSpecsDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.qosSpecsDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.qosSpecsDeleteConfirmText}: [${selectedQoSSpecs.map(q => q.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onQoSSpecDelete, 
                    sx: {color: 'primary.main'}}]}
            />
            <CustomDialog
                open={qosSpecsDisassociateAllConfirmDialogOpen}
                onClose={handleQoSSpecsDisassociateAllConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.qosSpecsDisassociateAllConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.qosSpecsDisassociateAllConfirmText}: [${selectedQoSSpecs.map(q => q.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick:  onQoSSpecDisassociateAll, 
                    sx: {color: 'primary.main'}}]}
            />
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default QoSSpecsWrapperV3;
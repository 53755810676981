import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import IKEPoliciesSubheaderV20 from './ikePoliciesSubheaderV2.0';
import IKEPoliciesTableV20 from './ikePoliciesTableV2.0';
import { vpnIKEPoliciesFilterMenu, vpnIKEPolicyDataForm } 
from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const IKEPoliciesWrapperV20 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [ikePoliciesData, setIkePoliciesData] = useState([])
    const [ikePolicies, setIkePolicies] = useState([])
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [ikePolicyDeleteConfirmDialogOpen, setIkePolicyDeleteConfirmDialogOpen] = useState(false);
    const [selectedIkePolicies, setSelectedIkePolicies] = useState([])
    
    const [ikePolicyFilterQueryParams, setIkePolicyFilterQueryParams] = useState("")
    const [selectedIkePolicyFilter, setSelectedIkePolicyFilter] = useState(vpnIKEPoliciesFilterMenu[0].value)
    const [selectedIkePolicyFilterValue, setSelectedIkePolicyFilterValue] = useState("")
    const [ikePoliciesFilter, setIkePoliciesFilter] = useState([...vpnIKEPoliciesFilterMenu])

    const [projects, setProjects] = useState([])
    const [updateDataOptions, setUpdateDataOptions] = useState({});
    const [updateFormData, setUpdateFormData] = useState({});
    const [ikePolicyUpdateDialogOpen, setIKEPolicyUpdateDialogOpen] = useState(false)
    const [selectedFieldKey, setSelectedFieldKey] = useState("")
    const [selectedFieldTitleText, setSelectedFieldTitleText] = useState("")

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const vpnIKEPoliciesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.vpnIKEPoliciesUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleIkePolicyFilteredSearch = () => {
        if (selectedIkePolicyFilter && selectedIkePolicyFilterValue) {
            setIkePolicyFilterQueryParams(`${selectedIkePolicyFilter}=${selectedIkePolicyFilterValue}`)
        } else {
            setIkePolicyFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleIkePoliciesDataFormatting = useCallback(() => {
        const formatted_data = ikePoliciesData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            new_item.lifetime = `${item.lifetime.value} ${item.lifetime.units}`
            return new_item
        })
        setIkePolicies(formatted_data)
    },[
        ikePoliciesData,
        projects
    ])

    const handleFormDataChange = (event,field_key) => {
        setUpdateDataOptions({})
        let new_form_data = {...updateFormData}
        if (vpnIKEPolicyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (vpnIKEPolicyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const getDataForm = () => {
        let form = vpnIKEPolicyDataForm.filter(i => i.field_key === selectedFieldKey)
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...updateDataOptions[field.field_key]}
                    form_field_options = {...form_field_options, ...field}
                    delete form_field_options["label"]
                    form_field_options["item_titles"] = defaultTexts
                    return (
                        getFormFieldComponent(
                            field,
                            updateFormData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleUpdateDataValidation = (field_key, data) => {
        if (data[field_key].length === 0) {
            let options = {}
            options[field_key] = {}
            options[field_key]["error"] = true
            options[field_key]["errorText"] = defaultTexts.requiredFormFieldError
            setUpdateDataOptions(options)
            return false
        } else {
            return true
        }
    }

    const handleIKEPolicyUpdate = async (eg_id,field_key,data) => {
        const data_is_valid = handleUpdateDataValidation(field_key,data)
        let update_data = {}
        update_data[field_key] = data[field_key]
        if (data_is_valid) {
            handleIKEPolicyUpdateDialogClose()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "PUT"
                const vpnGroup_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIKEPoliciesUrl}/${eg_id}`, 
                    method: method, 
                    data: {ikepolicy: update_data},
                    token: project_token
                })
                if (vpnGroup_response.status_code === vpnUrlResponses.put.success_response.status_code) {
                    return null
                } else {
                    return vpnGroup_response.error
                }
            }
        } 
    }

    const onIKEPolicyUpdate = async () => {
        let err = []
        for (let n in selectedIkePolicies) {
            const resp = await handleIKEPolicyUpdate(
                selectedIkePolicies[n].id,
                selectedFieldKey,
                updateFormData)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        setUpdateDataOptions({})
        setUpdateFormData({})
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onIKEPolicyUpdateNameDialogOpen = useCallback((n_list) => {
        const selected_n_list = ikePoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIkePolicies([...selected_n_list])
        setSelectedFieldKey("name")
        setSelectedFieldTitleText(defaultTexts.updateIkePolicyNameTitle)
        setIKEPolicyUpdateDialogOpen(true)
    },[ikePoliciesData, defaultTexts])

    const onIKEPolicyUpdateDescriptionDialogOpen = useCallback((n_list) => {
        const selected_n_list = ikePoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIkePolicies([...selected_n_list])
        setSelectedFieldKey("description")
        setSelectedFieldTitleText(defaultTexts.updateIkePolicyDescriptionTitle)
        setIKEPolicyUpdateDialogOpen(true)
    },[ikePoliciesData, defaultTexts])

    const onIKEPolicyUpdateAuthAlgDialogOpen  = useCallback((n_list) => {
        const selected_n_list = ikePoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIkePolicies([...selected_n_list])
        setSelectedFieldKey("auth_algorithm")
        setSelectedFieldTitleText(defaultTexts.updateIkePolicyAuthAlgTitle)
        setIKEPolicyUpdateDialogOpen(true)
    },[ikePoliciesData, defaultTexts])

    const onIKEPolicyUpdateEncrAlgDialogOpen  = useCallback((n_list) => {
        const selected_n_list = ikePoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIkePolicies([...selected_n_list])
        setSelectedFieldKey("encryption_algorithm")
        setSelectedFieldTitleText(defaultTexts.updateIkePolicyEncrAlgTitle)
        setIKEPolicyUpdateDialogOpen(true)
    },[ikePoliciesData, defaultTexts])

    const onIKEPolicyUpdatePFSGroupDialogOpen  = useCallback((n_list) => {
        const selected_n_list = ikePoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIkePolicies([...selected_n_list])
        setSelectedFieldKey("pfs")
        setSelectedFieldTitleText(defaultTexts.updateIkePolicyPFSGroupTitle)
        setIKEPolicyUpdateDialogOpen(true)
    },[ikePoliciesData, defaultTexts])

    const onIKEPolicyUpdateIKEVersionDialogOpen  = useCallback((n_list) => {
        const selected_n_list = ikePoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIkePolicies([...selected_n_list])
        setSelectedFieldKey("ike_version")
        setSelectedFieldTitleText(defaultTexts.updateIkePolicyIKEVersionTitle)
        setIKEPolicyUpdateDialogOpen(true)
    },[ikePoliciesData, defaultTexts])

    const handleIKEPolicyUpdateDialogClose = () => {
        setIKEPolicyUpdateDialogOpen(false)
    }

    const handleIkePolicyFilterReset = () => {
        setSelectedIkePolicyFilter(vpnIKEPoliciesFilterMenu[0].value)
        setSelectedIkePolicyFilterValue("")
        setIkePolicyFilterQueryParams("")
        handleDataFetch()
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleIkePolicyDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIKEPoliciesUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === vpnUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onIkePolicyDelete = async () => {
        handleIkePolicyDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedIkePolicies) {
            const resp = await handleIkePolicyDelete(selectedIkePolicies[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onIkePolicyDeleteConfirm = useCallback((n_list) => {
        const selected_n_list = ikePoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIkePolicies([...selected_n_list])
        setIkePolicyDeleteConfirmDialogOpen(true)
    },[ikePoliciesData])

    const handleIkePolicyDeleteConfirmDialogClose = () => {
        setIkePolicyDeleteConfirmDialogOpen(false)
    }

    const getIkePoliciesActionsList = useCallback(() => {
        let ikePolicy_actions = []
        let new_action = {}
        new_action["value"] = "ike_policy_update_name"
        new_action["action"] = onIKEPolicyUpdateNameDialogOpen
        new_action["keyword"] = "ikePolicyUpdateNameActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ikePolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ike_policy_update_description"
        new_action["action"] = onIKEPolicyUpdateDescriptionDialogOpen
        new_action["keyword"] = "ikePolicyUpdateDescriptionActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ikePolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ike_policy_update_auth_alg"
        new_action["action"] = onIKEPolicyUpdateAuthAlgDialogOpen
        new_action["keyword"] = "ikePolicyUpdateAuthAlgActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ikePolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ike_policy_update_encr_alg"
        new_action["action"] = onIKEPolicyUpdateEncrAlgDialogOpen
        new_action["keyword"] = "ikePolicyUpdateEncrAlgActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ikePolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ike_policy_update_pfs"
        new_action["action"] = onIKEPolicyUpdatePFSGroupDialogOpen
        new_action["keyword"] = "ikePolicyUpdatePFSGroupActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ikePolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ike_policy_update_version"
        new_action["action"] = onIKEPolicyUpdateIKEVersionDialogOpen
        new_action["keyword"] = "ikePolicyUpdateIKEVersionActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ikePolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ikePolicy_delete"
        new_action["action"] = onIkePolicyDeleteConfirm
        new_action["keyword"] = "ikePolicyDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ikePolicy_actions.push({...new_action})
        
        return ikePolicy_actions
    },[
        onIkePolicyDeleteConfirm,
        onIKEPolicyUpdateNameDialogOpen,
        onIKEPolicyUpdateDescriptionDialogOpen,
        onIKEPolicyUpdateAuthAlgDialogOpen,
        onIKEPolicyUpdateEncrAlgDialogOpen,
        onIKEPolicyUpdatePFSGroupDialogOpen,
        onIKEPolicyUpdateIKEVersionDialogOpen
    ])

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIKEPoliciesUrl}?${ikePolicyFilterQueryParams}`
                    const method = "GET"
                    const ikePolicy_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (ikePolicy_response.status_code === vpnUrlResponses.get.success_response.status_code) {
                        setIkePoliciesData(ikePolicy_response.data.ikepolicies)
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        vpnIKEPoliciesUrl, 
        ikePolicyFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (ikePoliciesData.length > 0) {
            handleIkePoliciesDataFormatting()
        }
    },[
        ikePoliciesData,
        handleIkePoliciesDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }
        let ikePolicy_filter_menu = vpnIKEPoliciesFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            return new_item
        })

        setIkePoliciesFilter(ikePolicy_filter_menu)
    },[
        ikePoliciesData,
        projects
    ])

    useEffect(() => {
        if (Object.keys(updateFormData).length === 0) {
            let new_form_data = {}
            for (const n in vpnIKEPolicyDataForm) {
                if (
                    vpnIKEPolicyDataForm[n].field_type === "string" || 
                    vpnIKEPolicyDataForm[n].field_type === "select"
                    ) {
                    new_form_data[vpnIKEPolicyDataForm[n].field_key] = vpnIKEPolicyDataForm[n].default_value ? 
                    vpnIKEPolicyDataForm[n].default_value : 
                    ""
                } else if (vpnIKEPolicyDataForm[n].field_type === "integer") {
                    new_form_data[vpnIKEPolicyDataForm[n].field_key] = 3600
                } else if (vpnIKEPolicyDataForm[n].field_type === "bool") {
                    new_form_data[vpnIKEPolicyDataForm[n].field_key] = vpnIKEPolicyDataForm[n].default_value ? 
                    vpnIKEPolicyDataForm[n].default_value : 
                    false
                }
            }
            setUpdateFormData(new_form_data)
        }
    },[
        updateFormData
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <IKEPoliciesSubheaderV20 
                selectedFilter={selectedIkePolicyFilter} 
                setSelectedFilter={setSelectedIkePolicyFilter}
                selectedFilterValue={selectedIkePolicyFilterValue}
                setSelectedFilterValue={setSelectedIkePolicyFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={ikePoliciesFilter}
                handleFilteredSearch={handleIkePolicyFilteredSearch}
                handleFilterReset={handleIkePolicyFilterReset}
                projects={projects}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <IKEPoliciesTableV20 
            ikePoliciesData={ikePolicies}
                setIkePoliciesData={setIkePolicies}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getIkePoliciesActionsList()}
                projects={projects}
            />
        }
        <CustomDialog
            open={ikePolicyDeleteConfirmDialogOpen}
            onClose={handleIkePolicyDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.ikePolicyDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.ikePolicyDeleteConfirmText}`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onIkePolicyDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={ikePolicyUpdateDialogOpen}
            onClose={handleIKEPolicyUpdateDialogClose}
            dialogTitle={{
                title: selectedFieldTitleText, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onIKEPolicyUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm()}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default IKEPoliciesWrapperV20;
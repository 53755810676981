import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../../_common/WrapperBox';
import AddButton from '../../../../../_common/AddButton';
import FormGroup from '@mui/material/FormGroup';
import Constants from '../../../../../../config/constants';
import FilterButton from '../../../../../_common/FilterButton';
import CustomDialog from '../../../../../_common/CustomDialog';
import { 
    openStackServices, 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import { portDataForm } from 
'../../../../../../_data/openstack/neutron/ports/v2.0';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import { 
    getXAuthTokenProjectScope,
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { portsUrl as portUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/ports/v2.0';
import Grid from '@mui/material/Grid';
const ip_checker = require('ip-address');


const SERVICE_NAME = openStackServices.networkService

const PortsSubheaderV20 = (props) => {
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu } = props
    const { networks, subnets, secGroups } = props 
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successPortAdd, setSuccessPortAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const portsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.portsUrl)[0].url)

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessPortAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddPortFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in portDataForm) {
            if (portDataForm[n].field_type === "select" &&
                portDataForm[n].multiple
                ) {
                    new_form_data[portDataForm[n].field_key] = []
            } else if (
                portDataForm[n].field_type === "string" || 
                portDataForm[n].field_type === "select"
                ) {
                new_form_data[portDataForm[n].field_key] = ""
            } else if (portDataForm[n].field_type === "bool") {
                new_form_data[portDataForm[n].field_key] = portDataForm[n].default_value ? 
                portDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (portDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (portDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select")  {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    const getDataForm = () => {
        let form = [...portDataForm]
        if (!formData.port_security_enabled) {
            form = portDataForm.filter(f => f.field_key !== "security_groups")
        }
        if (!formData.ip_address_or_subnet) {
            form = portDataForm.filter(f => f.field_key !== "ip_address" && f.field_key !== "subnet_id")
        }
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_key === "network_id") {
                        const networks_filter = networks.map(n => {
                            return {keyword: n.name, value: n.id, default: false}
                        })
                        form_field_options["items"] = [...networks_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    } else if (field.field_key === "subnet_id") {
                        const subnets_list = subnets.filter(s => s.network_id === formData.network_id)
                        const subnets_filter = subnets_list.map(s => {
                            return {keyword: `${s.name} ${s.cidr}`, value: s.id, default: false}
                        })
                        form_field_options["items"] = [...subnets_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    } else if (field.field_key === "security_groups") {
                        const project = networks.filter(n => n.id === formData.network_id)
                        let sec_groups_list = []
                        if (project.length > 0) {
                            sec_groups_list = secGroups.filter(s => s.project_id === project[0].project_id)
                        }
                        const secgroups_filter = sec_groups_list.map(s => {
                            return {keyword: s.name, value: s.id, default: false}
                        })
                        form_field_options["items"] = [...secgroups_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                        form_field_options["multiple"] = true
                    } else if (field.field_key === "binding:vnic_type") {
                        form_field_options["items"] = [...field.items]
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["empty"] = true
                        form_field_options["item_titles"] = defaultTexts
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in portDataForm) {
            if (portDataForm[n].required && !formData[portDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[portDataForm[n].field_key] = {}
                updatedDataFormOptions[portDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[portDataForm[n].field_key]["errorText"] = defaultTexts[portDataForm[n].error_label]
            }
        }

        if (formData.ip_address.length > 0) {
            let parsev4 = null
            let parsev6 = null
            try {
                parsev4 = new ip_checker.Address4(formData.ip_address)
            } catch {
                parsev4 = null
            }
            try {
                parsev6 = new ip_checker.Address6(formData.ip_address)
            } catch {
                parsev6 = null
            }
            if (!parsev4 && !parsev6) {
                validation_faild = false
                updatedDataFormOptions["ip_address"] = {}
                updatedDataFormOptions["ip_address"]["error"] = true
                updatedDataFormOptions["ip_address"]["errorText"] = defaultTexts.invalidIPAddressErrorText
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const formatData = () => {
        let new_data = {...formData}
        const fields_to_delete = portDataForm.filter(f => f.non_openstack_field)
        const keys_to_delete = fields_to_delete.map(f => f.field_key)
        for (let k in keys_to_delete) {
            delete new_data[keys_to_delete[k]]
        }
        if (formData.ip_address_or_subnet) {
            new_data["fixed_ips"] = []
            const new_address = {}
            if (formData.subnet_id.length > 0) {
                new_address["subnet_id"] = formData.subnet_id
            }
            if (formData.ip_address.length > 0) {
                new_address["ip_address"] = formData.ip_address
            }
            if (Object.keys(new_address).length > 0) {
                new_data["fixed_ips"].push(new_address)
            }
        }
        return new_data
    }

    const onAddPort = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            let data = formatData()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}`
                const method = "POST"
                const port_response = await openstackRequest({
                    url: url, 
                    method: method, 
                    data: {port: data},
                    token: project_token
                })
                if (port_response.status_code === portUrlResponses.post.success_response.status_code) {
                    handleDataFetch()
                    handleAddPortFormReset()
                    setFormDataOptions({})
                } else {
                    const error_response = portUrlResponses.post_async.error_response.filter(
                        error_item => error_item.status_code === port_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: port_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = portUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: port_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }
        return validateFormData
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successPortAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in portDataForm) {
                if (
                    portDataForm[n].field_type === "select" &&
                    portDataForm[n].multiple
                    ) {
                    new_form_data[portDataForm[n].field_key] = []
                } else if (
                    portDataForm[n].field_type === "string" || 
                    portDataForm[n].field_type === "select"
                    ) {
                    new_form_data[portDataForm[n].field_key] = ""
                } else if (portDataForm[n].field_type === "bool") {
                    new_form_data[portDataForm[n].field_key] = portDataForm[n].default_value ? 
                    portDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid 
                container 
                justifyContent='space-between' 
                alignItems="center">
                <Grid item>
                    <AddButton 
                        getDataForm={getDataForm}               
                        onSubmit={onAddPort}
                        formReset={handleAddPortFormReset}
                        customTexts={{
                            title: defaultTexts.addPortDialogTitle
                        }}
                    />
                </Grid>
                <Grid item>
                    <FilterButton 
                        choice_mode={Constants.simple_filter_type}
                        currentFilter={selectedFilter}
                        setCurrentFilter={setSelectedFilter}
                        filter_menu_titles={defaultTexts}
                        filter_menu={filterMenu}
                        currentFilterValue={selectedFilterValue}
                        setCurrentFilterValue={setSelectedFilterValue}
                        onFilterSubmit={handleFilteredSearch}
                        onFilterReset={handleFilterReset}
                    />
                </Grid>
            </Grid>
            {successPortAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successPortAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successPortAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default PortsSubheaderV20
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ServiceContentHeader from '../../../_common/ServiceContentHeader';
import Box from '@mui/material/Box';
import ComputeFlavorsWrapperV2_1 from './flavors/computeFlavorsWrapperV2_1';
import ComputeKeypairsWrapperV2_1 from './keypairs/computeKeypairsWrapperV2_1';
import ComputeLimitsWrapperV21 from './limits/computeLimitsWrapperV2_1';
import ComputeServersWrapperV21 from './servers/computeServersWrapperV2_1';
import AggregatesWrapperV21 from './infrastructure/aggregates/aggregatesWrapperV2.1';
import AvailabilityZonesWrapperV21 from './infrastructure/availability_zones/availabilityZonesWrapperV2.1'
import HypervisorsWrapperV21 from './infrastructure/hypervisors/hypervisorsWrapperV2.1'
import ComputeServerGroupsWrapperV21 from './servers/computeServerGroupsWrapperV2_1'

const ComputeNovaContentV2_1 = (props) => {
    const { navigate, location, section } = props
    const { serviceTitle } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const [serversMenu, setServersMenu] = useState([
        {keyword: "submenuServers", navigation: "/servers", is_active: true, component: ComputeServersWrapperV21},
        {keyword: "submenuServerGroups", navigation: "/server-groups", is_active: false, component: ComputeServerGroupsWrapperV21}
    ])

    const [flavorsMenu, setFlavorsMenu] = useState([
        {keyword: "submenuFlavors", navigation: "/flavors", is_active: true, component: ComputeFlavorsWrapperV2_1},
    ])

    const [keypairsMenu, setKeypairsMenu] = useState([
        {keyword: "submenuKeypairs", navigation: "/keypairs", is_active: true, component: ComputeKeypairsWrapperV2_1},
    ])

    const [infrastructureMenu, setInfrastructureMenu] = useState([
        {keyword: "submenuHostAggregates", navigation: "/aggregates", is_active: true, component: AggregatesWrapperV21},
        {keyword: "submenuAvailabilityZones", navigation: "/availability_zones", is_active: false, component: AvailabilityZonesWrapperV21},
        {keyword: "submenuHypervisors", navigation: "/hypervisors", is_active: false, component: HypervisorsWrapperV21}
    ])

    const [limitsMenu, setLimitsMenu] = useState([
        {keyword: "submenuLimits", navigation: "/limits", is_active: true, component: ComputeLimitsWrapperV21},
    ])

    const getCurrentTab = useCallback(() => {
        if (section === "/servers") {
            const currentItem = serversMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/flavors") {
            const currentItem = flavorsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/keypairs") {
            const currentItem = keypairsMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/infrastructure") {
            const currentItem = infrastructureMenu.filter(item => item.is_active)[0]
            return currentItem
        } else if (section === "/limits") {
            const currentItem = limitsMenu.filter(item => item.is_active)[0]
            return currentItem
        }
    },[
        serversMenu,
        flavorsMenu,
        keypairsMenu,
        infrastructureMenu,
        limitsMenu,
        section
    ])

    const [currentTab, setCurrentTab] = useState(getCurrentTab())

    const changeMenuActiveTab = (navigation) => {
        if (section === "/servers") {
            const updated_menu = serversMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setServersMenu(updated_menu)
        } else if (section === "/flavors") {
            const updated_menu = flavorsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setFlavorsMenu(updated_menu)
        } else if (section === "/keypairs") {
            const updated_menu = keypairsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setKeypairsMenu(updated_menu)
        } else if (section === "/infrastructure") {
            const updated_menu = infrastructureMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setInfrastructureMenu(updated_menu)
        } else if (section === "/limits") {
            const updated_menu = limitsMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                    setCurrentTab(item)
                } else {
                    item.is_active = false
                }
                return item
            })
            setLimitsMenu(updated_menu)
        }
        
    }

    const getCurrentServiceMenu = () => {
        if (section === "/servers") {
            return serversMenu
        } else if (section === "/flavors") {
            return flavorsMenu
        } else if (section === "/keypairs") {
            return keypairsMenu
        } else if (section === "/infrastructure") {
            return infrastructureMenu
        } else if (section === "/limits") {
            return limitsMenu
        }
    }

    useEffect(() => {
        setCurrentTab(getCurrentTab())
    },[
        section, getCurrentTab
    ]);

    return (
        <Box>
            <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={getCurrentServiceMenu()}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component
                navigate={navigate}
                location={location}
            />
        </Box>
    )
};

export default ComputeNovaContentV2_1;
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormGroup } from '@mui/material';
import AddButton from '../../../../_common/AddButton';
import { projectsUrl as projectsUrlResponses} from
'../../../../../_api_responses/openstack/identity/projects/v3';
import { openstackRequest, computeNovaRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { 
    computeNovaConstants, 
    identityKeystonConstants
} from 
'../../../../../config/openStackConstants';
import { Stack } from '@mui/material';
import CustomDialog from '../../../../_common/CustomDialog';
import Box from '@mui/material/Box';
import { 
    serversFilterGroups, 
    serversFilters,
    serverGroupDataForm,
    serverGroupRulesDataForm
} from '../../../../../_data/openstack/compute/servers/v2.1';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import ComputeServerGroupsTableV21 from './computeServerGroupsTableV2_1';

const SERVICE_NAME = openStackServices.computeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService
const POLICY_RULES_MIN_API_V = "2.64"
const POLICY_RULES_REQUIRED_POLICY = "anti-affinity"

const ComputeServerGroupsWrapperV21 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [serversData, setServersData] = useState([])
    const [projectsList, setProjectsList] = useState([])
    const [usersList, setUsersList] = useState([])
    const [fetchGroupsDataRequired, setFetchGroupsDataRequired] = useState(true);
    const [currentGroupAction, setCurrentGroupAction] = useState("");
    const [serverGroupDeleteConfirmDialogOpen, setServerGroupDeleteConfirmDialogOpen] = useState(false);
    const [selectedServerGroups, setSelectedServerGroups] = useState([])
    const [currentFilters, setCurrentFilters] = useState({})
    const [serverGroups, setServerGroups] = useState([])
    const [formattedServerGroups, setFormattedServerGroups] = useState([])
    const [serverGroupFormOptions, setServerGroupFormOptions] = useState({})
    const [serverGroupForm, setServerGroupForm] = useState({})
    const [serverGroupRuleForm, setServerGroupRuleForm] = useState({})
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const serverGroupsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serverGroupsUrl)[0].url)
    const serversUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serversUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    const usersUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.usersUrl)[0].url)
    const computeNovaMaxApiVersion = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            versions => versions.api_version === "v2.1")[0].max_api_version)


    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

            
    const handleServerGroupDelete = async (group) => {
        const url = `${computeServiceDomain}/${computeServiceVersion}/${serverGroupsUrl}/${group.id}`
        const method = "DELETE"
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const group_response = await computeNovaRequest({
                url:url, 
                method:method, 
                token: project_token
            })
            if (group_response.status_code === 204) {
                return true
            } else {
                return false
            }
        }
    }

    const onServerGroupDelete = async () => {
        handleServerGroupDeleteConfirmDialogClose()
        setIsLoading(true)
        for (let s in selectedServerGroups) {
            await handleServerGroupDelete(selectedServerGroups[s])
        }
        setIsLoading(false)
        handleGroupsDataFetch()
    }

    const onServerGroupDeleteConfirm = (server_groups_list) => {
        const selected_group_list = serverGroups.filter(srv => 
            server_groups_list.includes(srv.id))
        setSelectedServerGroups([...selected_group_list])
        setServerGroupDeleteConfirmDialogOpen(true)
    }

    const handleServerGroupDeleteConfirmDialogClose = () => {
        setServerGroupDeleteConfirmDialogOpen(false)
    }

    const getServerGroupsActionsList = () => {
        let server_group_actions = []
        let new_action = {}
        new_action["value"] = "server_group_delete"
        new_action["action"] = onServerGroupDeleteConfirm
        new_action["keyword"] = "serverGroupDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        server_group_actions.push({...new_action})
        
        return server_group_actions
    }

    const handleGroupsDataFetch = () => {
        setFetchGroupsDataRequired(true)
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleServerGroupFormChange = (event,field_key) => {
        let new_form_data = {...serverGroupForm}
        if (serverGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (serverGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setServerGroupForm(new_form_data)
    }

    const handleServerGroupRuleFormChange = (event,field_key) => {
        let new_form_data = {...serverGroupRuleForm}
        if (serverGroupRulesDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (serverGroupRulesDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setServerGroupRuleForm(new_form_data)
    }

    const getServerGroupDataForm = () => {
        return (
            <FormGroup>
                {serverGroupDataForm.map(field => {
                    let form_field_options = {...serverGroupFormOptions[field.field_key]}
                    form_field_options["withHelp"] = field.with_help
                    form_field_options["helpText"] = field.help_text ? 
                    defaultTexts[field.help_text] : ""
                    form_field_options["helpTextWidth"] = 350
                    form_field_options["helpTextHeight"] = 350
                    form_field_options["zIndex"] = 1300
                    if (!field.with_help) {
                        form_field_options["sx"] = {my: 1, width: {xs: "83%", sm: "91%"}}
                    }
                    if (field.field_type === "select" && field.items) {
                        form_field_options["items"] = [...field.items]
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_valuse"] = field.default_value
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            serverGroupForm,
                            handleServerGroupFormChange,
                            defaultTexts[field.label],
                            {
                                ...form_field_options
                            }
                        )
                    )
                })}
                {parseFloat(computeNovaMaxApiVersion) >= parseFloat(POLICY_RULES_MIN_API_V) && 
                    serverGroupForm.policy === POLICY_RULES_REQUIRED_POLICY &&
                    serverGroupRulesDataForm.map(field => {
                        return (
                            getFormFieldComponent(
                                field,
                                serverGroupRuleForm,
                                handleServerGroupRuleFormChange,
                                defaultTexts[field.label],
                                {
                                    sx: {my: 1, width: {xs: "83%", sm: "91%"}},
                                    default_value: field.default_value
                                }
                            )
                        )
                    })
                }
            </FormGroup>
        )
    }

    const handleServerGroupFormValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...serverGroupFormOptions}
        for (let n in serverGroupDataForm) {
            if (serverGroupDataForm[n].required && !serverGroupForm[serverGroupDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[serverGroupDataForm[n].field_key] = {}
                updatedDataFormOptions[serverGroupDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[serverGroupDataForm[n].field_key]["errorText"] = defaultTexts[serverGroupDataForm[n].error_label]
            }
        }
        setServerGroupFormOptions(updatedDataFormOptions)
        return validation_faild
    }

    const handleServerGroupDataNormalization = () => {
        let data_to_send = {...serverGroupForm}
        if (parseFloat(computeNovaMaxApiVersion) < parseFloat(POLICY_RULES_MIN_API_V)) {
            data_to_send["policies"] = [serverGroupForm.policy]
            delete data_to_send["policy"]
        } else {
            if (serverGroupForm.policy === POLICY_RULES_REQUIRED_POLICY) {
                data_to_send["rules"] = serverGroupRuleForm
            }
        }
        return data_to_send
    }

    const handleServerGroupFormReset = () => {
        setServerGroupForm({})
        setServerGroupFormOptions({})
        setServerGroupRuleForm({})
    }

    const onServerGroupAdd = async () => {
        const validateFormData = handleServerGroupFormValidation()
        if (validateFormData) {
            const data = handleServerGroupDataNormalization()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {

                const url = `${computeServiceDomain}/${computeServiceVersion}/${serverGroupsUrl}`
                const method = "POST"
                const group_response = await computeNovaRequest({
                    url:url, 
                    method:method, 
                    data: {server_group: data}, 
                    token: project_token
                })
                if (group_response.status_code === 200) {
                    handleGroupsDataFetch()
                    return true
                } else {
                    setError({
                        error_title: "",
                        error_message: "",
                        error_details: group_response.error})
                    return false
                }
            }
        }
    }

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"
            const projects_response = await openstackRequest({url:url, method:method})
            
            if ( projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                const projects_list = projects_response.data.projects.map(project => {
                    return {keyword: project.name, value: project.id, default: false}
                })
                setProjectsList(projects_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}`
            const method = "GET"
            const users_response = await openstackRequest({url:url, method:method})
            
            if ( users_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                const users_list = users_response.data.users.map(user => {
                    return {keyword: user.name, value: user.id, default: false}
                })
                setUsersList(users_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        usersUrl
    ]);


    useEffect(() => {
        if (fetchGroupsDataRequired) {
                (async () => {
                    handleLoading(true)
                    const url = `${computeServiceDomain}/${computeServiceVersion}/${serverGroupsUrl}?all_projects=true`
                    const method = "GET"
                    const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                    if (project_token) {
                        const server_groups_response = await computeNovaRequest({
                            url:url, 
                            method:method, 
                            token: project_token
                        })
                        setServerGroups(server_groups_response.data.server_groups)
                    }
                })();
           setFetchGroupsDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},600)
    },[
        computeServiceDomain,
        computeServiceVersion,
        serverGroupsUrl,
        defaultAdminProject,
        fetchGroupsDataRequired
    ]);

    useEffect(() => {
        (async () => {
            const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/detail?all_tenants=true`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const servers_response = await openstackRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                setServersData(servers_response.data.servers)

            }
        })();
    },[
        computeServiceDomain,
        computeServiceVersion,
        serversUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (Object.keys(currentFilters).length === 0) {
            let new_filter_data = {}
            for (let g in serversFilterGroups) {
                for (let n in serversFilters[serversFilterGroups[g].name]) {
                    if (
                        serversFilters[serversFilterGroups[g].name][n].field_type === "string" || 
                        serversFilters[serversFilterGroups[g].name][n].field_type === "select" ||
                        serversFilters[serversFilterGroups[g].name][n].field_type === "list"
                        ) {
                            new_filter_data[serversFilters[serversFilterGroups[g].name][n].field_key] = ""
                    } else if (serversFilters[serversFilterGroups[g].name][n].field_type === "bool") {
                        new_filter_data[serversFilters[serversFilterGroups[g].name][n].field_key] = serversFilters[g.name][n].default_value
                    }
                }
            }
            setCurrentFilters(new_filter_data)
        }
    },[
        currentFilters
    ]);

    useEffect(() => {
        if (serverGroups.length > 0) {
            let formattedGroups = [...serverGroups]
            if (Object.keys(serverGroups[0]).includes("project_id") && 
                projectsList.length > 0 && 
                usersList.length > 0) {
                formattedGroups = formattedGroups.map(gr => {
                    let new_item = {...gr}
                    new_item.project_id = projectsList.filter(pj => pj.value === gr.project_id)[0].keyword
                    new_item.user_id = usersList.filter(u => u.value === gr.user_id)[0].keyword
                    return new_item
                })
            }
            formattedGroups = formattedGroups.map(gr => {
                let new_item = {...gr}
                if (gr.members.length > 0) {
                    let gr_members = gr.members.map(m => {
                        const server = serversData.filter(s => s.id === m)
                        if (server.length > 0) {
                            return server[0].name
                        } else {
                            return m
                        }
                    })
                    new_item.members = gr_members
                }
                return new_item
            })
            setFormattedServerGroups(formattedGroups)
        } else {
            setFormattedServerGroups(serverGroups)
        }
    },[
        serverGroups,
        projectsList,
        usersList,
        serversData
    ]);

    useEffect(() => {
        if (Object.keys(serverGroupForm).length === 0) {
            let new_form_data = {}
            for (const n in serverGroupDataForm) {
                if (
                    serverGroupDataForm[n].field_type === "string" || 
                    serverGroupDataForm[n].field_type === "select" ||
                    serverGroupDataForm[n].field_type === "list"
                    ) {
                    new_form_data[serverGroupDataForm[n].field_key] = ""
                } else if (serverGroupDataForm[n].field_type === "bool") {
                    new_form_data[serverGroupDataForm[n].field_key] = serverGroupDataForm[n].default_value ? 
                    serverGroupDataForm[n].default_value : 
                    false
                } else if (serverGroupDataForm[n].field_type === "integer") {
                    new_form_data[serverGroupDataForm[n].field_key] = serverGroupDataForm[n].default_value ? 
                    serverGroupDataForm[n].default_value : 
                    0
                }
            }
            setServerGroupForm(new_form_data)
        }
    },[serverGroupForm]);

    useEffect(() => {
        if (Object.keys(serverGroupRuleForm).length === 0) {
            let new_form_data = {}
            for (const n in serverGroupRulesDataForm) {
                if (
                    serverGroupRulesDataForm[n].field_type === "string" || 
                    serverGroupRulesDataForm[n].field_type === "select" ||
                    serverGroupRulesDataForm[n].field_type === "list"
                    ) {
                    new_form_data[serverGroupRulesDataForm[n].field_key] = ""
                } else if (serverGroupRulesDataForm[n].field_type === "bool") {
                    new_form_data[serverGroupRulesDataForm[n].field_key] = serverGroupRulesDataForm[n].default_value ? 
                    serverGroupRulesDataForm[n].default_value : 
                    false
                } else if (serverGroupRulesDataForm[n].field_type === "integer") {
                    new_form_data[serverGroupRulesDataForm[n].field_key] = serverGroupRulesDataForm[n].default_value ? 
                    serverGroupRulesDataForm[n].default_value : 
                    0
                }
            }
            setServerGroupRuleForm(new_form_data)
        }
    },[serverGroupRuleForm]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <Box 
                sx={{
                    flexDirection: "row", 
                    justifyContent: 'space-between',
                    my: 2
                }}>
                    <Stack 
                        direction="row" 
                        justifyContent="space-between" 
                        alignItems="center"
                        sx={{position: "relative", width: "100%"}}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-start"
                            spacing={2}
                        >
                            <AddButton 
                                getDataForm={getServerGroupDataForm}               
                                onSubmit={onServerGroupAdd}
                                formReset={handleServerGroupFormReset}
                                customTexts={{
                                    title: defaultTexts.addNewServerGroupTitle
                                }}
                            />
                        </Stack>
                    </Stack>
            </Box>
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && 
                <ComputeServerGroupsTableV21 
                    serverGroupsData={formattedServerGroups}
                    setServerGroupsData={setFormattedServerGroups}
                    currentAction={currentGroupAction}
                    setCurrentAction={setCurrentGroupAction}
                    actionsTexts={defaultTexts}
                    actionsList={getServerGroupsActionsList()}
                />}
            <CustomDialog
                open={serverGroupDeleteConfirmDialogOpen}
                onClose={handleServerGroupDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.serverGroupDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.serverGroupDeleteConfirmText}: [${selectedServerGroups.map(s => s.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onServerGroupDelete, 
                    sx: {color: 'primary.main'}}]}
            />
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default ComputeServerGroupsWrapperV21;
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import VirtualIPsSubheaderV20 from './virtualIPsSubheaderV2.0';
import VirtualIPsTableV20 from './virtualIPsTableV2.0';
import VirtualIPDetailV20 from './virtualIPDetailV2.0';
import { virtualIPsFilterMenu } 
from '../../../../../../_data/openstack/neutron/virtual_ips/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { virtualIPsUrl as virtualIPUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/virtual_ips/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const VirtualIPsWrapperV20 = (props) => {
    const { navigate, location } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [virtualIPsData, setVirtualIPsData] = useState([])
    const [virtualIPs, setVirtualIPs] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedVirtualIP, setSelectedVirtualIP] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [virtualIPDeleteConfirmDialogOpen, setVirtualIPDeleteConfirmDialogOpen] = useState(false);
    const [selectedVirtualIPs, setSelectedVirtualIPs] = useState([])
    const [virtualIPsSortParams, setVirtualIPsSortParams] = useState("")
    
    const [virtualIPFilterQueryParams, setVirtualIPFilterQueryParams] = useState("")
    const [selectedVirtualIPFilter, setSelectedVirtualIPFilter] = useState(virtualIPsFilterMenu[0].value)
    const [selectedVirtualIPFilterValue, setSelectedVirtualIPFilterValue] = useState("")
    const [virtualIPsFilter, setVirtualIPsFilter] = useState([...virtualIPsFilterMenu])

    const [projects, setProjects] = useState([])
    const [networks, setNetworks] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const virtualIPsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.virtualIPsUrl)[0].url)
    const networksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleVirtualIPFilteredSearch = () => {
        if (selectedVirtualIPFilter && selectedVirtualIPFilterValue) {
            setVirtualIPFilterQueryParams(`${selectedVirtualIPFilter}=${selectedVirtualIPFilterValue}`)
        } else {
            setVirtualIPFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleVirtualIPsDataFormatting = useCallback(() => {
        const formatted_data = virtualIPsData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            const network = networks.filter(n => n.id === item.network_id)
            if (network.length > 0) {
                new_item.network_id = network[0].name
            } else {
                new_item.network_id = "-"
            }
            return new_item
        })
        setVirtualIPs(formatted_data)
    },[
        virtualIPsData,
        projects,
        networks
    ])

    const handleVirtualIPFilterReset = () => {
        setSelectedVirtualIPFilter(virtualIPsFilterMenu[0].value)
        setSelectedVirtualIPFilterValue("")
        setVirtualIPFilterQueryParams("")
        handleDataFetch()
    }

    const handleNavigateToNetwork = (network_id) => {
        handleDetailCardClose()
        navigate("/networks/l2networking",{state: {network_id: network_id}})
    }

    const handleNavigateToServer = (server_id) => {
        handleDetailCardClose()
        navigate("/compute/servers",{state: {server_id: server_id}})
    }

    const handleNavigateToRouter = (router_id) => {
        handleDetailCardClose()
        navigate("/networks/l3networking",{state: {router_id: router_id}})
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedVirtualIP(virtualIPsData[index].id)
        setSelectedRow(virtualIPsData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[virtualIPsData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedVirtualIP(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleVirtualIPsSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setVirtualIPsSortParams(sort_param)
        handleDataFetch()
    }

    const handleVirtualIPDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${virtualIPsUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === virtualIPUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onVirtualIPDelete = async () => {
        handleVirtualIPDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedVirtualIPs) {
            const resp = await handleVirtualIPDelete(selectedVirtualIPs[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onVirtualIPDeleteConfirm = (f_list) => {
        const selected_f_list = virtualIPsData.filter(f => 
            f_list.includes(f.id))
        setSelectedVirtualIPs([...selected_f_list])
        setVirtualIPDeleteConfirmDialogOpen(true)
    }

    const handleVirtualIPDeleteConfirmDialogClose = () => {
        setVirtualIPDeleteConfirmDialogOpen(false)
    }

    const getVirtualIPsActionsList = () => {
        let virtual_ip_actions = []
        let new_action = {}
        new_action["value"] = "virtual_ip_delete"
        new_action["action"] = onVirtualIPDeleteConfirm
        new_action["keyword"] = "virtualIPDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        virtual_ip_actions.push({...new_action})
        
        return virtual_ip_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${virtualIPsUrl}?${virtualIPFilterQueryParams}${virtualIPsSortParams}`
                    const method = "GET"
                    const fip_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (fip_response.status_code === virtualIPUrlResponses.get.success_response.status_code) {
                        setVirtualIPsData(fip_response.data.local_ips)
                        if (selectedVirtualIP) {
                            const selected_fip = fip_response.data.local_ips.filter(item => item.id === selectedVirtualIP)
                            if (selected_fip.length > 0) {
                                setSelectedRow(selected_fip[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        virtualIPsUrl, 
        virtualIPFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        virtualIPsSortParams,
        selectedVirtualIP
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
                const method = "GET"
                const network_response = await openstackRequest({url:url, method:method, token: project_token})
                if (network_response.status_code === virtualIPUrlResponses.get.success_response.status_code) {
                    setNetworks(network_response.data.networks)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        networksUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (virtualIPsData.length > 0) {
            handleVirtualIPsDataFormatting()
        }
    },[
        virtualIPsData,
        handleVirtualIPsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        let networks_filter = []
        if (networks.length > 0) {
            networks_filter = networks.map(network => {
                return {keyword: network.name, value: network.id, default: false}
            })
        }
        
        let fip_filter_menu = virtualIPsFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            if (nt.value === "network_id") {
                new_item.items = [...networks_filter]
            }
            return new_item
        })

        setVirtualIPsFilter(fip_filter_menu)
    },[
        virtualIPsData,
        projects,
        networks
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const fip_id = location.state ? location.state.virtual_ip_id : null
                const fip_index = virtualIPsData.findIndex(v => v.id === fip_id);
                if (fip_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(fip_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        virtualIPsData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <VirtualIPsSubheaderV20 
                selectedFilter={selectedVirtualIPFilter} 
                setSelectedFilter={setSelectedVirtualIPFilter}
                selectedFilterValue={selectedVirtualIPFilterValue}
                setSelectedFilterValue={setSelectedVirtualIPFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={virtualIPsFilter}
                handleFilteredSearch={handleVirtualIPFilteredSearch}
                handleFilterReset={handleVirtualIPFilterReset}
                networks={networks}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <VirtualIPsTableV20 
                virtualIPs={virtualIPs}
                setVirtualIPs={setVirtualIPs}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getVirtualIPsActionsList()}
                sortHandler={handleVirtualIPsSorting}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <VirtualIPDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleNavigateToNetwork={handleNavigateToNetwork}
                    handleNavigateToServer={handleNavigateToServer}
                    handleNavigateToRouter={handleNavigateToRouter}
                    handleDelete={onVirtualIPDeleteConfirm}
                    projects={projects}
                    networks={networks}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={virtualIPDeleteConfirmDialogOpen}
            onClose={handleVirtualIPDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.virtualIPDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.virtualIPDeleteConfirmText}`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onVirtualIPDelete, 
                sx: {color: 'primary.main'}}]}
        />
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default VirtualIPsWrapperV20;
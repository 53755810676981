export const computeNovaTexts = {
    submenuServers: "servers",
    submenuServerGroups: "server groups",
    submenuFlavors: "flavors",
    submenuKeypairs: "keypairs",
    submenuLimits: "limits",
    submenuInfrastructure: "infrastructure",
    flavorCreateSuccessResponseMessage: "New flavor was successfully created.",
    flavorUpdateSuccessResponseMessage: "Flavor was successfully updated.",
    flavorDeleteSuccessResponseMessage: "Flavor was successfully deleted.",
    addFlavorDialogTitle: "Add New Flavor",
    vCPUsHelpText: "The number of virtual CPUs that will be allocated to the server.",
    ramHelpText: "The amount of RAM a flavor has, in MiB.",
    diskHelpText: "The size of the root disk that will be created in GiB. If 0 the root disk will be set to exactly the size of the image used to deploy the instance. However, in this case the scheduler cannot select the compute host based on the virtual image size. Therefore, 0 should only be used for volume booted instances or for testing purposes.",
    ephemeralDiskHelpText: "The size of the ephemeral disk that will be created, in GiB. Ephemeral disks may be written over on server state changes. So should only be used as a scratch space for applications that are aware of its limitations.",
    swapHelpText: "The size of a dedicated swap disk that will be allocated, in MiB. If 0 (the default), no dedicated swap disk will be created.",
    rxtxFactorHelpText: "The receive / transmit factor (as a float) that will be set on ports if the network backend supports the QOS extension. Otherwise it will be ignored. It defaults to 1.0.",
    flavorAccessTypeHelpText: "Whether the flavor is public (available to all projects) or scoped to a set of projects. Default is True.",
    flavorDisableActionLabel: "Disable Flavor",
    flavorEnableActionLabel: "Enable Flavor",
    flavorDeleteActionLabel: "Delete Flavor",
    flavorMakeAccessPrivateActionLabel: "Make Private Access",
    flavorMakeAccessPublicActionLabel: "Make Public Access",
    privateFlavorAddProjectAccessActionLabel: "Add access to project",
    privateFlavorRemoveProjectAccessActionLabel: "Remove access from project",
    flavorAccessFormFieldLabel: "Flavor Access",
    allProjectsAccessTableLabelText: "All projects",
    showProjectsAccessTableLabelText: "Show",
    flavorAccessProjectsDialogTitle: "Flavor Access Projects",
    addFlavorAccessProjectsDialogTitle: "Add Flavor Access Project",
    removeFlavorAccessProjectsDialogTitle: "Remove Flavor Access Project",
    flavorAccessProjectsRemoveDialogMessage: "Select project below, which will be removed from allowed list to access selected flavor(s)",
    flavorAccessProjectsAddDialogMessage: "Select project below, which will be allowed to access selected flavor(s)",
    noAccessProjectsNoteText: "No projects allowed",
    keypairCreateSuccessResponseMessage: "New keypair was successfully created.",
    keypairDeleteSuccessResponseMessage: "Keypair was successfully deleted.",
    addKeypairDialogTitle: "Add New Keypair",
    addKeypairDialogDescriptionText: "Please, copy and paste your public key in form below.",
    keypairDeleteActionLabel: "Delete keypair",
    cpuCoresTitleText: "CPU Cores",
    ramTitleText: "RAM",
    serverInstancesTitleText: "Server Instances",
    serverGroupsTitleText: "Servr Groups",
    totalAllowedKeypairsTitleText: "Total Allowed Keypairs",
    totalAllowedServerMetadataTitleText: "Total Allowed Server Metadata",
    totalAllowedServerGroupMembers: "Total Allowed Server Group Members",
    availableRAMGBLegendText: "Available RAM (GB)",
    totalUsedRAMGBLegendText: "Total Used RAM (GB)",
    availableCPUCoresLegendText: "Available CPU Cores",
    totalUsedCPUCoresLegendText: "Total Used CPU Cores",
    availableInstancesLegendText: "Available Instances",
    totalUsedInstancesLegendText: "Total Used Instances",
    availableServerGroupsLegendText: "Available Groups",
    totalUsedServerGroupsLegendText: "Total Used Groups",
    powerStateFormFieldLabel: "Power State",
    noStatePowerStatus:"No State",
    runningStatePowerStatus:"Running",
    pausedStatePowerStatus:"Paused",
    shutdownStatePowerStatus:"Shutdown",
    crashedStatePowerStatus:"Crashed",
    suspendedStatePowerStatus:"Suspended",
    ageFormFieldLabel: "Age",
    submenuVolumes: "Volumes",
    submenuInterfaces: "Interfaces",
    submenuSecurityGroups: "Security Groups",
    submenuMetadata: "Metadata",
    submenuUserData: "User Data",
    submenuFault: "Fault",
    adminPassChangeActionTitle: "Change admin password",
    addSecGroupActionTitle: "Add security group",
    removeSecGroupActionTitle: "Remove security group",
    createBackupActionTitle: "Create Backup",
    createImageActionTitle: "Create Image",
    triggerCrashDumpActionTitle: "Trigger crash dump",
    editServerActionTitle: "Edit Server",
    resizeServerActionTitle: "Resize Server",
    confirmResizeServerActionTitle: "Confirm Server Resize",
    lockServerActionTitle: "Lock Server",
    unlockServerActionTitle: "Unlock Server",
    rebuildServerActionTitle: "Rebuild Server",
    restoreSoftDeleteActionTitle: "Restore soft deleted",
    forceDeleteActionTitle: "Force Delete",
    shelveServerActionTitle: "Shelve Server",
    unshelveServerActionTitle: "Unshelve Server",
    shelfOffloadServerActionTitle: "Shelf offload (remove)",
    migrateServerActionTitle: "Migrate Server",
    liveMigrateServerActionTitle: "Live migrate server",
    attachVolumeActionTitle: "Attach Volume",
    detachVolumeActionTitle: "Detach Volume",
    attachIntefaceActionTitle: "Attach Interface",
    detachInerfaceActionTitle: "Detach Interface",
    addTagActionTitle: "Add Tag",
    deleteTagActionTitle: "Delete Tag",
    createUpdateMetadataActionTitle: "Create/update metadata",
    replaceMetadataActionTitle: "Replace metadata",
    removeMetadataActionTitle: "Remove metadata",
    serverActionCategoryTitle: "Security Actions",
    serverChangeActionCategoryTitle: "Modify Actions",
    serverBackupActionCategoryTitle: "Backup Actions",
    serverVolumesActionCategoryTitle: "Volume Actions",
    serverInterfacesActionCategoryTitle: "Interface Actions",
    serverTagsActionCategoryTitle: "Tag Actions",
    serverMetadataActionCategoryTitle: "Metadata Actions",
    serverCreateSnapshotActionTitle: "Create Snapshot",
    failedActionErrorDialogTitle: "Action Failed!",
    failedActionErrorDialogMessage: "Your action could not be completed.",
    successActionDialogTitle: "Action Succeeded!",
    successActionDialogMessage: "Your action successfully completed.",
    addSecGroupActionDialogMessage: "Select security group to apply to server.",
    removeSecGroupActionDialogMessage: "Select security group to remove from server.",
    securityGroupFormFieldLabel: "Security Group",
    updateServerActionTitle: "Update Server Details",
    serverUpdateSuccessResponseTitle: "Success Update!",
    serverUpdateSuccessResponseMessage: "Server details successfully updated.",
    invalidIPAddressFormError: "Invalid IP Address",
    changeAdminPassActionTitle: "Change Admin Password",
    adminPassFormFieldLabel: "New Admin Password",
    serverInterfaceAttachActionTitle: "Attach New Interface",
    networkFormFieldLabel: "Network",
    fixedIPFormFieldLabel: "Fixed IP Address",
    networkInterfaceFormFieldLabel: "Network Interface",
    removeNetworkInterfaceActionTitle: "Remove Network Interface",
    removeNetworkInterfaceActionDialogMessage: "Select network interface below",
    volumeFormFieldLabel: "Volume",
    deleteOnTerminateFormFieldLabel: "Delete on server terminate",
    serverVolumeAttachActionTitle: "Attach New Volume",
    removeVolumeActionTitle: "Remove Volume",
    diskPartitionFormFieldLabel: "Disk Partition",
    manualPartitionConfigChoice: "Manual",
    autoPartitionConfigChoice: "Auto",
    serverResizeActionTitle: "Resize Server",
    serverMetadataAddActionTitle: "Add Extra Metadata",
    addMetadataFormFieldLabel: "Metadata key/value pair",
    serverStartActionWarningMessage1: "Server has wrong status.",
    serverStartActionWarningMessage2: "It must be",
    serverMetadataDeleteConfirmTitle: "Confirm Metadata Delete",
    serverMetadataDeleteConfirmText: "Are you sure, you want to delete selected metadata?",
    serverDeleteActionTitle: "Delete Server",
    serverDeleteConfirmTitle: "Confirm Server Delete",
    serverDeleteConfirmText: "Are you sure, you want to delete selected servers? This action is irreversible. Selected servers",
    serverConsoleTitle: "Console",
    policyFormFieldLabel: "Policy",
    rulesFormFieldLabel: "Policy Rules",
    membersFormFieldLabel: "Members",
    serverGroupPolicyHelpText: `
    <p>The policy represents the name of the policy.</p> 
    <p>The current valid policy names are: </p>
    <p><strong>- anti-affinity</strong> - servers in this group must be scheduled to different hosts. </p>
    <p><strong>- affinity</strong> - servers in this group must be scheduled to the same host. </p>
    <p><strong>- soft-anti-affinity</strong> - servers in this group should be scheduled to different hosts if possible, but if not possible then they should still be scheduled instead of resulting in a build failure. </p>
    <p><strong>- soft-affinity</strong> - servers in this group should be scheduled to the same host if possible, but if not possible then they should still be scheduled instead of resulting in a build failure.</p>
    `,
    maxServerPerHostFormFieldLabel: "Maximum servers per group",
    serverGroupDeleteActionTitle: "Delete Server Group",
    serverGroupDeleteConfirmTitle: "Confirm Server Group Delete",
    serverGroupDeleteConfirmText: "Are you sure, you want to delete selected server groups? This action is irreversible. Selected server groups",
    showStatsSubheaderButtonText: "Show Stats",
    hideStatsSubheaderButtonText: "Hide Stats",
    attachedVolumesTitleText: "Attached Volumes",
    volumeIDTitleText: "Volume ID",
    attachementDeviceTitleText: "Attached on",
    addNewServerInstanceTitle: "Add New Server Instance",
    addNewServerGroupTitle: "Add New Server Group",
    serversTabButtonText: "Servers",
    serverGroupsTabButtonText: "Server Groups",
    flavorDetailsDialogTitle: "Flavor",
    detailsErrorNoteDialogText: "Details",
    responseNoteDialogText: "Response",
    totalCPUUsageStatsTitle: "Total CPU Usage",
    totalRAMUsageStatsTitle: "Total RAM Usage",
    totalStorageUsageStatsTitle: "Total Storage Usage",
    serverTotalHoursUsageStatsTitle: "Servers Total Hours",
    selectedProjectsButtonText: "Selected Projects",
    allProjectsButtonText: "All Projects",
    selecteProjectsDialogTitleText: "Select Projects",
    selecteProjectsDialogMessageText: "Select one or more projects to recalculate usage statistics for selected projects, otherwise usage statistics for all projects is calculates",
    selectServerStatsDatesDialogTitleText: "Select Dates",
    selectServerStatsDatesDialogMessageText: "Select start and/or end time period for which usage statistics will be calculated, otherwise current month period will be selected.",
    startTimeTitleText: "Start Time",
    endTimeTitleText: "End Time",
    minutesText: "minutes",
    hoursText: "hours",
    daysText: "days",
    serverGroupsFormFieldLabel: "Server Groups",
    ageTableHeaderTitleText: "Age",
    flavorIDTitleText: "Flavor ID",
    flavorNameTitleText: "Flavor Name",
    computeHostFormFieldLabel: "Compute Host",
    hypervisorHostnameFormFieldLabel: "Hypervisor Hostname",
    sshKeyNameFormFieldLabel: "SSH Key Name",
    vmStateFormFieldLabel: "VM State",
    taskStateFormFieldLabel: "Task State",
    lunchedAtFormFieldLabel: "Lunched at",
    updatedAtFormFieldLabel: "Updated at",
    terminatedAtFormFieldLabel: "Terminated at",
    imageFormFieldLabel: "Image",
    configDriveFormFieldLabel: "Config Drive",
    diskConfigFormFieldLabel: "Disk Config",
    instanceNameFormFieldLabel: "Instance Name",
    kernelIDFormFieldLabel: "Kernel ID",
    isLockedFormFieldLabel: "Is Locked",
    lockReasonFormFieldLabel: "Lock Reason",
    lunchIndexFormFieldLabel: "Lunch Index",
    ramdiskIDFormFieldLabel: "Ramdisk ID",
    reservationIDFormFieldLabel: "Reservation ID",
    rootDeviceNameFormFieldLabel: "Root Device Name",
    hostIDFormFieldLabel: "Host ID",
    detailsSubmenuTitleText: "Details",
    rulesFormFieldLablel: "Rules",
    directionFormFieldLabel: "Direction",
    etherTypeFormFieldLabel: "Ether Type",
    ipProtocolFormFieldLabel: "IP Protocol",
    portRangeFormFieldLabel: "Port Range",
    remoteIPPrefixFormFieldLabel: "Remote IP Prefix",
    remoteSecGroupFormFieldLabel: "Remote Security Group",
    anyKeywordText: "Any",
    appliedMetadataTitleText: "Applied Metadata",
    keyKeywordText: "Key",
    valueKeywordText: "Value",
    attachedInterfacesTitleText: "Attached Interfaces",
    ipAddressFormFieldLabel: "IP Address",
    subnetFormFieldLabel: "Subnet",
    macAddressFormFieldLabel: "MAC Address",
    portFormFieldLabel: "Port",
    faultCodeFormFieldLabel: "Fault Code",
    createdFormFieldLabel: "Created",
    faultMessageFormFieldLabel: "Fault Message",
    faultDetailsFormFieldLabel: "Fault Details",
    noUserDataNoteText: "No User Data",
    hostAggregateDeleteActionTitle: "Delete Host Aggregate",
    aggregateDeleteConfirmTitle: "Confirm Host Aggregate Delete",
    AggregateDeleteConfirmText: "Are you sure, you want to delete selected host aggregates?",
    addAggregateDialogTitle: "Add New Host Aggregate",
    hostAggregatesTabButtonText: "Host Aggregates",
    availabilityZonesTabButtonText: "Availability Zones",
    hypervisorsTabButtonText: "Hypervisors",
    aggregateUpdateActionTitle: "Update Host Aggregate",
    updateAggregateActionTitle: "Update Host Aggregate",
    uuidFormFieldLabel: "UUID",
    hostsFormFieldLabel: "Hosts",
    submenuHosts: "Hosts",
    invalidAvailabilityZoneNameError: "Invalid Availability Zone name. It should not contain ':' character.",
    noHostNoteText: "No Hosts",
    aggregateAddHostActionTitle: "Add Hosts",
    addHostToAggregateActionTitle: "Add Hosts to Aggregate",
    addHostToAggregateActionText: "Selecte host(s) below to add to current host aggregate",
    aggregateMetadataUpdateActionTitle: "Update Host Aggregate Metadata",
    availableFormFieldLabel: "Available",
    hypervisorTypeFormFieldLabel: "Hypervisor Type",
    hostIPFormFieldLabel: "Host IP",
    serverInstancesFormFieldLabel: "Server Instances",
    runningVMsFormFieldLabel: "Running VMs",
    hypervisorInstancesDetailsDialogTitle: "Hypervisor Instances",
    flavorDeleteConfirmTitle: "Confirm Flavor Delete",
    flavorDeleteConfirmText: "Are you sure, you want to delete selected flavors? This operation can not be reverted. Selected flavors",
    flavorMetadataUpdateActionTitle: "Update Flavor Exta Specs",
    updateFlavorActionTitle: "Update Flavor",
    flavorUpdateActionTitle: "Update Flavor",
    serverCreateTitle: "Create New Server Instance",
    generalInfoSectionTitle: "General Info",
    bootSourceSectionTitle: "Boot Source",
    flavorSectionTitle: "Flavor", 
    storageSectionTitle: "Storage",
    networkSectionTitle: "Network",
    securitySectionTitle: "Security",
    keyPairSectionTitle: "Keypair",
    createNewVolumeFormFieldLabel: "Create New System Disk",
    instanceCountFormFieldLabel: "Instance Count",
    imageBootSourceFormFieldLabel: "Image",
    instanceSnapshotBootSourceFormFieldLabel: "Instance Snapshot",
    volumeBootSourceFormFieldLabel: "Volume",
    volumeSnapshotBootSourceFormFieldLabel: "Volume Snapshot",
    sizeInGBFormFieldLabel: "Size (GB)",
    dataDiskTableTitle: "Data Disk",
    systemDiskTableTitle: "System Disk",
    doNotDeleteOnTerminateFormFieldLabel: "Do not delete on server terminate",
    defaultVolumeTypeFormFieldLabel: "Default",
    addMoreDataVolumeFormFieldLabel: "Add Data Volumes",
    networkAllocationOptionsLabel: "Network Allocation",
    autoNetworkAllocationTypeTitle: "Auto",
    noneNetworkAllocationTypeTitle: "None",
    manualNetworkAllocationTypeTitle: "Manual",
    addNetworkFormFieldLabel: "Add Network",
    autoIPAllocationOptionLabel: "Automatic",
    manualIPAllocationOptionLabel: "Manual",
    ipAllocationTypeFormFieldLabel: "IP Allocation Type",
    portSectionTitle: "Port",
    addPortFormFieldLabel: "Add Network Port",
    createNewSecGroupFormFieldLabel: "Create New Security Group",
    allowSSHFormFieldLabel: "Allow SSH",
    allowHTTPFormFieldLabel: "Allow HTTP",
    allowHTTPSFormFieldLabel: "Allow HTTPS",
    noSecGroupAppliedToServerNoteText: "At least one network with enabled port security must be selected to apply security groups to it. Currently no network with required property is selected.",
    mulformSecGroupAppliedToServerNoteText: "Not all selected networks have enabled port security. You are not allowed to apply security groups to such networks.",
    selectedSecurityGroupsNoteText: "Selected Security Groups",
    selectedPortsNoteText: "Selected Ports",
    uploadPublicKeyButtonText: "Import from file",
    createNewKeypairFormFieldLabel: "Create New Keypair",
    keypairFormFieldLabel: "SSH Keypair",
    showAdvancedOptionsButtonText: "Show Advanced Options",
    hideAdvancedOptionsButtonText: "Hide Advanced Options",
    adminPasswordSectionTitle: "Admin Password",
    descriptionSectionTitle: "Description",
    availabilityZoneSectionTitle: "Availability Zone",
    diskPartitionSectionTitle: "Disk Partition",
    serverGroupSectionTitle: "Server Group",
    userDataSectionTitle: "Custom Configuration",
    metadataSectionTitle: "Metadata",
    setAdminPasswordFormFieldLabel: "Set Admin Password",
    automaticDiskPartitionOptionLabel: "Automatic",
    manualDiskPartitionOptionLabel: "Manual",
    serverGroupFormFieldLabel: "Server Group",
    customCofigurationFormFieldLabel: "Custom Configuration Script",
    uploadUserDataButtonText: "Import from file",
    bytesFormFieldLabel: "Bytes",
    providedDataSizeLabelText: "Provided data size",
    maxAllowedDataSizeLabel: "Maximum allowed",
    addMetadataButtonText: "Add Metadata",
    serverMetadataSelectActionTitle: "Apply Server Metadata",
    volumeSnapshotFormFieldLabel: "Volume Snapshot",
    emptyNetworkListErrorText: "At least one network must be selected with manual network allocation.",
    //New Items
    submenuHostAggregates: "Host Aggregates",
    submenuAvailabilityZones: "Availability Zones",
    submenuHypervisors: "Hypervisors"
}
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        computeNovaConstants,
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import FloatingIPsSubheaderV20 from './floatingIPsSubheaderV2.0';
import FloatingIPsTableV20 from './floatingIPsTableV2.0';
import FloatingIPDetailV20 from './floatingIPDetailV2.0';
import { floatingIPsFilterMenu } 
from '../../../../../../_data/openstack/neutron/floating_ips/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { floatingIPsUrl as floatingIPUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/floating_ips/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';

const SERVICE_NAME = openStackServices.networkService
const COMPUTE_SERVICE_NAME = openStackServices.computeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const FloatingIPsWrapperV20 = (props) => {
    const { navigate, location } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [floatingIPsData, setFloatingIPsData] = useState([])
    const [floatingIPs, setFloatingIPs] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedFloatingIP, setSelectedFloatingIP] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [floatingIPDeleteConfirmDialogOpen, setFloatingIPDeleteConfirmDialogOpen] = useState(false);
    const [selectedFloatingIPs, setSelectedFloatingIPs] = useState([])
    const [floatingIPsSortParams, setFloatingIPsSortParams] = useState("")
    
    const [floatingIPFilterQueryParams, setFloatingIPFilterQueryParams] = useState("")
    const [selectedFloatingIPFilter, setSelectedFloatingIPFilter] = useState(floatingIPsFilterMenu[0].value)
    const [selectedFloatingIPFilterValue, setSelectedFloatingIPFilterValue] = useState("")
    const [floatingIPsFilter, setFloatingIPsFilter] = useState([...floatingIPsFilterMenu])

    const [projects, setProjects] = useState([])
    const [networks, setNetworks] = useState([])
    const [servers, setServers] = useState([])
    const [floatingIPPools,setFloatingIPPools] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const floatingIPsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.floatingIPsUrl)[0].url)
    const networksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
    const floatingIPPoolsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.floatingIPPoolsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
    const serversUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serversUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleFloatingIPFilteredSearch = () => {
        if (selectedFloatingIPFilter && selectedFloatingIPFilterValue) {
            setFloatingIPFilterQueryParams(`${selectedFloatingIPFilter}=${selectedFloatingIPFilterValue}`)
        } else {
            setFloatingIPFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleFloatingIPsDataFormatting = useCallback(() => {
        const formatted_data = floatingIPsData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            const network = networks.filter(n => n.id === item.floating_network_id)
            if (network.length > 0) {
                new_item.floating_network_id = network[0].name
            } else {
                new_item.floating_network_id = "-"
            }
            if (item.port_details) {
                if (item.port_details.device_owner === networkNeutronConstants.computeNovaOwner) {
                    const server = servers.filter(srv => srv.id === item.port_details.device_id)
                    new_item.port_id = server.length > 0 ? server[0].name : item.port_id
                }
            }
            return new_item
        })

        setFloatingIPs(formatted_data)
    },[
        floatingIPsData,
        projects,
        networks,
        servers
    ])

    const handleFloatingIPFilterReset = () => {
        setSelectedFloatingIPFilter(floatingIPsFilterMenu[0].value)
        setSelectedFloatingIPFilterValue("")
        setFloatingIPFilterQueryParams("")
        handleDataFetch()
    }

    const handleNavigateToNetwork = (network_id) => {
        handleDetailCardClose()
        navigate("/networks/l2networking",{state: {network_id: network_id}})
    }

    const handleNavigateToServer = (server_id) => {
        handleDetailCardClose()
        navigate("/compute/servers",{state: {server_id: server_id}})
    }

    const handleNavigateToRouter = (router_id) => {
        handleDetailCardClose()
        navigate("/networks/l3networking",{state: {router_id: router_id}})
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedFloatingIP(floatingIPsData[index].id)
        setSelectedRow(floatingIPsData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[floatingIPsData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedFloatingIP(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFloatingIPsSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setFloatingIPsSortParams(sort_param)
        handleDataFetch()
    }

    const handleFloatingIPDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${floatingIPsUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === floatingIPUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onFloatingIPDelete = async () => {
        handleFloatingIPDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedFloatingIPs) {
            const resp = await handleFloatingIPDelete(selectedFloatingIPs[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onFloatingIPDeleteConfirm = (f_list) => {
        const selected_f_list = floatingIPsData.filter(f => 
            f_list.includes(f.floating_ip_address))
        setSelectedFloatingIPs([...selected_f_list])
        setFloatingIPDeleteConfirmDialogOpen(true)
    }

    const handleFloatingIPDeleteConfirmDialogClose = () => {
        setFloatingIPDeleteConfirmDialogOpen(false)
    }

    const getFloatingIPsActionsList = () => {
        let floating_ip_actions = []
        let new_action = {}
        new_action["value"] = "floating_ip_delete"
        new_action["action"] = onFloatingIPDeleteConfirm
        new_action["keyword"] = "floatingIPDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        floating_ip_actions.push({...new_action})
        
        return floating_ip_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${floatingIPsUrl}?${floatingIPFilterQueryParams}${floatingIPsSortParams}`
                    const method = "GET"
                    const fip_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (fip_response.status_code === floatingIPUrlResponses.get.success_response.status_code) {
                        setFloatingIPsData(fip_response.data.floatingips)
                        if (selectedFloatingIP) {
                            const selected_fip = fip_response.data.floatingips.filter(item => item.id === selectedFloatingIP)
                            if (selected_fip.length > 0) {
                                setSelectedRow(selected_fip[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        floatingIPsUrl, 
        floatingIPFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        floatingIPsSortParams,
        selectedFloatingIP
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
                const method = "GET"
                const network_response = await openstackRequest({url:url, method:method, token: project_token})
                if (network_response.status_code === floatingIPUrlResponses.get.success_response.status_code) {
                    setNetworks(network_response.data.networks)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        networksUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/detail?all_tenants=true`
                const method = "GET"
                const servers_response = await openstackRequest({url:url, method:method, token: project_token})
                if (servers_response.status_code === floatingIPUrlResponses.get.success_response.status_code) {
                    
                    setServers(servers_response.data.servers)
                }
            }
        })();
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        serversUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${floatingIPPoolsUrl}`
                const method = "GET"
                const fip_response = await openstackRequest({url:url, method:method, token: project_token})
                if (fip_response.status_code === floatingIPUrlResponses.get.success_response.status_code) {
                    setFloatingIPPools(fip_response.data.floatingip_pools)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        floatingIPPoolsUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (floatingIPsData.length > 0) {
            handleFloatingIPsDataFormatting()
        }
    },[
        floatingIPsData,
        handleFloatingIPsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        let networks_filter = []
        if (networks.length > 0) {
            const external_networks = networks.filter(net => net["router:external"])
            networks_filter = external_networks.map(network => {
                return {keyword: network.name, value: network.id, default: false}
            })
        }
        
        let fip_filter_menu = floatingIPsFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            if (nt.value === "floating_network_id") {
                new_item.items = [...networks_filter]
            }
            return new_item
        })

        setFloatingIPsFilter(fip_filter_menu)
    },[
        floatingIPsData,
        projects,
        networks
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const fip_id = location.state ? location.state.floating_ip_id : null
                const fip_index = floatingIPsData.findIndex(v => v.id === fip_id);
                if (fip_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(fip_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        floatingIPsData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <FloatingIPsSubheaderV20 
                selectedFilter={selectedFloatingIPFilter} 
                setSelectedFilter={setSelectedFloatingIPFilter}
                selectedFilterValue={selectedFloatingIPFilterValue}
                setSelectedFilterValue={setSelectedFloatingIPFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={floatingIPsFilter}
                handleFilteredSearch={handleFloatingIPFilteredSearch}
                handleFilterReset={handleFloatingIPFilterReset}
                networks={networks}
                projects={projects}
                floatingIPPools={floatingIPPools}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <FloatingIPsTableV20 
                floatingIPs={floatingIPs}
                setFloatingIPs={setFloatingIPs}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getFloatingIPsActionsList()}
                sortHandler={handleFloatingIPsSorting}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <FloatingIPDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleNavigateToNetwork={handleNavigateToNetwork}
                    handleNavigateToServer={handleNavigateToServer}
                    handleNavigateToRouter={handleNavigateToRouter}
                    handleDelete={onFloatingIPDeleteConfirm}
                    projects={projects}
                    networks={networks}
                    servers={servers}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={floatingIPDeleteConfirmDialogOpen}
            onClose={handleFloatingIPDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.floatingIPDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.floatingIPDeleteConfirmText}: [${selectedFloatingIPs.map(v => v.floating_ip_address).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onFloatingIPDelete, 
                sx: {color: 'primary.main'}}]}
        />
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default FloatingIPsWrapperV20;
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants,
        computeNovaConstants
} from '../../../../../../config/openStackConstants';
import PortsSubheaderV20 from './portsSubheaderV2.0';
import PortsTableV20 from './portsTableV2.0';
import PortDetailV20 from './portDetailV2.0';
import { portsFilterMenu, portDataUpdateForm } 
from '../../../../../../_data/openstack/neutron/ports/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { portsUrl as portUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/ports/v2.0';
import { networksUrl as networkUrlResponses} from 
'../../../../../../_api_responses/openstack/neutron/networks/v2.0'
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';

const SERVICE_NAME = openStackServices.networkService
const COMPUTE_SERVICE_NAME = openStackServices.computeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const PortsWrapperV20 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [portsData, setPortsData] = useState([])
    const [ports, setPorts] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedPort, setSelectedPort] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [portDeleteConfirmDialogOpen, setPortDeleteConfirmDialogOpen] = useState(false);
    const [selectedPorts, setSelectedPorts] = useState([])
    const [portsSortParams, setPortsSortParams] = useState("")
    
    const [portFilterQueryParams, setPortFilterQueryParams] = useState("")
    const [selectedPortFilter, setSelectedPortFilter] = useState(portsFilterMenu[0].value)
    const [selectedPortFilterValue, setSelectedPortFilterValue] = useState("")
    const [portsFilter, setPortsFilter] = useState([...portsFilterMenu])
    const [portUpdateData, setPortUpdateData] = useState({})
    const [selectedUpdateField, setSelectedUpdateField] = useState("")
    const [portUpdateDialogOpen, setPortUpdateDialogOpen] = useState(false)

    const [projects, setProjects] = useState([])
    const [networks, setNetworks] = useState([])
    const [subnets, setSubnets] = useState([])
    const [secGroups, setSecGroups] = useState([])
    const [servers, setServers] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const portsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.portsUrl)[0].url)
    const networksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
    const subnetsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)
    const secGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.secGroupsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
    const serversUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serversUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handlePortFilteredSearch = () => {
        if (selectedPortFilter && selectedPortFilterValue) {
            setPortFilterQueryParams(`${selectedPortFilter}=${selectedPortFilterValue}`)
        } else {
            setPortFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handlePortsDataFormatting = useCallback(() => {
        const formatted_data = portsData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            if (item.fixed_ips.length > 0) {
                let ip_addresses = []
                for (let ip in item.fixed_ips) {
                    ip_addresses.push(item.fixed_ips[ip].ip_address)
                }
                new_item.fixed_ips = ip_addresses
            }
            const network = networks.filter(n => n.id === item.network_id)
            if (network.length > 0) {
                new_item.network_id = network[0].name
            }
            
            return new_item
        })

        setPorts(formatted_data)
    },[
        portsData,
        projects,
        networks
    ])

    const handlePortFilterReset = () => {
        setSelectedPortFilter(portsFilterMenu[0].value)
        setSelectedPortFilterValue("")
        setPortFilterQueryParams("")
        handleDataFetch()
    }

    const handleNavigateToServer = (server_id) => {
        handleDetailCardClose()
        navigate("/compute/servers",{state: {server_id: server_id}})
    }

    const handleNavigateToRouter = (router_id) => {
        handleDetailCardClose()
        navigate("/networks/l3networking",{state: {router_id: router_id}})
    }

    const handleNavigateToSecGroup = (sec_group_id) => {
        handleDetailCardClose()
        navigate("/networks/security",{state: {securityGroup_id: sec_group_id}})
    }

    const handleNavigateToNetwork = (network_id) => {
        handleDetailCardClose()
        navigate("/networks/l2networking",{state: {network_id: network_id}})
    }

    const handleNavigateToSubnet = (subnet_id) => {
        handleDetailCardClose()
        navigate("/networks/l3networking",{state: {subnet_id: subnet_id}})
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedPort(portsData[index].id)
        setSelectedRow(portsData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[portsData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedPort(null)
        navigate(location.path,{})
        changeMenuActiveTab("/ports")
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handlePortsSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setPortsSortParams(sort_param)
        handleDataFetch()
    }

    const handlePortUpdate = async (p_id,data) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}/${p_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {port: data},
                token: project_token
            })

            if (nt_response.status_code === portUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onPortUpdate = async () => {
        handlePortUpdateDialogClose()
        let err = []
        const data = {}
        data[selectedUpdateField] = portUpdateData[selectedUpdateField]
        for (let n in selectedPorts) {
            const resp = await handlePortUpdate(selectedPorts[n].id, data)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorUpdateRecordTitle"
            error_object["error_message"] = "errorUpdateRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handlePortDelete = async (p_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}/${p_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === portUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onPortDelete = async () => {
        handlePortDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedPorts) {
            const resp = await handlePortDelete(selectedPorts[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onPortDeleteConfirm = (n_list) => {
        const selected_n_list = portsData.filter(n => 
            n_list.includes(n.id))
        setSelectedPorts([...selected_n_list])
        setPortDeleteConfirmDialogOpen(true)
    }

    const handlePortDeleteConfirmDialogClose = () => {
        setPortDeleteConfirmDialogOpen(false)
    }

    const onPortUpdateAdminState = (n_list) => {
        const selected_n_list = portsData.filter(n => 
            n_list.includes(n.id))
        setSelectedPorts([...selected_n_list])
        setSelectedUpdateField("admin_state_up")
        setPortUpdateDialogOpen(true)
    }

    const onPortUpdatePortSecurity = (n_list) => {
        const selected_n_list = portsData.filter(n => 
            n_list.includes(n.id))
        setSelectedPorts([...selected_n_list])
        setSelectedUpdateField("port_security_enabled")
        setPortUpdateDialogOpen(true)
    }

    const handlePortUpdateDialogClose = () => {
        setPortUpdateDialogOpen(false)
    }

    const handlePortUpdateDataChange = (event,field_key) => {
        let updated_data = {...portUpdateData}
        const field_type = portDataUpdateForm.filter(field => field.field_key === field_key)[0].field_type
        if (field_type === "bool") {
            updated_data[field_key] = event.target.checked
        } else if (field_type === "select") {
            updated_data[field_key] = event
        } else {
            updated_data[field_key] = event.target.value
        }
        setPortUpdateData(updated_data)        
    }

    const getUpdateForm = () => {
        let form = portDataUpdateForm.filter(field => field.field_key === selectedUpdateField)
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {}
                    return (
                        getFormFieldComponent(
                            field,
                            portUpdateData,
                            handlePortUpdateDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getPortsActionsList = () => {
        let port_actions = []
        let new_action = {}
        new_action["value"] = "update_admin_state"
        new_action["action"] = onPortUpdateAdminState
        new_action["keyword"] = "portUpdateAdminStateActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        port_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "update_port_security"
        new_action["action"] = onPortUpdatePortSecurity
        new_action["keyword"] = "portUpdatePortSecurityActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        port_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "port_delete"
        new_action["action"] = onPortDeleteConfirm
        new_action["keyword"] = "portDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        port_actions.push({...new_action})
        
        return port_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}?${portFilterQueryParams}${portsSortParams}`
                    const method = "GET"
                    const port_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (port_response.status_code === portUrlResponses.get.success_response.status_code) {
                        setPortsData(port_response.data.ports)
                        if (selectedPort) {
                            const selected_port = port_response.data.ports.filter(item => item.id === selectedPort)
                            if (selected_port.length > 0) {
                                setSelectedRow(selected_port[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        portsUrl, 
        portFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        portsSortParams,
        selectedPort
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`
                const method = "GET"
                const subnets_response = await openstackRequest({url:url, method:method, token: project_token})
                if (subnets_response.status_code === portUrlResponses.get.success_response.status_code) {
                    setSubnets(subnets_response.data.subnets)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        subnetsUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${secGroupsUrl}`
                const method = "GET"
                const sec_groups_response = await openstackRequest({url:url, method:method, token: project_token})
                if (sec_groups_response.status_code === portUrlResponses.get.success_response.status_code) {
                    setSecGroups(sec_groups_response.data.security_groups)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        secGroupsUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
                const method = "GET"
                const network_response = await openstackRequest({url:url, method:method, token: project_token})
                if (network_response.status_code === networkUrlResponses.get.success_response.status_code) {
                    setNetworks(network_response.data.networks)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        networksUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/detail?all_tenants=true`
                const method = "GET"
                const servers_response = await openstackRequest({url:url, method:method, token: project_token})
                if (servers_response.status_code === networkUrlResponses.get.success_response.status_code) {
                    
                    setServers(servers_response.data.servers)
                }
            }
        })();
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        serversUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (portsData.length > 0) {
            handlePortsDataFormatting()
        }
    },[
        portsData,
        handlePortsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        let networks_filter = []
        if (networks.length > 0) {
            networks_filter = networks.map(network => {
                return {keyword: network.name, value: network.id, default: false}
            })
        }
        
        let port_filter_menu = portsFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            if (nt.value === "network_id") {
                new_item.items = [...networks_filter]
            }
            return new_item
        })

        setPortsFilter(port_filter_menu)
    },[
        portsData,
        projects,
        networks
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const port_id = location.state ? location.state.port_id : null
                const port_index = portsData.findIndex(v => v.id === port_id);
                if (port_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(port_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        portsData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        if (Object.keys(portUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in portDataUpdateForm) {
                if (
                    portDataUpdateForm[n].field_type === "string" || 
                    portDataUpdateForm[n].field_type === "select"
                    ) {
                    new_form_data[portDataUpdateForm[n].field_key] = ""
                } else if (portDataUpdateForm[n].field_type === "bool") {
                    new_form_data[portDataUpdateForm[n].field_key] = portDataUpdateForm[n].default_value ? 
                    portDataUpdateForm[n].default_value : 
                    false
                }
            }
            setPortUpdateData(new_form_data)
        }
    },[
        portUpdateData
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <PortsSubheaderV20 
                selectedFilter={selectedPortFilter} 
                setSelectedFilter={setSelectedPortFilter}
                selectedFilterValue={selectedPortFilterValue}
                setSelectedFilterValue={setSelectedPortFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={portsFilter}
                handleFilteredSearch={handlePortFilteredSearch}
                handleFilterReset={handlePortFilterReset}
                networks={networks}
                subnets={subnets}
                secGroups={secGroups}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <PortsTableV20 
                portsData={ports}
                setPortsData={setPorts}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getPortsActionsList()}
                sortHandler={handlePortsSorting}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <PortDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleDelete={onPortDeleteConfirm}
                    projects={projects}
                    networks={networks}
                    secGroups={secGroups}
                    subnets={subnets}
                    servers={servers}
                    handleNavigateToNetwork={handleNavigateToNetwork}
                    handleNavigateToSecGroup={handleNavigateToSecGroup}
                    handleNavigateToServer={handleNavigateToServer}
                    handleNavigateToRouter={handleNavigateToRouter}
                    handleNavigateToSubnet={handleNavigateToSubnet}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={portDeleteConfirmDialogOpen}
            onClose={handlePortDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.portDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.portDeleteConfirmText}`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onPortDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={portUpdateDialogOpen}
            onClose={handlePortUpdateDialogClose}
            dialogTitle={{
                title: defaultTexts.portUpdateTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onPortUpdate, 
                sx: {color: 'primary.main'}}]}
        >
                {getUpdateForm()}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default PortsWrapperV20;
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { openStackServices, 
        blockStorageCinderConstants, 
        identityKeystonConstants
} from '../../../../../config/openStackConstants';
import VolumeTransfersTableV3 from './volumeTransfersTableV3';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../_network/openstack_request';
import { volumesUrl as volumeUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import { usersUrl as usersUrlResponses } from 
'../../../../../_api_responses/openstack/identity/users/v3';

const SERVICE_NAME = openStackServices.volumeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService
const TRANSFERS_MIN_API_VERSION = "3.55"

const VolumeTransfersWrapperV3 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [volumesData, setVolumesData] = useState([])
    const [volumeTransfersData, setVolumeTransfersData] = useState([])
    const [volumeTransfers, setVolumeTransfers] = useState([])
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [fetchTransferDataRequired, setFetchTransferDataRequired] = useState(true);
    const [currentTransfersAction, setCurrentTransfersAction] = useState("");
    const [volumeTransferDeleteConfirmDialogOpen, setVolumeTransferDeleteConfirmDialogOpen] = useState(false)
    const [selectedVolumeTransfers, setSelectedVolumeTransfers] = useState([])

    const [projects, setProjects] = useState([])

    const [providedAuthKey, setProvidedAuthKey] = useState("")
    const [volumeTransferAcceptDialogOpen, setVolumeTransferAcceptDialogOpen] = useState(false)
    const [providedAuthKeyError, setProvidedAuthKeyError] = useState(false)

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const maxAPIVersion = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].max_api_version)
    const volumesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
    const volumeTransfersUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumeTransfersUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTransfersDataFetch = () => {
        setFetchTransferDataRequired(true)
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleVolumeTransferDelete = async (t_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            let url = ""
            if (parseFloat(maxAPIVersion) >= parseFloat(TRANSFERS_MIN_API_VERSION)) {
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTransfersUrl}/${t_id}`
            } else {
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/os-volume-transfer/${t_id}`
            }
            
            const method = "DELETE"
            
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (vt_response.status_code === volumeUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    };

    const onVolumeTransferDelete = async () => {
        handleVolumeTransferDeleteConfirmDialogClose()
        let err = []
        for (let t in selectedVolumeTransfers) {
            const resp = await handleVolumeTransferDelete(selectedVolumeTransfers[t].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleTransfersDataFetch()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onVolumeTransferDeleteConfirm = (t_list) => {
        const selected_t_list = volumeTransfers.filter(t => 
            t_list.includes(t.id))
        setSelectedVolumeTransfers([...selected_t_list])
        setVolumeTransferDeleteConfirmDialogOpen(true)
    }

    const handleVolumeTransferAccept = async (t_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            let url = ""
            if (parseFloat(maxAPIVersion) >= parseFloat(TRANSFERS_MIN_API_VERSION)) {
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTransfersUrl}/${t_id}/accept`
            } else {
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/os-volume-transfer/${t_id}/accept`
            }
            
            const method = "POST"
            
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                data: {
                    accept: {
                        "auth_key": providedAuthKey
                    }
                },
                token: project_token
            })

            if (vt_response.status_code === volumeUrlResponses.post_async.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    }

    const onVolumeTransferAccept = async () => {
        if (providedAuthKey.length === 0) {
            setProvidedAuthKeyError(true)
            return false
        } else {
            let err = []
            for (let t in selectedVolumeTransfers) {
                const resp = await handleVolumeTransferAccept(selectedVolumeTransfers[t].id)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
            handleVolumeTransferAcceptDialogClose()
            handleTransfersDataFetch()
            handleDataFetch()
            if (err.length > 0) {
                let error_object = {}
                error_object["error_title"] = "errorDeleteRecordTitle"
                error_object["error_message"] = "errorDeleteRecordMessage"
                error_object["error_details"] = err.toString()
                setError(error_object)
                setErrorDialogOpen(true)
            }
        }
    }

    const handleVolumeTransferAcceptDialogOpen = (t_list) => {
        const selected_t_list = volumeTransfers.filter(t => 
            t_list.includes(t.id))
        setSelectedVolumeTransfers([...selected_t_list])
        setVolumeTransferAcceptDialogOpen(true)
    }

    const handleVolumeTransferAcceptDialogClose = () => {
        setProvidedAuthKey("")
        setVolumeTransferAcceptDialogOpen(false)
    }

    const handleProvidedAuthKeyChange = (event) => {
        setProvidedAuthKeyError(false)
        setProvidedAuthKey(event.target.value)
    }

    const handleVolumeTransferDeleteConfirmDialogClose = () => {
        setVolumeTransferDeleteConfirmDialogOpen(false)
    }

    const getVolumeTransfersActionsList = () => {
        let volume_transfer_actions = []
        let new_action = {}
        new_action["value"] = "volume_transfer_accept"
        new_action["action"] = handleVolumeTransferAcceptDialogOpen
        new_action["keyword"] = "volumeTransferAcceptActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        volume_transfer_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "volume_transfer_delete"
        new_action["action"] = onVolumeTransferDeleteConfirm
        new_action["keyword"] = "volumeTransferDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        volume_transfer_actions.push({...new_action})
        
        return volume_transfer_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail`
                    const method = "GET"
                    const volume_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (volume_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        setVolumesData(volume_response.data.volumes)
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        volumesUrl,
        dataFetchingRequired,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (fetchTransferDataRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = ""
                    if (parseFloat(maxAPIVersion) >= parseFloat(TRANSFERS_MIN_API_VERSION)) {
                        url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTransfersUrl}/detail?all_tenants=true`
                    } else {
                        url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/os-volume-transfer/detail?all_tenants=true`
                    }
                    
                    const method = "GET"
        
                    const volume_transfers_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (volume_transfers_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        setVolumeTransfersData(volume_transfers_response.data.transfers)
                    }
                }
            })();
        }
        setFetchTransferDataRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        volumeTransfersUrl,
        fetchTransferDataRequired,
        defaultAdminProject,
        maxAPIVersion
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === usersUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        const formatVolumeTransfersData = (data) => {
            const new_list = data.map(item => {
                let new_item = {...item} 
                if (item.source_project_id) {
                    const source = projects.filter(p => p.id === item.source_project_id)
                    if (source.length > 0) {
                        new_item.source_project_id = source[0].name
                    }
                }
                if (item.destination_project_id) {
                    const destination = projects.filter(p => p.id === item.destination_project_id)
                
                    if (destination.length > 0) {
                        new_item.destination_project_id = destination[0].name
                    }
                }
                const volume = volumesData.filter(v => v.id === item.volume_id)
                if (volume.length > 0) {
                    new_item.volume_id = volume[0].name ? volume[0].name : volume[0].id
                }
                
                return new_item
            })
            setVolumeTransfers(new_list)
        }

        formatVolumeTransfersData(volumeTransfersData)

    },[
        projects,
        volumeTransfersData,
        volumesData
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box sx={{mt: 2}}>
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && 
                <VolumeTransfersTableV3 
                    volumeTransfersData={volumeTransfers}
                    setVolumeTransfersData={setVolumeTransfers}
                    currentAction={currentTransfersAction}
                    setCurrentAction={setCurrentTransfersAction}
                    actionsTexts={defaultTexts}
                    actionsList={getVolumeTransfersActionsList()}
                />
            }
            <CustomDialog
                open={volumeTransferDeleteConfirmDialogOpen}
                onClose={handleVolumeTransferDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.volumeTransferDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.volumeTransferDeleteConfirmText}: [${selectedVolumeTransfers.map(v => v.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onVolumeTransferDelete, 
                    sx: {color: 'primary.main'}}]}
            />
            
            <CustomDialog
                open={volumeTransferAcceptDialogOpen}
                onClose={handleVolumeTransferAcceptDialogClose}
                dialogTitle={{
                    title: defaultTexts.acceptVolumeTransferActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onVolumeTransferAccept, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomTextField
                    required={true}
                    error={providedAuthKeyError}
                    errorText={defaultTexts.requiredFormFieldError}
                    currentValue={providedAuthKey}
                    setCurrentValue={handleProvidedAuthKeyChange}
                    label={defaultTexts.authKeyFormFieldLabel}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default VolumeTransfersWrapperV3;
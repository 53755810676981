import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../../_common/WrapperBox';
import AddButton from '../../../../../_common/AddButton';
import FormGroup from '@mui/material/FormGroup';
import Constants from '../../../../../../config/constants';
import FilterButton from '../../../../../_common/FilterButton';
import CustomDialog from '../../../../../_common/CustomDialog';
import { 
    openStackServices, 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import { defaultRuleDataForm } from 
'../../../../../../_data/openstack/neutron/security/v2.0';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import { 
    getXAuthTokenProjectScope,
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { securityUrl as securityUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/security/v2.0';
import Grid from '@mui/material/Grid';
import {
    ipv4CidrChecker,
    ipv6CidrChecker
} from '../../../../../_common/common_helpers';


const SERVICE_NAME = openStackServices.networkService

const DefaultRulesSubheaderV20 = (props) => {
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successDefaultRuleAdd, setSuccessDefaultRuleAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const defaultRulesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.defaultSecGroupRulesUrl)[0].url)

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessDefaultRuleAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddDefaultRuleFormReset = () => {
        setFormDataOptions({})
        setFormData({})
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (defaultRuleDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (defaultRuleDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    const getDataForm = () => {
        let form = [...defaultRuleDataForm]
        if (formData.protocol_selection !== "other") {
            form = form.filter(item => item.field_key !== "protocol")
        } else {
            form = form.filter(item => 
                item.field_key !== "icmp_type" && 
                item.field_key !== "icmp_code" &&
                item.field_key !== "port_range_min" && 
                item.field_key !== "port_range_max"
            )
        }
        if ((!Object.keys(formData).includes("protocol_selection") || 
            formData.protocol_selection.length === 0) || 
            formData.protocol_selection === "any") {
            form = form.filter(item => 
                item.field_key !== "port_range_min" && 
                item.field_key !== "port_range_max" &&
                item.field_key !== "icmp_type" && 
                item.field_key !== "icmp_code" &&
                item.field_key !== "protocol"
            )
        }

        if (formData.protocol_selection === "tcp" || formData.protocol_selection === "udp") {
            form = form.filter(item => 
                item.field_key !== "icmp_type" && 
                item.field_key !== "icmp_code"
            )
        }

        if (formData.protocol_selection === "icmp") {
            form = form.filter(item => 
                item.field_key !== "port_range_min" && 
                item.field_key !== "port_range_max"
            )
        }

        if (formData.remote_type !== "security_group") {
            form = form.filter(item => item.field_key !== "remote_group_id")
        }
        if (formData.remote_type !== "ip_prefix") {
            form = form.filter(item => item.field_key !== "remote_ip_prefix")
        }

        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    form_field_options = {...form_field_options, ...field}
                    delete form_field_options["label"]
                    form_field_options["item_titles"] = defaultTexts
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in defaultRuleDataForm) {
            if (defaultRuleDataForm[n].required && !formData[defaultRuleDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[defaultRuleDataForm[n].field_key] = {}
                updatedDataFormOptions[defaultRuleDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[defaultRuleDataForm[n].field_key]["errorText"] = defaultTexts[defaultRuleDataForm[n].error_label]
            }
        }

        if (formData.protocol_selection === "tcp" || formData.protocol_selection === "udp") {
            if (formData.port_range_min > -1 && formData.port_range_max > -1) {
                if (parseInt(formData.port_range_min) > parseInt(formData.port_range_max)) {
                    updatedDataFormOptions["port_range_max"] = {}
                    updatedDataFormOptions["port_range_max"]["error"] = true
                    updatedDataFormOptions["port_range_max"]["errorText"] = defaultTexts.invalidPortRangeErrorText
                }
            }
        }

        if (formData.remote_ip_prefix.length > 0) {
            let valid_ip = false
            if (formData.ethertype === "ipv4") {
                valid_ip = ipv4CidrChecker(formData.remote_ip_prefix)
            } else {
                valid_ip = ipv6CidrChecker(formData.remote_ip_prefix)
            }
            if (!valid_ip) {
                updatedDataFormOptions["remote_ip_prefix"] = {}
                updatedDataFormOptions["remote_ip_prefix"]["error"] = true
                updatedDataFormOptions["remote_ip_prefix"]["errorText"] = defaultTexts.invalidCIDRErrorText
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const formatRuleData = () => {
        let updated_data = {...formData}
        if (parseInt(formData.port_range_min) === -1 || 
            formData.protocol_selection === "other" || 
            formData.protocol_selection === "any") {
            delete updated_data.port_range_min
            delete updated_data.port_range_max
        }
        if (parseInt(formData.port_range_min) > -1 && parseInt(formData.port_range_max) === -1) {
            updated_data.port_range_max = formData.port_range_min
        }
        if (formData.protocol_selection === "icmp") {
            if (parseInt(formData.icmp_type) > -1) {
                updated_data["port_range_min"] = formData.icmp_type
            }
            if (parseInt(formData.icmp_code) > -1) {
                updated_data["port_range_max"] = formData.icmp_code
            }
        }
        delete updated_data.icmp_type
        delete updated_data.icmp_code
        if (formData.protocol_selection !== "other" && formData.protocol_selection !== "any") {
            updated_data.protocol = formData.protocol_selection
        }
        if (formData.protocol_selection === "any") {
            updated_data.protocol = 0
        }
        delete updated_data.protocol_selection
        if (formData.remote_type === "security_group") {
            delete updated_data.remote_address_group_id
            delete updated_data.remote_ip_prefix
        } else if (formData.remote_type === "address_group") {
            delete updated_data.remote_group_id
            delete updated_data.remote_ip_prefix
        }  else if (formData.remote_type === "ip_prefix") {
            delete updated_data.remote_group_id
            delete updated_data.remote_address_group_id
            if (formData.remote_ip_prefix.length === 0) {
                updated_data.remote_ip_prefix = formData.ethertype === "ipv4" ? "0.0.0.0/0" : "::/0"
            }
        }
        delete updated_data.remote_type
        return updated_data
    }

    const onAddDefaultRule = async () => {
        const data_is_valid = handleFormDataValidation()
        if (data_is_valid) {
            const formatted_data = formatRuleData()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "POST"
                const securityGroup_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${defaultRulesUrl}`, 
                    method: method, 
                    data: {default_security_group_rule: formatted_data},
                    token: project_token
                })
                if (securityGroup_response.status_code === securityUrlResponses.post.success_response.status_code) {
                    handleAddDefaultRuleFormReset()
                    handleDataFetch()
                } else {
                    const error_response = securityUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === securityGroup_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: securityGroup_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = securityUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: securityGroup_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        } 
        return data_is_valid
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successDefaultRuleAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in defaultRuleDataForm) {
                if (
                    defaultRuleDataForm[n].field_type === "string" || 
                    defaultRuleDataForm[n].field_type === "select"
                    ) {
                    new_form_data[defaultRuleDataForm[n].field_key] = defaultRuleDataForm[n].default_value ? 
                    defaultRuleDataForm[n].default_value : 
                    ""
                } else if (defaultRuleDataForm[n].field_type === "integer") {
                    new_form_data[defaultRuleDataForm[n].field_key] = -1
                } else if (defaultRuleDataForm[n].field_type === "bool") {
                    new_form_data[defaultRuleDataForm[n].field_key] = defaultRuleDataForm[n].default_value ? 
                    defaultRuleDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[
        formData
    ]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid 
                container 
                justifyContent='space-between' 
                alignItems="center">
                <Grid item>
                    <AddButton 
                        getDataForm={getDataForm}               
                        onSubmit={onAddDefaultRule}
                        formReset={handleAddDefaultRuleFormReset}
                        customTexts={{
                            title: defaultTexts.addDefaultRuleDialogTitle
                        }}
                    />
                </Grid>
                <Grid item>
                    <FilterButton 
                        choice_mode={Constants.simple_filter_type}
                        currentFilter={selectedFilter}
                        setCurrentFilter={setSelectedFilter}
                        filter_menu_titles={defaultTexts}
                        filter_menu={filterMenu}
                        currentFilterValue={selectedFilterValue}
                        setCurrentFilterValue={setSelectedFilterValue}
                        onFilterSubmit={handleFilteredSearch}
                        onFilterReset={handleFilterReset}
                    />
                </Grid>
            </Grid>
            {successDefaultRuleAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successDefaultRuleAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successDefaultRuleAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default DefaultRulesSubheaderV20
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import AddressGroupsSubheaderV20 from './addressGroupsSubheaderV2.0';
import AddressGroupsTableV20 from './addressGroupsTableV2.0';
import { addressGroupsFilterMenu, addressGroupUpdateForm } 
from '../../../../../../_data/openstack/neutron/security/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { securityUrl as securityUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/security/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import FormGroup from '@mui/material/FormGroup';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const AddressGroupsWrapperV20 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [addressGroupsData, setAddressGroupsData] = useState([])
    const [addressGroups, setAddressGroups] = useState([])
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [addressGroupDeleteConfirmDialogOpen, setAddressGroupDeleteConfirmDialogOpen] = useState(false);
    const [selectedAddressGroups, setSelectedAddressGroups] = useState([])
    const [selectedAddressGroupIds, setSelectedAddressGroupIds] = useState([])
    const [addressGroupsSortParams, setAddressGroupsSortParams] = useState("")
    
    const [addressGroupFilterQueryParams, setAddressGroupFilterQueryParams] = useState("")
    const [selectedAddressGroupFilter, setSelectedAddressGroupFilter] = useState(addressGroupsFilterMenu[0].value)
    const [selectedAddressGroupFilterValue, setSelectedAddressGroupFilterValue] = useState("")
    const [addressGroupsFilter, setAddressGroupsFilter] = useState([...addressGroupsFilterMenu])
    const [addressGroupUpdateData, setAddressGroupUpdateData] = useState({});
    const [updateAddressGroupDialogOpen, setUpdateAddressGroupDialogOpen] = useState(false)
    const [actionsList, setActionsList] = useState([])

    const [projects, setProjects] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const addressGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.addressGroupsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleAddressGroupFilteredSearch = () => {
        if (selectedAddressGroupFilter && selectedAddressGroupFilterValue) {
            setAddressGroupFilterQueryParams(`${selectedAddressGroupFilter}=${selectedAddressGroupFilterValue}`)
        } else {
            setAddressGroupFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleAddressGroupsDataFormatting = useCallback(() => {
        const formatted_data = addressGroupsData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            return new_item
        })
        setAddressGroups(formatted_data)
    },[
        addressGroupsData,
        projects
    ])

    const handleAddressGroupFilterReset = () => {
        setSelectedAddressGroupFilter(addressGroupsFilterMenu[0].value)
        setSelectedAddressGroupFilterValue("")
        setAddressGroupFilterQueryParams("")
        handleDataFetch()
    }


    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleAddressGroupsSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setAddressGroupsSortParams(sort_param)
        handleDataFetch()
    }

    const handleUpdateAddressGroupDialogClose = () => {
        setUpdateAddressGroupDialogOpen(false)
    }

    const handleUpdateAddressGroupDialogReset = () => {
        setAddressGroupUpdateData({})
        setUpdateAddressGroupDialogOpen(false)
    }

    const handleAddressGroupUpdateDataChange = (event, field_key) => {
        let new_form_data = {...addressGroupUpdateData}
        if (addressGroupUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (addressGroupUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setAddressGroupUpdateData(new_form_data)
    }

    const getDataForm = () => {
        let form_data = [...addressGroupUpdateForm]
        return (
            <FormGroup>
                {form_data.map(field => {
                    let form_field_options = {}
                    return (
                        getFormFieldComponent(
                            field,
                            addressGroupUpdateData,
                            handleAddressGroupUpdateDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleAddressGroupDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${addressGroupsUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === securityUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onAddressGroupDelete = async () => {
        handleAddressGroupDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedAddressGroups) {
            const resp = await handleAddressGroupDelete(selectedAddressGroups[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        setCurrentAction("")
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleAddressGroupSelection = (selected_rows) => {
        if (currentAction === "update_address_group" && selected_rows.length > 1) {
            setCurrentAction("")
        }
        setSelectedAddressGroupIds(selected_rows)
    }

    const onAddressGroupDeleteConfirm = useCallback((n_list) => {
        const selected_n_list = addressGroupsData.filter(n => 
            n_list.includes(n.id))
        setSelectedAddressGroups([...selected_n_list])
        setAddressGroupDeleteConfirmDialogOpen(true)
    },[addressGroupsData])

    const handleAddressGroupDeleteConfirmDialogClose = () => {
        setAddressGroupDeleteConfirmDialogOpen(false)
    }

    const handleAddressGroupUpdate = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${addressGroupsUrl}/${n_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {address_group: addressGroupUpdateData},
                token: project_token
            })

            if (nt_response.status_code === securityUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };


    const onAddressGroupUpdate = async () => {
        handleUpdateAddressGroupDialogClose()
        let err = []
        for (let n in selectedAddressGroups) {
            const resp = await handleAddressGroupUpdate(selectedAddressGroups[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        setAddressGroupUpdateData({})
        setCurrentAction("")
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onAddressGroupUpdateDialogOpen = useCallback((ag_list) => {
        const selected_n_list = addressGroupsData.filter(n => 
            ag_list.includes(n.id))
        setSelectedAddressGroups([...selected_n_list])
        setUpdateAddressGroupDialogOpen(true)
    },[
        addressGroupsData
    ])

    const getAddressGroupsActionsList = useCallback(() => {
        let addressGroup_actions = []
        let new_action = {}
        if (selectedAddressGroupIds.length === 1) {
            new_action["value"] = "update_address_group"
            new_action["action"] = onAddressGroupUpdateDialogOpen
            new_action["keyword"] = "addressGroupUpdateActionTitle"
            new_action["button_text"] = "applyButtonTitleText"
            addressGroup_actions.push({...new_action})
            new_action = {}
        }
        new_action["value"] = "addressGroup_delete"
        new_action["action"] = onAddressGroupDeleteConfirm
        new_action["keyword"] = "addressGroupDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        addressGroup_actions.push({...new_action})
        
        return addressGroup_actions
    },[
        onAddressGroupUpdateDialogOpen,
        onAddressGroupDeleteConfirm,
        selectedAddressGroupIds
    ])

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${addressGroupsUrl}?${addressGroupFilterQueryParams}${addressGroupsSortParams}`
                    const method = "GET"
                    const addressGroup_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (addressGroup_response.status_code === securityUrlResponses.get.success_response.status_code) {
                        setAddressGroupsData(addressGroup_response.data.address_groups)
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        addressGroupsUrl, 
        addressGroupFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        addressGroupsSortParams
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (addressGroupsData.length > 0) {
            handleAddressGroupsDataFormatting()
        }
    },[
        addressGroupsData,
        handleAddressGroupsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        
        let addressGroup_filter_menu = addressGroupsFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            return new_item
        })

        setAddressGroupsFilter(addressGroup_filter_menu)
    },[
        addressGroupsData,
        projects
    ])

    useEffect(() => {
        if (selectedAddressGroups.length === 1) {
            let new_update_data = {}
            for (let i in addressGroupUpdateForm) {
                new_update_data[addressGroupUpdateForm[i].field_key] = selectedAddressGroups[0][addressGroupUpdateForm[i].field_key]
            }
            setAddressGroupUpdateData(new_update_data)
        }
    },[
        selectedAddressGroups
    ]);

    useEffect(() => {
        const actions_list = getAddressGroupsActionsList()
        setActionsList(actions_list)
    },[
        selectedAddressGroups,
        getAddressGroupsActionsList
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <AddressGroupsSubheaderV20 
                selectedFilter={selectedAddressGroupFilter} 
                setSelectedFilter={setSelectedAddressGroupFilter}
                selectedFilterValue={selectedAddressGroupFilterValue}
                setSelectedFilterValue={setSelectedAddressGroupFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={addressGroupsFilter}
                handleFilteredSearch={handleAddressGroupFilteredSearch}
                handleFilterReset={handleAddressGroupFilterReset}
                projects={projects}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <AddressGroupsTableV20 
                addressGroupsData={addressGroups}
                setAddressGroupsData={setAddressGroups}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={actionsList}
                sortHandler={handleAddressGroupsSorting}
                handleRowSelection={handleAddressGroupSelection}
                projects={projects}
            />
        }
        <CustomDialog
            open={addressGroupDeleteConfirmDialogOpen}
            onClose={handleAddressGroupDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.addressGroupDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.addressGroupDeleteConfirmText}: [${selectedAddressGroups.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onAddressGroupDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={updateAddressGroupDialogOpen}
            onClose={handleUpdateAddressGroupDialogReset}
            dialogTitle={{
                title: defaultTexts.addressGroupUpdateActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onAddressGroupUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm()}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default AddressGroupsWrapperV20;